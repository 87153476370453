import { useNavigate } from "@solidjs/router";
import { Component, ComponentProps, splitProps } from "solid-js";

import { ActionMenu, ActionMenuItem } from "@/iro/objects/ActionMenu";
import { useStore } from "@/lib/exome/solid";
import * as routes from "@/routes";
import { ROOT_BUFFER } from "@/store/buffer";
import { UserBuffer } from "@/store/buffer/userBuffer";

import { Icon } from "../objects/Icon";

export const UserBufferMenu: Component<ComponentProps<typeof ActionMenu> & { buffer: UserBuffer }> = (_props) => {
    const [props, propsRest] = splitProps(_props, ["buffer"]);

    const buffer = useStore(() => props.buffer);
    const navigate = useNavigate();

    const handleClose = () => {
        navigate(routes.chat(buffer().network.id, ROOT_BUFFER));
        buffer().close();
    };

    const close = (fn?: () => void) => {
        fn?.();
        propsRest.onClose?.();
    };

    return (
        <ActionMenu {...propsRest}>
            <ActionMenuItem pre={<Icon id="logout" />} onClick={[close, handleClose]}>
                Close
            </ActionMenuItem>
        </ActionMenu>
    );
};
