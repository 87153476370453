import { Component, createEffect, For, JSX, mergeProps, Show, splitProps } from "solid-js";

import { callEventHandler } from "@/lib/solid/eventHandler";
import icons from "#/icons.svg";

import { Icon } from "./Icon";

export const Select: Component<
    {
        ref?: HTMLSelectElement | ((el: HTMLSelectElement) => void);
        before?: JSX.Element;
        bgClass?: string;
        invalid?: boolean;
        options: Array<{ value: string; label: string }>;
    } & JSX.SelectHTMLAttributes<HTMLSelectElement>
> = (_props) => {
    const propsWithDefaults = mergeProps({ bgClass: "", class: "" }, _props);
    const [props, propsRest] = splitProps(propsWithDefaults, [
        "before",
        "disabled",
        "bgClass",
        "invalid",
        "value",
        "onInput",
        "style",
        "class",
        "children",
        "options",
    ]);
    const onInput: JSX.EventHandler<HTMLSelectElement, InputEvent> = (e) => {
        callEventHandler(props.onInput, e);
        if (props.value !== undefined && props.value !== e.currentTarget.value) {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            e.currentTarget.value = props.value as any;
        }
    };

    let el!: HTMLSelectElement;

    createEffect(() => {
        el.value = props.value ? `${props.value}` : "";
    });

    return (
        <div
            class={`o-text-field l-media l-media--flush l-media--gapless ${props.disabled ? "is-disabled" : ""} ${
                props.invalid ? "is-invalid" : ""
            } ${!props.before && !props.children ? "" : "o-text-field--extended"} ${props.class}`}
        >
            <Show when={props.before}>
                <div class="u-d-contents t-hi">{props.before}</div>
            </Show>
            <select
                ref={el}
                class="o-text-field__native l-media__block l-media__block--main"
                value={props.value}
                onInput={onInput}
                {...propsRest}
            >
                <For each={props.options}>{(option) => <option value={option.value}>{option.label}</option>}</For>
            </select>
            <div class="l-media__block u-pl-100 u-pr-100">
                <Icon source={icons} id="chevron-down" />
            </div>
            <Show when={props.children}>
                <div class="u-d-contents t-hi">{props.children}</div>
            </Show>
            <div class={`o-text-field__bg ${props.bgClass}`} />
        </div>
    );
};
