import { Component, ComponentProps, splitProps } from "solid-js";

import { ActionMenu, ActionMenuItem } from "@/iro/objects/ActionMenu";
import { useStore } from "@/lib/exome/solid";
import { Narrow } from "@/lib/unionTypes";
import { Buffer as BufferStore } from "@/store/buffer";
import { RenderableMessage } from "@/store/messageList";

import { Icon } from "../objects/Icon";

export const MessageMenu: Component<
    ComponentProps<typeof ActionMenu> & { buffer: BufferStore; message: Narrow<RenderableMessage, "Message"> }
> = (_props) => {
    const [props, propsRest] = splitProps(_props, ["buffer", "message"]);

    const buffer = useStore(() => props.buffer);

    const handleReply = () => {
        buffer().draft.setReplyTo(props.message.value.id);
    };

    const close = (fn?: () => void) => {
        fn?.();
        propsRest.onClose?.();
    };

    return (
        <ActionMenu {...propsRest}>
            <ActionMenuItem
                pre={<Icon id="arrow-corner-down-right" />}
                disabled={!props.message.value.id}
                onClick={[close, handleReply]}
            >
                Reply
            </ActionMenuItem>
        </ActionMenu>
    );
};
