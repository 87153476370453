import { useNavigate } from "@solidjs/router";
import { Component, Match, Show, Switch, useContext } from "solid-js";
import { P } from "ts-pattern";

import { useOnDemand } from "@/iro/hooks/onDemand";
import { ActionButton } from "@/iro/objects/ActionButton";
import { useStore } from "@/lib/exome/solid";
import { isMatchingN } from "@/lib/ts-pattern/util";
import * as routes from "@/routes";
import { Buffer as BufferStore, ROOT_BUFFER } from "@/store/buffer";
import { ChannelBuffer } from "@/store/buffer/channelBuffer";
import { ServerBuffer } from "@/store/buffer/serverBuffer";
import { UserBuffer } from "@/store/buffer/userBuffer";
import { networkList } from "@/store/networkList";
import { ChannelDialog } from "@/ui/modals/Channel";
import { CreateNetworkDialog } from "@/ui/modals/CreateNetwork";
import { JoinDialog } from "@/ui/modals/Join";
import { UserName } from "@/ui/objects/UserName";
import { UserConfig } from "@/userConfig";

import { Header } from "../../layouts/Header";
import { Icon } from "../../objects/Icon";
import { DynamicUserAvatar } from "../../objects/UserAvatar";

const ChannelBufferHeader: Component<{ buffer: ChannelBuffer }> = (props) => {
    const [userConfig, setUserConfig] = useContext(UserConfig);
    const [onDemand] = useOnDemand();
    const navigate = useNavigate();

    const buffer = useStore(() => props.buffer);
    const network = useStore(() => buffer().network);

    const handleLeave = () => {
        navigate(routes.chat(buffer().network.id, ROOT_BUFFER));
        props.buffer.part();
    };

    const handleSettings = () => {
        onDemand((close) => <ChannelDialog buffer={props.buffer} onClose={close} />);
    };

    const handleSidebar = () => setUserConfig("channelSidebar", true);

    return (
        <>
            <div class="l-media__block">
                <Icon id="hash" />
            </div>
            <div class="l-media__block l-media__block--main">
                <strong class="u-d-block u-elp">{buffer().id.slice(1)}</strong>
                <small class="u-d-block u-elp">{network().name}</small>
            </div>
            <div class="l-media__block l-header__button-end">
                <ActionButton quiet round onClick={handleSettings}>
                    <Icon id="cog" block />
                </ActionButton>{" "}
                <ActionButton quiet round onClick={handleLeave} title="Leave channel">
                    <Icon id="logout" block />
                </ActionButton>
                <Show when={!userConfig.channelSidebar}>
                    <ActionButton quiet round onClick={handleSidebar} class="u-ml-200">
                        <Icon id="sidebar-right" block />
                    </ActionButton>
                </Show>
            </div>
        </>
    );
};

const UserBufferHeader: Component<{ buffer: UserBuffer }> = (props) => {
    const [userConfig, setUserConfig] = useContext(UserConfig);
    const navigate = useNavigate();

    const buffer = useStore(() => props.buffer);
    const network = useStore(() => buffer().network);

    const handleClose = () => {
        navigate(routes.chat(buffer().network.id, ROOT_BUFFER));
        props.buffer.close();
    };

    const handleSidebar = () => setUserConfig("userSidebar", true);

    return (
        <>
            <div class="l-media__block">
                <DynamicUserAvatar user={buffer().user} size="75" showStatus colored />
            </div>
            <div class="l-media__block l-media__block--main">
                <div class="u-elp">
                    <strong>
                        <UserName user={buffer().user} />
                    </strong>
                </div>
                <small class="u-d-block u-elp">{network().name}</small>
            </div>
            <div class="l-media__block l-header__button-end">
                <ActionButton quiet round onClick={handleClose} title="Close conversation">
                    <Icon id="logout" block />
                </ActionButton>
                <Show when={!userConfig.userSidebar}>
                    <ActionButton quiet round onClick={handleSidebar} class="u-ml-200">
                        <Icon id="sidebar-right" block />
                    </ActionButton>
                </Show>
            </div>
        </>
    );
};

const ServerBufferHeader: Component<{ buffer: ServerBuffer }> = (props) => {
    const [userConfig, setUserConfig] = useContext(UserConfig);
    const [onDemand] = useOnDemand();

    const buffer = useStore(() => props.buffer);
    const network = useStore(() => buffer().network);

    const handleSidebar = () => setUserConfig("networkSidebar", true);

    const viewCreateNetworkModal = (edit?: boolean) => {
        onDemand((close) => <CreateNetworkDialog network={edit ? buffer().network : undefined} onClose={close} />);
    };

    const viewJoinModal = () => {
        onDemand((close) => <JoinDialog network={buffer().network} onClose={close} />);
    };

    const disconnect = () => networkList.clearConfig();

    return (
        <>
            <div class="l-media__block">
                <Icon id="server" />
            </div>
            <div class="l-media__block l-media__block--main">
                <strong class="u-d-block u-elp">{network().name}</strong>
                <small class="u-d-block u-elp">{network().host}</small>
            </div>
            <div class="l-media__block l-header__button-end">
                <Show
                    when={network().isRoot}
                    fallback={
                        <>
                            <ActionButton quiet round onClick={viewJoinModal}>
                                <Icon id="message-plus" block />
                            </ActionButton>{" "}
                            <ActionButton quiet round onClick={[viewCreateNetworkModal, true]}>
                                <Icon id="cog" block />
                            </ActionButton>
                        </>
                    }
                >
                    <Show when={network().isBouncer}>
                        <ActionButton quiet round onClick={[viewCreateNetworkModal, false]}>
                            <Icon id="server-plus" block />
                        </ActionButton>{" "}
                    </Show>
                    <ActionButton quiet round onClick={disconnect}>
                        <Icon id="logout" block />
                    </ActionButton>
                </Show>
                <Show when={!userConfig.networkSidebar}>
                    <ActionButton quiet round onClick={handleSidebar} class="u-ml-200">
                        <Icon id="sidebar-right" block />
                    </ActionButton>
                </Show>
            </div>
        </>
    );
};

export const BufferHeader: Component<{ buffer: BufferStore }> = (props) => (
    <Header>
        <Switch>
            <Match when={isMatchingN(P.instanceOf(ServerBuffer), props.buffer)}>
                {(buffer) => <ServerBufferHeader buffer={buffer()} />}
            </Match>
            <Match when={isMatchingN(P.instanceOf(ChannelBuffer), props.buffer)}>
                {(buffer) => <ChannelBufferHeader buffer={buffer()} />}
            </Match>
            <Match when={isMatchingN(P.instanceOf(UserBuffer), props.buffer)}>
                {(buffer) => <UserBufferHeader buffer={buffer()} />}
            </Match>
        </Switch>
    </Header>
);
