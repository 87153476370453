import { useIntl } from "@cookbook/solid-intl";
import { isThisYear, isToday } from "date-fns";
import { date as D, function as F, option as O, ord as Or } from "fp-ts";
import { Component, createMemo, Index, Match, onCleanup, Show, splitProps, Switch, useContext } from "solid-js";
import { Dynamic } from "solid-js/web";

import { Avatar } from "@/iro/objects/Avatar";
import { Button, ButtonLink } from "@/iro/objects/Button";
import { Dialog } from "@/iro/objects/Dialog";
import { Divider } from "@/iro/objects/Divider";
import { Time } from "@/iro/objects/Time";
import { useStore, useStores } from "@/lib/exome/solid";
import { isMatchingN } from "@/lib/ts-pattern/util";
import { Narrow } from "@/lib/unionTypes";
import * as routes from "@/routes";
import { networkList as networkListStore } from "@/store/networkList";
import { Notification as NotificationObj, NotificationList } from "@/store/notificationList";
import { UserConfig } from "@/userConfig";

import { Icon } from "../objects/Icon";
import { StaticMention } from "../objects/Mention";

type RenderableNotification = { notificationList: NotificationList; notification: NotificationObj };

const notificationOrd: Or.Ord<RenderableNotification> = F.pipe(
    O.getOrd(D.Ord),
    Or.reverse,
    Or.contramap((notification) => O.fromNullable(notification.notification.value.time)),
);

const InviteNotification: Component<{
    notificationList: NotificationList;
    notification: Narrow<NotificationObj, "Invite">;
    unread?: boolean;
    onClose?: (trigger?: string) => void;
}> = (props) => {
    const intl = useIntl();

    const user = useStore(() => props.notification.value.user);
    const notificationList = useStore(() => props.notificationList);

    const dateFormat = (date: Date): Intl.DateTimeFormatOptions | undefined =>
        isToday(date)
            ? { minute: "2-digit", hour: "2-digit" }
            : isThisYear(date)
            ? { month: "short", day: "numeric" }
            : { year: "numeric", month: "short", day: "numeric" };

    const handleClick = async () => {
        await props.notificationList.network.join(props.notification.value.channel);
        props.onClose?.();
    };

    return (
        <div class="l-media l-media--flush">
            <Avatar class="l-media__block">
                <Icon id="user-plus" />
            </Avatar>
            <div class="l-media__block l-media__block--main">
                <small class="u-d-block">
                    <Time date={props.notification.value.time} format={dateFormat} />
                </small>
                <Dynamic component={props.unread ? "strong" : "div"} class="u-d-block u-mt-50">
                    {intl.formatMessage(
                        {
                            id: "notification.invite.title",
                            defaultMessage: "{user} invited you to {channel}",
                        },
                        {
                            user: <StaticMention>{user().whox.nickname}</StaticMention>,
                            channel: props.notification.value.channel,
                        },
                    )}
                </Dynamic>
            </div>
            <div class="l-media__block">
                <ButtonLink
                    href={routes.chat(
                        props.notificationList.network.id,
                        encodeURIComponent(props.notification.value.channel),
                    )}
                    variant="secondary"
                    onClick={handleClick}
                >
                    Join
                </ButtonLink>
            </div>
        </div>
    );
};

const MentionNotification: Component<{
    notificationList: NotificationList;
    notification: Narrow<NotificationObj, "Mention">;
    unread?: boolean;
    onClose?: (trigger?: string) => void;
}> = (props) => {
    const intl = useIntl();

    const user = useStore(() => props.notification.value.user);
    const notificationList = useStore(() => props.notificationList);
    const network = useStore(() => notificationList().network);

    const target = () => props.notification.value.channel ?? user().whox.nickname;

    const dateFormat = (date: Date): Intl.DateTimeFormatOptions | undefined =>
        isToday(date)
            ? { minute: "2-digit", hour: "2-digit" }
            : isThisYear(date)
            ? { month: "short", day: "numeric" }
            : { year: "numeric", month: "short", day: "numeric" };

    const showHandler = () => props.onClose?.();

    return (
        <div class="l-media l-media--flush">
            <Avatar class="l-media__block">
                <Icon id="message" />
            </Avatar>
            <div class="l-media__block l-media__block--main">
                <small class="u-d-block">
                    <Time date={props.notification.value.time} format={dateFormat} />
                </small>
                <Dynamic component={props.unread ? "strong" : "div"} class="u-d-block u-mt-50">
                    {intl.formatMessage(
                        {
                            id: "notification.mention.title",
                            defaultMessage: `{user} mentioned {keyword, select,
                                _ {you}
                                other {the keyword "{keyword}"}
                            } in {channel, select, 
                                _ {a conversation}
                                other {{channel}}
                            }`,
                        },
                        {
                            user: <StaticMention>{user().whox.nickname}</StaticMention>,
                            keyword: props.notification.value.keyword ?? "_",
                            channel: props.notification.value.channel ?? "_",
                        },
                    )}
                </Dynamic>
                <small class="u-d-block u-mt-50">{props.notification.value.message}</small>
            </div>
            <div class="l-media__block">
                <ButtonLink
                    href={routes.chat(network().id, encodeURIComponent(target()))}
                    onClick={showHandler}
                    variant="secondary"
                >
                    Show
                </ButtonLink>
            </div>
        </div>
    );
};

const Notification: Component<{
    notificationList: NotificationList;
    notification: NotificationObj;
    unread?: boolean;
    onClose?: (trigger?: string) => void;
}> = (_props) => {
    const [props, propsRest] = splitProps(_props, ["notification"]);

    return (
        <Switch>
            <Match when={isMatchingN(NotificationObj.Invite.select(), props.notification)}>
                {(notification) => <InviteNotification notification={notification()} {...propsRest} />}
            </Match>
            <Match when={isMatchingN(NotificationObj.Mention.select(), props.notification)}>
                {(notification) => <MentionNotification notification={notification()} {...propsRest} />}
            </Match>
        </Switch>
    );
};

export const NotificationsDialog: Component<{
    onClose?: (trigger?: string) => void;
}> = (props) => {
    const intl = useIntl();

    const [userConfig] = useContext(UserConfig);
    const networkList = useStore(() => networkListStore);
    const networks = useStores(() => Array.from(networkList().networks.values()));
    const notificationLists = useStores(() => networks().map((network) => network.notifications));

    const readNotifications = createMemo(() =>
        notificationLists().flatMap((list) =>
            list.grouped.read
                .map(
                    (notification): RenderableNotification => ({
                        notificationList: list as NotificationList,
                        notification,
                    }),
                )
                .sort(notificationOrd.compare),
        ),
    );
    const unreadNotifications = createMemo(() =>
        notificationLists().flatMap((list) =>
            list.grouped.unread
                .map(
                    (notification): RenderableNotification => ({
                        notificationList: list as NotificationList,
                        notification,
                    }),
                )
                .sort(notificationOrd.compare),
        ),
    );

    const handleClose = async (result?: string, e?: SubmitEvent) => {
        e?.preventDefault();

        if (result === "read") {
            for (const list of notificationLists()) {
                list.setLastRead(new Date());
            }
            return;
        }

        props.onClose?.(result);
    };

    onCleanup(() => {
        if (userConfig.autoReadNotifications) {
            for (const list of notificationLists()) {
                list.setLastRead(new Date());
            }
        }
    });

    return (
        <Dialog
            title="Notifications"
            size="sm"
            onClose={handleClose}
            scrollable
            footer={(close) => (
                <div class="l-button-group">
                    <Button type="button" outline onClick={[close, "read"]} disabled={!unreadNotifications().length}>
                        Mark as read
                    </Button>{" "}
                    <Button type="submit" variant="primary" onClick={[close, "ok"]} class="u-ml-auto">
                        Close
                    </Button>
                </div>
            )}
        >
            <Index
                each={unreadNotifications()}
                fallback={
                    <small class="u-d-block u-pt-400 u-pb-400 u-ta-center">
                        <em>
                            {intl.formatMessage({
                                id: "notification.empty",
                                defaultMessage: "No unread notifications",
                            })}
                        </em>
                    </small>
                }
            >
                {(notification, i) => (
                    <>
                        <Show when={i}>
                            <Divider variant="faint" class="u-mt-200 u-mb-200" />
                        </Show>
                        <Notification
                            notificationList={notification().notificationList}
                            notification={notification().notification}
                            onClose={props.onClose}
                            unread
                        />
                    </>
                )}
            </Index>
            <Divider variant="medium" class="u-mt-200 u-mb-200">
                old
            </Divider>
            <Index each={readNotifications()}>
                {(notification, i) => (
                    <>
                        <Show when={i}>
                            <Divider variant="faint" class="u-mt-200 u-mb-200" />
                        </Show>
                        <Notification
                            notificationList={notification().notificationList}
                            notification={notification().notification}
                            onClose={props.onClose}
                        />
                    </>
                )}
            </Index>
        </Dialog>
    );
};
