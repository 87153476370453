import { Exome } from "exome";

import { ChannelListItem } from "@/lib/irc/list";

import { Network } from "./network";

export enum ChannelListStatus {
    Uninitialized = "uninitialized",
    Loading = "loading",
    Idle = "idle",
}

export class ChannelList extends Exome {
    channels: ChannelListItem[] = [];

    status: ChannelListStatus = ChannelListStatus.Uninitialized;

    lastUpdate?: Date;

    // eslint-disable-next-line no-useless-constructor
    constructor(readonly network: Network) {
        super();
    }

    setStatus(status: ChannelListStatus) {
        this.status = status;
    }

    setChannels(channels: ChannelListItem[]) {
        this.channels = channels;
        this.channels.sort((a, b) => b.clients - a.clients);
        this.status = ChannelListStatus.Idle;
        this.lastUpdate = new Date();
    }
}
