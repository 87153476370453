import { Component, JSX, Show, splitProps } from "solid-js";

import { Avatar } from "@/iro/objects/Avatar";
import { useStore } from "@/lib/exome/solid";
import { RenderableSource } from "@/store/messageList";
import { User } from "@/store/user";

export const UserAvatar: Component<{
    source: RenderableSource;
    class?: string;
    style?: JSX.CSSProperties;
    noStatus?: boolean;
    size?: "100" | "75" | "50";
    colored?: boolean;
}> = (_props) => {
    const [props, propsRest] = splitProps(_props, ["source"]);

    return (
        <Show when={props.source.user} fallback={<Avatar {...propsRest}>{props.source.nickname}</Avatar>}>
            {(user) => <DynamicUserAvatar user={user()} {...propsRest} />}
        </Show>
    );
};

export const DynamicUserAvatar: Component<{
    user: User;
    class?: string;
    style?: JSX.CSSProperties;
    showStatus?: boolean;
    size?: "100" | "75" | "50";
    colored?: boolean;
}> = (_props) => {
    const [props, propsRest] = splitProps(_props, ["user", "showStatus"]);

    const user = useStore(() => props.user);
    const nickname = () => user().whox.nickname;
    const status = () => (props.showStatus ?? propsRest.size === "100" ? user().status : undefined);

    return (
        <Avatar status={status()} {...propsRest}>
            {nickname()}
        </Avatar>
    );
};
