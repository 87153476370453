import { Exome } from "exome";
import { nanoid } from "nanoid";
import { match, P } from "ts-pattern";

import { CaseMappedMap } from "@/lib/caseMappedMap";
import { Capability } from "@/lib/client/capabilityManager";
import { Client, ClientParams, ClientStatus } from "@/lib/client/client";
import { ISupportToken } from "@/lib/client/isupportManager";
import { Batch } from "@/lib/irc/batch";
import { BouncerNetworkState, NetworkAttributes, newNetworkAttributes } from "@/lib/irc/bouncer";
import { CaseMapping, rfc1459 } from "@/lib/irc/caseMapping";
import * as Code from "@/lib/irc/codes";
import { parseIrcTimestamp } from "@/lib/irc/date";
import { ChannelListItem } from "@/lib/irc/list";
import { Message } from "@/lib/irc/message";
import { ModeChange, ModeTypes, parseModes, renderModes } from "@/lib/irc/modes";
import { computeMaxMessageLength, parseRawMessage, parseSource, Tags } from "@/lib/irc/rawMessage";
import { Typing } from "@/lib/irc/typing";
import { Whox } from "@/lib/irc/whox";
import { linkify } from "@/lib/linkify";
import { isEnum } from "@/lib/types";
import { impl, Narrow, Variant } from "@/lib/unionTypes";

import { ROOT_BUFFER } from "./buffer";
import { ChannelBuffer } from "./buffer/channelBuffer";
import { ServerBuffer } from "./buffer/serverBuffer";
import { UserBuffer } from "./buffer/userBuffer";
import { BufferList } from "./bufferList";
import { ChannelList, ChannelListStatus } from "./channelList";
import { RenderableMessage } from "./messageList";
import { networkList } from "./networkList";
import { Notification, NotificationList } from "./notificationList";
import { UserList } from "./userList";

export const ROOT_NETWORK = "~";

export type NetworkConnection =
    | Variant<"Connecting">
    | Variant<"Connected">
    | Variant<"Disconnected", { error?: unknown }>;

export const NetworkConnection = impl<NetworkConnection>();

export class Network extends Exome {
    private configOverrides: Partial<ClientParams> = {};

    readonly isRoot: boolean;

    readonly client = new Client();

    isBouncer = false;

    clientStatus = ClientStatus.Disconnected;

    serverBuffer = new ServerBuffer(this);

    buffers = new BufferList(this, rfc1459);

    users = new UserList(this, rfc1459);

    channels = new ChannelList(this);

    notifications = new NotificationList(this);

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    typings = new CaseMappedMap<any>(rfc1459);

    constructor();
    constructor(id: string, attrs: NetworkAttributes);
    constructor(
        readonly id: string = ROOT_NETWORK,
        public attrs: NetworkAttributes = newNetworkAttributes(),
    ) {
        super();

        this.isRoot = id === ROOT_NETWORK;

        if (!this.isRoot) {
            this.configOverrides = { bouncerNetwork: id };
        }

        this.client.addEventListener("message", this.handleMessage.bind(this));
        this.client.addEventListener("batch", this.handleBatch.bind(this));
        this.client.addEventListener("status", this.handleStatus.bind(this));
        this.client.addEventListener("caseMapping", this.handleCaseMapping.bind(this));
        this.client.addEventListener("names", this.handleNames.bind(this));
        this.client.addEventListener("who", this.handleWho.bind(this));
        this.client.addEventListener("motd", this.handleMotd.bind(this));
        this.client.addEventListener("info", this.handleInfo.bind(this));
        this.client.addEventListener("list", this.handleList.bind(this));
    }

    get name() {
        if (this.isRoot) {
            if (this.isBouncer) {
                return "Bouncer";
            }
            if (this.client.server.nickname) {
                return this.client.server.nickname;
            }
            return "Server";
        }
        if (this.attrs.name) {
            return this.attrs.name;
        }
        if (this.attrs.host) {
            return this.attrs.host;
        }
        return this.id;
    }

    get host() {
        if (this.isRoot) {
            return this.client.params.socket;
        }
        if (this.attrs.host) {
            return this.attrs.host;
        }
        return `${this.client.params.socket} -> ${this.id}`;
    }

    get status() {
        if (this.clientStatus === ClientStatus.Connected && !this.isRoot && this.attrs) {
            return {
                [BouncerNetworkState.Connecting]: ClientStatus.Connecting,
                [BouncerNetworkState.Connected]: ClientStatus.Connected,
                [BouncerNetworkState.Disconnected]: ClientStatus.Disconnected,
            }[this.attrs.state];
        }

        return this.clientStatus;
    }

    get me() {
        return this.users.upsert(this.client.nickname);
    }

    get modes(): ModeTypes {
        return {
            ...this.client.isupport[ISupportToken.ChanModes],
            ...this.client.isupport[ISupportToken.Prefix],
        };
    }

    async connect(config: Partial<ClientParams> & Pick<ClientParams, "socket" | "nickname">) {
        try {
            await this.client.connect({ ...config, ...this.configOverrides });
        } catch (err) {
            console.error(err);
            return;
        }

        this.isBouncer = this.client.caps.has(Capability.BouncerNetworksNotify);
        this.client.who(this.client.nickname);
        this.buffers.load();
    }

    disconnect() {
        this.client.disconnect();
    }

    updateAttrs(attrs: Partial<NetworkAttributes>) {
        this.attrs = { ...this.attrs, ...attrs };
    }

    private handleCaseMapping(e: CustomEvent<CaseMapping>) {
        this.buffers.updateCaseMapping(e.detail);
        this.users.updateCaseMapping(e.detail);
        this.typings = new CaseMappedMap(e.detail, this.typings);
    }

    private handleMessage = (e: CustomEvent<Message>) => {
        if (this.client.findBatchByType(e.detail.value, "chathistory")) {
            return;
        }

        match(e.detail)
            .with(
                Message.BouncerNetworkChanged.select(),
                (_) => this.isRoot,
                (msg) => {
                    this.routeMessage(e.detail);

                    const network = networkList.upsert(msg.id, msg.attributes);

                    if (network.clientStatus === ClientStatus.Disconnected) {
                        network.connect(this.client.params);
                    }
                },
            )
            .with(
                Message.BouncerNetworkRemoved.select(),
                (_) => this.isRoot,
                (msg) => {
                    this.routeMessage(e.detail);
                    networkList.delete(msg.id);
                },
            )
            .with(
                Message.Privmsg.pattern({}),
                Message.Ctcp.pattern({ command: "ACTION" }),
                Message.Notice.pattern({}),
                Message.CtcpNotice.pattern({ command: "ACTION" }),
                (msg) => {
                    const isCtcp = Message.Ctcp.is(msg) || Message.CtcpNotice.is(msg);
                    const text = isCtcp ? msg.value.params : msg.value.content;
                    const selfRegex = new RegExp(`\\b${this.me.whox.nickname}\\b`, "ui");

                    if (selfRegex.test(text)) {
                        this.notifications.insert([
                            Notification.Mention({
                                time: new Date(),
                                user: this.users.upsert(msg.value.source.nickname),
                                channel: this.isChannel(msg.value.target) ? msg.value.target : undefined,
                                message: text,
                            }),
                        ]);
                    }

                    this.routeMessage(e.detail);
                },
            )
            .with(Message.Join.select(), (msg) => {
                if (this.client.isMe(msg.source.nickname)) {
                    this.buffers.upsert(msg.channel);
                }

                const whox: Whox = { nickname: msg.source.nickname };

                if (msg.source.username) {
                    whox.username = msg.source.username;
                }
                if (msg.source.hostname) {
                    whox.hostname = msg.source.hostname;
                }
                if (msg.account) {
                    whox.account = msg.account !== "*" ? msg.account : undefined;
                }
                if (msg.realname) {
                    whox.realname = msg.realname;
                }

                const user = this.users.upsert(msg.source.nickname, whox);
                user.setOffline(false);

                const buffer = this.buffers.get(msg.channel);

                if (buffer instanceof ChannelBuffer) {
                    buffer.members.add(user, "");
                }

                this.routeMessage(e.detail);
            })
            .with(Message.Part.select(), (msg) => {
                this.routeMessage(e.detail);

                if (this.client.isMe(msg.source.nickname)) {
                    this.buffers.delete(msg.channel);
                    return;
                }

                const buffer = this.buffers.get(msg.channel);

                if (buffer instanceof ChannelBuffer) {
                    buffer.members.delete(msg.source.nickname);
                }
            })
            .with(Message.Kick.select(), (msg) => {
                this.routeMessage(e.detail);

                const buffer = this.buffers.get(msg.channel);

                if (buffer instanceof ChannelBuffer) {
                    buffer.members.delete(msg.source.nickname);
                }
            })
            .with(Message.Quit.select(), (msg) => {
                this.routeMessage(e.detail);
                this.users.get(msg.source.nickname)?.setOffline(true);
                this.buffers.deleteMember(msg.source.nickname);
            })
            .with(Message.Nick.select(), (msg) => {
                this.routeMessage(e.detail);
                this.moveUser(msg.source.nickname, msg.nickname);
            })
            .with(Message.SetName.select(), (msg) => {
                this.routeMessage(e.detail);
                this.users.get(msg.source.nickname)?.updateWhox({ realname: msg.realname });
            })
            .with(Message.Chghost.select(), (msg) => {
                this.routeMessage(e.detail);
                this.users.get(msg.source.nickname)?.updateWhox({ username: msg.username, hostname: msg.hostname });
            })
            .with(Message.Account.select(), (msg) => {
                this.routeMessage(e.detail);
                this.users
                    .get(msg.source.nickname)
                    ?.updateWhox({ account: msg.account === "*" ? undefined : msg.account });
            })
            .with(Message.Away.select(), (msg) => {
                this.routeMessage(e.detail);
                this.users.get(msg.source.nickname)?.updateWhox({ isAway: msg.reason !== undefined });
            })
            .with(Message.Topic.select(), (msg) => {
                this.routeMessage(e.detail);
                const buffer = this.buffers.get(msg.channel);
                if (buffer instanceof ChannelBuffer) {
                    buffer.setTopic(msg.topic);
                }
                this.client.topic(msg.channel);
            })
            .with(Message.Mode.select(), (msg) => {
                this.routeMessage(e.detail);

                if (this.isChannel(msg.target)) {
                    const buffer = this.buffers.get(msg.target);
                    const parsedModes = parseModes(msg.modes, msg.params, this.modes);

                    if (buffer instanceof ChannelBuffer && parsedModes) {
                        buffer.setModes(parsedModes);
                    }
                } else {
                    const user = this.users.get(msg.target);
                    const parsedModes = parseModes(msg.modes, msg.params);

                    if (user && parsedModes) {
                        user.setModes(parsedModes);
                    }
                }
            })
            .with(Message.Reply.select(), (msg) => {
                this.routeMessage(e.detail);
                this.handleReplyMessage(msg);
            })
            .with(Message.MarkRead.select(), (msg) => {
                this.buffers.get(msg.target)?.messages.setLastRead(msg.timestamp);
            })
            .with(Message.Invite.select(), (msg) => {
                this.notifications.insert([
                    Notification.Invite({
                        time: new Date(),
                        user: this.users.upsert(msg.source.nickname),
                        channel: msg.channel,
                    }),
                ]);
            })
            .with(Message.TagMsg.select(), (msg) => {
                const isTyping = isEnum(Typing);
                const typing = msg.tags["+typing"];

                if (isTyping(typing)) {
                    const buffer = this.buffers.get(msg.target);

                    if (buffer instanceof UserBuffer) {
                        buffer.setTyping(typing);
                    } else if (buffer instanceof ChannelBuffer) {
                        buffer.members.setTyping(msg.source.nickname, typing);
                    }

                    const key = `${msg.target}_${msg.source.nickname}`;

                    const clear = () => {
                        if (buffer instanceof UserBuffer) {
                            buffer.setTyping(Typing.Done);
                        } else if (buffer instanceof ChannelBuffer) {
                            buffer.members.setTyping(msg.source.nickname, Typing.Done);
                        }

                        this.typings.delete(key);
                    };

                    let timer = this.typings.get(key);

                    if (timer) {
                        clearTimeout(timer);
                    }

                    switch (typing) {
                        case Typing.Active:
                            timer = setTimeout(clear, 6000);
                            this.typings.set(key, timer);
                            break;

                        case Typing.Paused:
                            timer = setTimeout(clear, 30000);
                            this.typings.set(key, timer);
                            break;
                    }
                }
            })
            .otherwise(() => {
                this.routeMessage(e.detail);
            });
    };

    private handleReplyMessage = (msg: Narrow<Message, "Reply">["value"]) =>
        match(msg)
            .with({ code: Code.RPL_NOTOPIC, params: [P._, P._, P._] }, (msg) => {
                const channel = msg.params[1];
                const buffer = this.buffers.get(channel);
                if (buffer instanceof ChannelBuffer) {
                    buffer.setTopic("");
                }
            })
            .with({ code: Code.RPL_TOPIC, params: [P._, P._, P._] }, (msg) => {
                const channel = msg.params[1];
                const topic = msg.params[2];
                const buffer = this.buffers.get(channel);
                if (buffer instanceof ChannelBuffer) {
                    buffer.setTopic(topic);
                }
            })
            .with({ code: Code.RPL_TOPICWHOTIME, params: [P._, P._, P._, P._] }, (msg) => {
                const channel = msg.params[1];
                const source = parseSource(msg.params[2]);
                const setAt = msg.params[3];
                const setAtNum = +setAt;

                if (source && setAt && !isNaN(setAtNum)) {
                    const buffer = this.buffers.get(channel);
                    if (buffer instanceof ChannelBuffer) {
                        buffer.setTopicProvenance(source.nickname, parseIrcTimestamp(setAtNum));
                    }
                }
            })
            .with({ code: Code.RPL_AWAY, params: [P._, P._, P._] }, (msg) => {
                const nickname = msg.params[1];
                this.users.get(nickname)?.updateWhox({ isAway: true });
            })
            .with({ code: Code.RPL_UNAWAY, params: [P._, P._] }, () => {
                this.me.updateWhox({ isAway: false });
            })
            .with({ code: Code.RPL_NOWAWAY, params: [P._, P._] }, () => {
                this.me.updateWhox({ isAway: true });
            })
            .with({ code: Code.RPL_MONONLINE, params: [P._, P._] }, (msg) => {
                const targets = msg.params[1].split(",");

                for (const target of targets) {
                    const nickname = parseSource(target)?.nickname ?? target;
                    this.users.get(nickname)?.setOffline(false);
                }
            })
            .with({ code: Code.RPL_MONOFFLINE, params: [P._, P._] }, (msg) => {
                const targets = msg.params[1].split(",");

                for (const target of targets) {
                    const nickname = parseSource(target)?.nickname ?? target;
                    this.users.get(nickname)?.setOffline(true);
                }
            })
            .with({ code: Code.RPL_UMODEIS, params: [P._, P._] }, (msg) => {
                const modes = msg.params[1];
                const parsedModes = parseModes(modes, []);

                if (parsedModes) {
                    this.me.setModes(parsedModes);
                }
            })
            .with({ code: Code.RPL_CHANNELMODEIS, params: [P._, P._, P._, ...P.array()] }, (msg) => {
                const [, target, modes, ...modeParams] = msg.params;
                const buffer = this.buffers.get(target);

                if (buffer instanceof ChannelBuffer) {
                    const parsedModes = parseModes(modes, modeParams, this.modes);

                    if (parsedModes) {
                        buffer.setModes(parsedModes);
                    }
                }
            })
            .with({ code: Code.RPL_CREATIONTIME, params: [P._, P._, P._] }, (msg) => {
                const [, target, date] = msg.params;
                const buffer = this.buffers.get(target);

                if (buffer instanceof ChannelBuffer) {
                    buffer.setCreatedAt(parseIrcTimestamp(date));
                }
            })
            .otherwise(() => void 0);

    private handleBatch = (e: CustomEvent<Batch>) => {
        // TODO?
    };

    private handleStatus(e: CustomEvent<ClientStatus>) {
        this.clientStatus = e.detail;
    }

    private handleNames = (e: CustomEvent<{ channel: string; members: CaseMappedMap<string> }>) => {
        const buffer = this.buffers.get(e.detail.channel);

        if (buffer) {
            for (const [nickname, prefix] of e.detail.members) {
                const user = this.users.upsert(nickname);

                if (buffer instanceof ChannelBuffer) {
                    buffer.members.add(user, prefix);
                }
            }
        }
    };

    private handleWho = (e: CustomEvent<{ target: string; who: Whox[] }>) => {
        if (e.detail.who.length) {
            for (const whox of e.detail.who) {
                if (whox.nickname) {
                    this.users.upsert(whox.nickname, whox).setOffline(false);
                }
            }
        } else if (!this.isChannel(e.detail.target) && !e.detail.target.includes("*")) {
            this.users.get(e.detail.target)?.setOffline(true);
        }
    };

    private handleMotd = (e: CustomEvent<string[]>) => {
        const content = e.detail.map(linkify);

        this.serverBuffer.messages.insertRenderable([
            RenderableMessage.Motd({
                time: new Date(),
                hash: nanoid(),
                content: content.map((c) => c.text),
                links: content.flatMap((c) => c.links),
                raw: e.detail.join("\n"),
            }),
        ]);
    };

    private handleInfo = (e: CustomEvent<string[]>) => {
        this.serverBuffer.messages.insertRenderable([
            RenderableMessage.Info({
                time: new Date(),
                hash: nanoid(),
                content: e.detail.join("\n"),
            }),
        ]);
    };

    private handleList = (e: CustomEvent<ChannelListItem[]>) => this.channels.setChannels(e.detail);

    private routeMessage = (msg: Message) => {
        const affectedBuffers = match(msg)
            .with(Message.Mode.select(), (msg) => (this.isChannel(msg.target) ? [msg.target] : [ROOT_BUFFER]))
            .with(
                Message.Privmsg.pattern({}),
                Message.Ctcp.pattern({ command: "ACTION" }),
                Message.Notice.pattern({}),
                Message.CtcpNotice.pattern({ command: "ACTION" }),
                (msg) => {
                    const targets: string[] = [];
                    const context = msg.value.tags["+draft/channel-context"];
                    const allowedPrefixes = this.client.isupport[ISupportToken.StatusMsg];

                    if (this.client.caps.has(Capability.ChannelContext) && context) {
                        targets.push(context);
                    } else {
                        let target = !this.client.isMe(msg.value.target)
                            ? msg.value.target
                            : this.client.isServer(msg.value.target)
                            ? ROOT_BUFFER
                            : msg.value.source.nickname;

                        if ((Message.Notice.is(msg) || Message.CtcpNotice.is(msg)) && !this.buffers.has(target)) {
                            target = ROOT_BUFFER;
                        }

                        if (target !== ROOT_BUFFER && allowedPrefixes) {
                            target = this.client.parsePrefixed(target, allowedPrefixes).nickname;
                        }

                        targets.push(target);
                    }

                    return targets;
                },
            )
            .with(
                Message.Join.select(),
                (msg) => this.client.isMe(msg.value.source.nickname),
                () => [],
            )
            .with(
                Message.Part.select(),
                (msg) => this.client.isMe(msg.value.source.nickname),
                () => [],
            )
            .with(Message.Join.select(), (msg) => [msg.channel])
            .with(Message.Part.select(), (msg) => [msg.channel])
            .with(Message.Kick.select(), (msg) => [msg.channel])
            .with(Message.Quit.select(), (msg) => {
                const targets: string[] = [];
                for (const [id, buf] of this.buffers.buffers) {
                    if (buf instanceof ChannelBuffer && buf.members.members.has(msg.source.nickname)) {
                        targets.push(id);
                    }
                }
                return targets;
            })
            .with(Message.Nick.select(), (msg) => {
                const targets: string[] = [];
                for (const [id, buf] of this.buffers.buffers) {
                    if (buf instanceof ChannelBuffer && buf.members.members.has(msg.source.nickname)) {
                        targets.push(id);
                    }
                }
                if (this.client.isMe(msg.source.nickname)) {
                    targets.push(ROOT_BUFFER);
                }
                return targets;
            })
            .with(Message.Topic.select(), (msg) => [msg.channel])
            .with(Message.Reply.select(), (msg) => this.routeReply(msg))
            .with(
                Message.Away.select(),
                Message.SetName.select(),
                Message.Chghost.select(),
                Message.Account.select(),
                Message.CapLs.select(),
                Message.CapList.select(),
                Message.CapAck.select(),
                Message.CapNak.select(),
                Message.CapNew.select(),
                Message.CapDel.select(),
                Message.Authenticate.select(),
                Message.Ping.select(),
                Message.Pong.select(),
                Message.BatchStart.select(),
                Message.BatchEnd.select(),
                Message.ChatHistoryTargets.select(),
                Message.TagMsg.select(),
                Message.Ack.select(),
                Message.BouncerAddNetwork.select(),
                Message.BouncerChangeNetwork.select(),
                Message.BouncerDelNetwork.select(),
                Message.BouncerNetworkChanged.select(),
                Message.BouncerNetworkRemoved.select(),
                () => [],
            )
            .otherwise(() => [ROOT_BUFFER]);

        for (const buffer of affectedBuffers) {
            const buf = buffer === ROOT_BUFFER ? this.serverBuffer : this.buffers.upsert(buffer);

            if (buf instanceof ChannelBuffer || buf instanceof UserBuffer || buf instanceof ServerBuffer) {
                buf.messages.insert([msg]);
            }
        }
    };

    private routeReply = (msg: Narrow<Message, "Reply">["value"]) =>
        match(msg)
            .with(
                {
                    code: P.union(
                        Code.RPL_CHANNELMODEIS,
                        Code.ERR_CHANOPRIVSNEEDED,
                        Code.RPL_TOPIC,
                        Code.RPL_TOPICWHOTIME,
                        Code.RPL_NOTOPIC,
                        Code.RPL_CREATIONTIME,
                    ),
                    params: [P._, P.select(), ...P.array()],
                },
                (target) => (this.buffers.has(target) ? [target] : [ROOT_BUFFER]),
            )
            .otherwise(() => [ROOT_BUFFER]);

    isChannel = (name: string) => this.client.isChannel(name);

    send = (target: string, content: string, replyTo?: string) => {
        if (content.toLowerCase().startsWith("/raw ")) {
            const msg = parseRawMessage(content.slice(5).trimStart());

            if (msg) {
                this.client.send(msg);
            }

            return;
        }

        if (this.client.isServer(target)) {
            return;
        }

        const tags: Tags = {};

        if (replyTo) {
            tags["+draft/reply"] = replyTo;
        }

        const me = this.me;
        const lines = content.split(/\r?\n/);
        const echo = {
            tags,
            source: {
                nickname: this.client.nickname,
                username: me.whox.username ?? "",
                hostname: me.whox.hostname ?? "",
                account: me.whox.account ?? "",
            },
            target,
        };
        const chunkSize = computeMaxMessageLength({
            cmd: "PRIVMSG",
            tags: echo.tags,
            src: echo.source,
            params: [target],
        });

        for (const line of lines) {
            const chunks = line.match(new RegExp(`.{1,${chunkSize}}`, "g")) ?? [line];

            for (const chunk of chunks) {
                this.client.privmsg(target, chunk, replyTo);

                if (!this.client.caps.enabled(Capability.EchoMessage)) {
                    this.routeMessage(Message.Privmsg({ ...echo, time: new Date(), content: chunk }));
                }
            }
        }
    };

    join = (channel: string, key?: string) => this.client.join(channel, key);

    part = (channel: string) => this.client.part(channel);

    setAway = (reason?: string) => {
        this.client.setAway(reason);
        this.me.updateWhox({ isAway: true });
    };

    setOnline = () => {
        this.client.setOnline();
        this.me.updateWhox({ isAway: false });
    };

    nick = (nickname: string) => this.client.nick(nickname);

    setname = (realname: string) => this.client.setname(realname);

    topic = (channel: string, topic: string) => this.client.topic(channel, topic);

    who = (target: string) => this.client.who(target);

    mode = (target: string, modeChanges?: ModeChange[]) => {
        if (modeChanges?.length) {
            const [modes, modeArgs] = renderModes(modeChanges);
            return this.client.mode(target, modes, modeArgs);
        } else {
            return this.client.mode(target);
        }
    };

    list = async () => {
        this.channels.setStatus(ChannelListStatus.Loading);
        try {
            await this.client.list();
        } catch {
            this.channels.setStatus(ChannelListStatus.Idle);
        }
    };

    help = (subject: string) => this.client.help(subject);

    historyBefore = (target: string, anchor: Date) => {
        if (target === ROOT_BUFFER) {
            return Promise.resolve([]);
        }

        let limit = 100;
        if (this.client.caps[Capability.EventPlayback]?.enabled) {
            limit = 200;
        }

        return this.client.historyBefore(target, anchor, limit);
    };

    bouncerAddNetwork = (attrs: Partial<Omit<NetworkAttributes, "state">> & Pick<NetworkAttributes, "host">) =>
        this.client.bouncerAddNetwork(attrs);

    bouncerChangeNetwork = (id: string, attrs: Partial<Omit<NetworkAttributes, "state">>) =>
        this.client.bouncerChangeNetwork(id, attrs);

    bouncerDelNetwork = (id: string) => this.client.bouncerDelNetwork(id);

    markRead = (target: string, timestamp?: Date) => this.client.markRead(target, timestamp);

    typing = (target: string, typing: Typing) => this.client.typing(target, typing);

    moveUser = (oldNickname: string, newNickname: string) => {
        this.users.move(oldNickname, newNickname);
        this.buffers.move(oldNickname, newNickname);
        this.buffers.moveMember(oldNickname, newNickname);
    };
}
