import { ParentComponent, Show } from "solid-js";

import { Main } from "../layouts/Main";
import { Icon } from "../objects/Icon";

export const MessageView: ParentComponent<{ icon: string }> = (props) => (
    <>
        <Main centered>
            <Icon id={props.icon} class="u-ml-auto u-mr-auto" large block />
            <Show when={props.children}>{(children) => <div class="u-mt-100 u-ta-center">{children()}</div>}</Show>
        </Main>
    </>
);

export const ErrorView: ParentComponent = (props) => <MessageView icon="alert-triangle" {...props} />;

export const LoadingView: ParentComponent = (props) => <MessageView icon="loading" {...props} />;
