import { differenceInMilliseconds, isAfter, isSameDay } from "date-fns";
import { Exome } from "exome";
import { array as A, date as D, function as F, option as O } from "fp-ts";
import { nanoid } from "nanoid";
import { match, P } from "ts-pattern";

import { createIndexFinder } from "@/lib/array";
import * as Code from "@/lib/irc/codes";
import { Message } from "@/lib/irc/message";
import { ModeChange, parseModes } from "@/lib/irc/modes";
import { LinkData, Linkified, linkify } from "@/lib/linkify";
import { impl, Narrow, Variant } from "@/lib/unionTypes";

import { Buffer } from "./buffer";
import { UserBuffer } from "./buffer/userBuffer";
import { User } from "./user";

export const UNRENDERED_REPLIES = [
    Code.RPL_DATASTR,
    Code.RPL_HELPOP,
    Code.RPL_HELPTLR,
    Code.RPL_HELPHLP,
    Code.RPL_HELPFWD,
    Code.RPL_HELPIGN,
    Code.RPL_MOTDSTART,
    Code.RPL_MOTD,
    Code.RPL_ENDOFMOTD,
    Code.ERR_NOMOTD,
    Code.RPL_UMODEIS,
    Code.RPL_AWAY,
    Code.RPL_UNAWAY,
    Code.RPL_NOWAWAY,
    Code.RPL_TOPIC,
    Code.RPL_TOPICWHOTIME,
    Code.RPL_NOTOPIC,
    Code.RPL_CREATIONTIME,
    Code.RPL_CHANNELMODEIS,
    Code.RPL_LISTSTART,
    Code.RPL_LIST,
    Code.RPL_LISTEND,
    Code.RPL_HELPSTART,
    Code.RPL_HELPTXT,
    Code.RPL_ENDOFHELP,
    Code.RPL_INFOSTART,
    Code.RPL_INFO,
    Code.RPL_ENDOFINFO,
    Code.RPL_NAMREPLY,
    Code.RPL_ENDOFNAMES,
    Code.RPL_WHOSPCRPL,
    Code.RPL_WHOREPLY,
    Code.RPL_ENDOFWHO,
    Code.RPL_WHOISACCOUNT,
    Code.RPL_WHOISACTUALLY,
    Code.RPL_WHOISADMIN,
    Code.RPL_WHOISCERTFP,
    Code.RPL_WHOISCHANNELS,
    Code.RPL_WHOISCHANOP,
    Code.RPL_WHOISHELPER,
    Code.RPL_WHOISHELPOP,
    Code.RPL_WHOISHOST,
    Code.RPL_WHOISIDLE,
    Code.RPL_WHOISLANGUAGE,
    Code.RPL_WHOISMODES,
    Code.RPL_WHOISOPERATOR,
    Code.RPL_WHOISREGNICK,
    Code.RPL_WHOISSADMIN,
    Code.RPL_WHOISSECURE,
    Code.RPL_WHOISSERVER,
    Code.RPL_WHOISBOT,
    Code.RPL_ENDOFWHOIS,
    Code.RPL_WHOWASHOST,
    Code.RPL_WHOWASREAL,
    Code.RPL_WHOWASUSER,
    Code.RPL_ENDOFWHOWAS,
    Code.RPL_MONOFFLINE,
    Code.RPL_MONONLINE,
    Code.RPL_MONLIST,
];

export type RenderableSource = { user: User; nickname: string };

export interface RenderableMessageBase {
    time: Date;
    hash: string;
}

export enum TrafficLeave {
    Part = "part",
    Quit = "quit",
}
export interface Traffic {
    source: RenderableSource;
    join?: boolean;
    leave?: TrafficLeave;
}

export type NickChange = { source: RenderableSource; nickname: string };

export type RenderableMessage =
    | Variant<
          "Message",
          RenderableMessageBase & {
              id?: string;
              source: RenderableSource;
              notice: boolean;
              action: boolean;
              content: Linkified[][];
              links: LinkData[];
              raw: string;
              highlight: boolean;
              parentId?: string;
          }
      >
    | Variant<"Traffic", RenderableMessageBase & { until?: Date; traffic: Record<string, Traffic> }>
    | Variant<"Nick", RenderableMessageBase & { until?: Date; changes: [NickChange, ...NickChange[]] }>
    | Variant<"Topic", RenderableMessageBase & { source: RenderableSource; topic: Linkified[]; raw: string }>
    | Variant<
          "Mode",
          RenderableMessageBase & { until?: Date; source: RenderableSource; changes: [ModeChange, ...ModeChange[]] }
      >
    | Variant<"Reply", RenderableMessageBase & { code: number; params: string[] }>
    | Variant<"Motd", RenderableMessageBase & { content: Linkified[][]; links: LinkData[]; raw: string }>
    | Variant<"Info", RenderableMessageBase & { content: string }>;

export const RenderableMessage = impl<RenderableMessage>();

const findInsertionIndex = createIndexFinder(
    (item: { value: { time: Date } }) => item.value.time,
    (a, b) => a <= b,
);

export class MessageList extends Exome {
    private historySince?: Date;

    messages: RenderableMessage[] = [];

    messagesById = new Map<string, Narrow<RenderableMessage, "Message">>();

    lastRead?: O.Option<Date>;

    constructor(readonly buffer: Buffer) {
        super();

        setTimeout(() => {
            this.historyBefore();

            if (this.buffer instanceof UserBuffer) {
                this.getUnread();
            }
        }, 0);
    }

    private messageToRenderable = (msg: Message): O.Option<RenderableMessage> => {
        const base = {
            time: msg.value.time,
            hash: msg.value.tags.msgid ?? nanoid(),
        };

        return match(msg)
            .with(
                Message.Privmsg.pattern({}),
                Message.Notice.pattern({}),
                Message.Ctcp.pattern({ command: "ACTION" }),
                Message.CtcpNotice.pattern({ command: "ACTION" }),
                (msg) => {
                    const isCtcp = Message.Ctcp.is(msg) || Message.CtcpNotice.is(msg);
                    const text = isCtcp ? msg.value.params : msg.value.content;
                    const linkified = linkify(text);
                    const selfRegex = new RegExp(`\\b${this.buffer.network.me.whox.nickname}\\b`, "ui");

                    const highlight = selfRegex.test(text);

                    return O.some(
                        RenderableMessage.Message({
                            ...base,
                            id: msg.value.tags.msgid,
                            source: {
                                nickname: msg.value.source.nickname,
                                user: this.buffer.network.users.upsert(msg.value.source.nickname),
                            },
                            notice: Message.Notice.is(msg),
                            action: isCtcp,
                            content: [linkified.text],
                            links: linkified.links,
                            raw: isCtcp ? msg.value.params : msg.value.content,
                            highlight,
                            parentId: msg.value.tags["+draft/reply"],
                        }),
                    );
                },
            )
            .with(Message.Join.select(), (msg) =>
                O.some(
                    RenderableMessage.Traffic({
                        ...base,
                        traffic: {
                            [msg.source.nickname]: {
                                source: {
                                    nickname: msg.source.nickname,
                                    user: this.buffer.network.users.upsert(msg.source.nickname),
                                },
                                join: true,
                            },
                        },
                    }),
                ),
            )
            .with(Message.Part.select(), (msg) =>
                O.some(
                    RenderableMessage.Traffic({
                        ...base,
                        traffic: {
                            [msg.source.nickname]: {
                                source: {
                                    nickname: msg.source.nickname,
                                    user: this.buffer.network.users.upsert(msg.source.nickname),
                                },
                                leave: TrafficLeave.Part,
                            },
                        },
                    }),
                ),
            )
            .with(Message.Quit.select(), (msg) =>
                O.some(
                    RenderableMessage.Traffic({
                        ...base,
                        traffic: {
                            [msg.source.nickname]: {
                                source: {
                                    nickname: msg.source.nickname,
                                    user: this.buffer.network.users.upsert(msg.source.nickname),
                                },
                                leave: TrafficLeave.Quit,
                            },
                        },
                    }),
                ),
            )
            .with(Message.Nick.select(), (msg) =>
                O.some(
                    RenderableMessage.Nick({
                        ...base,
                        changes: [
                            {
                                nickname: msg.nickname,
                                source: {
                                    nickname: msg.source.nickname,
                                    user: this.buffer.network.users.upsert(msg.source.nickname),
                                },
                            },
                        ],
                    }),
                ),
            )
            .with(Message.Topic.select(), (msg) =>
                O.some(
                    RenderableMessage.Topic({
                        ...base,
                        source: {
                            nickname: msg.source.nickname,
                            user: this.buffer.network.users.upsert(msg.source.nickname),
                        },
                        topic: linkify(msg.topic).text,
                        raw: msg.topic,
                    }),
                ),
            )
            .with(Message.Mode.select(), (msg) => {
                const changes = this.buffer.network.isChannel(msg.target)
                    ? parseModes(msg.modes, msg.params, this.buffer.network.modes)
                    : parseModes(msg.modes, msg.params);

                if (!changes?.length) {
                    return O.none;
                }

                return O.some(
                    RenderableMessage.Mode({
                        ...base,
                        source: {
                            nickname: msg.source.nickname,
                            user: this.buffer.network.users.upsert(msg.source.nickname),
                        },
                        changes: changes as [ModeChange, ...ModeChange[]],
                    }),
                );
            })
            .with(
                Message.Reply.pattern({
                    code: P.select("code"),
                    params: [P._, ...P.array().select("params")],
                }),
                (msg) => !UNRENDERED_REPLIES.includes(msg.value.code),
                ({ code, params }) =>
                    O.some(
                        RenderableMessage.Reply({
                            ...base,
                            code,
                            params,
                        }),
                    ),
            )
            .otherwise(() => O.none);
    };

    private execSetLastRead(timestamp?: Date) {
        this.lastRead = O.fromNullable(timestamp);
    }

    private execInsertRenderable(msgs: RenderableMessage[]) {
        for (const msg of msgs) {
            if (RenderableMessage.Message.is(msg) && msg.value.id) {
                this.messagesById.set(msg.value.id, msg);
            }

            const i = findInsertionIndex(msg, this.messages);
            const prevMsg = i === -1 ? undefined : this.messages[i];

            match({ prevMsg, msg })
                .when(
                    ({ prevMsg, msg }) => !prevMsg || !isSameDay(msg.value.time, prevMsg.value.time),
                    () => this.messages.splice(i + 1, 0, msg),
                )
                .with(
                    { prevMsg: RenderableMessage.Message.pattern({}), msg: RenderableMessage.Message.pattern({}) },
                    ({ prevMsg, msg }) =>
                        msg.value.source.user === prevMsg.value.source.user &&
                        msg.value.action === prevMsg.value.action &&
                        msg.value.notice === prevMsg.value.notice &&
                        differenceInMilliseconds(msg.value.time, prevMsg.value.time) < 1000,
                    ({ prevMsg, msg }) => {
                        this.messages.splice(i, 1, {
                            ...prevMsg,
                            value: {
                                ...prevMsg.value,
                                content: [...prevMsg.value.content, ...msg.value.content],
                                links: [...prevMsg.value.links, ...msg.value.links],
                                raw: `${prevMsg.value.raw}\n${msg.value.raw}`,
                                time: msg.value.time,
                            },
                        });
                    },
                )
                .with(
                    { prevMsg: RenderableMessage.Topic.pattern({}), msg: RenderableMessage.Topic.pattern({}) },
                    ({ prevMsg, msg }) => msg.value.source.user === prevMsg.value.source.user,
                    ({ msg }) => this.messages.splice(i, 1, msg),
                )
                .with(
                    { prevMsg: RenderableMessage.Mode.pattern({}), msg: RenderableMessage.Mode.pattern({}) },
                    ({ prevMsg, msg }) => msg.value.source.user === prevMsg.value.source.user,
                    ({ prevMsg, msg }) => {
                        const changes: typeof prevMsg.value.changes = [...prevMsg.value.changes];

                        for (const change of msg.value.changes) {
                            const i = changes.findIndex((chg) => chg.mode === change.mode);
                            const prev = changes[i];

                            if (prev) {
                                if (prev.add && !change.add) {
                                    changes.splice(i, 1);
                                } else {
                                    changes.splice(i, 1, change);
                                }
                            } else {
                                changes.push(change);
                            }
                        }

                        const first = Object.values(changes)[0];

                        if (first) {
                            this.messages.splice(i, 1, {
                                ...prevMsg,
                                value: {
                                    ...prevMsg.value,
                                    until: msg.value.time,
                                    changes,
                                },
                            });
                        } else {
                            this.messages.splice(i, 1);
                        }
                    },
                )
                .with(
                    { prevMsg: RenderableMessage.Traffic.pattern({}), msg: RenderableMessage.Traffic.pattern({}) },
                    ({ prevMsg, msg }) => {
                        const traffic = { ...prevMsg.value.traffic };

                        for (const item of Object.values(msg.value.traffic)) {
                            const prev = traffic[item.source.nickname];

                            if (prev?.leave && !prev?.join && item.join) {
                                delete traffic[item.source.nickname];
                            } else if (prev?.leave && prev?.join && item.join) {
                                traffic[item.source.nickname] = {
                                    source: item.source,
                                    join: true,
                                };
                            } else {
                                traffic[item.source.nickname] = {
                                    source: item.source,
                                    join: item.join || prev?.join,
                                    leave: item.leave ?? prev?.leave,
                                };
                            }
                        }

                        const first = Object.values(traffic)[0];

                        if (first) {
                            this.messages.splice(i, 1, {
                                ...prevMsg,
                                value: {
                                    ...prevMsg.value,
                                    until:
                                        Object.keys(traffic).length !== 1 || (first.join && first.leave)
                                            ? msg.value.time
                                            : undefined,
                                    traffic,
                                },
                            });
                        } else {
                            this.messages.splice(i, 1);
                        }
                    },
                )
                .with(
                    { prevMsg: RenderableMessage.Nick.pattern({}), msg: RenderableMessage.Nick.pattern({}) },
                    ({ prevMsg, msg }) => {
                        const changes: typeof prevMsg.value.changes = [...prevMsg.value.changes];

                        for (const item of msg.value.changes) {
                            const i = changes.findIndex((change) => change.nickname === item.source.nickname);
                            const prev = changes[i];

                            if (prev?.source.nickname === item?.nickname) {
                                changes.splice(i, 1);
                            } else if (prev) {
                                changes.splice(i, 1, { source: prev.source, nickname: item.nickname });
                            } else {
                                changes.push(item);
                            }
                        }

                        if (changes.length) {
                            this.messages.splice(i, 1, {
                                ...prevMsg,
                                value: {
                                    ...prevMsg.value,
                                    until: msg.value.time,
                                    changes,
                                },
                            });
                        } else {
                            this.messages.splice(i, 1);
                        }
                    },
                )
                .otherwise(() => this.messages.splice(i + 1, 0, msg));
        }
    }

    setLastRead = (timestamp?: Date) => {
        if (!this.lastRead || !O.getEq(D.Eq).equals(this.lastRead, O.fromNullable(timestamp))) {
            this.execSetLastRead(timestamp);
        }
    };

    historyBefore = async () => {
        const anchor = this.messages[0]?.value.time ?? new Date();

        if (this.historySince && anchor >= this.historySince) {
            return;
        }

        this.historySince = anchor;

        const res = await this.buffer.network.historyBefore(this.buffer.id, anchor);

        this.insert(res);
    };

    getUnread = () => this.buffer.network.markRead(this.buffer.id);

    markRead = async () => {
        if (
            this.lastMessage &&
            this.lastRead &&
            !O.getEq(D.Eq).equals(this.lastRead, O.some(this.lastMessage.value.time))
        ) {
            await this.buffer.network.markRead(this.buffer.id, this.lastMessage.value.time);
        }
    };

    insert = (msgs: Message[]) => this.insertRenderable(F.pipe(msgs, A.filterMap(this.messageToRenderable)));

    insertRenderable = (msgs: RenderableMessage[]) => {
        if (msgs.length) {
            this.execInsertRenderable(msgs);
        }
    };

    get lastMessage() {
        return this.messages[this.messages.length - 1];
    }

    get numUnread() {
        if (!this.lastRead) {
            return 0;
        }

        if (O.isNone(this.lastRead)) {
            return this.messages.length;
        }

        let n = 0;

        for (let i = this.messages.length - 1; i >= 0; --i) {
            const message = this.messages[i]!;

            if (!isAfter(message.value.time, this.lastRead.value)) {
                break;
            }

            ++n;
        }

        return n;
    }
}
