export type CaseMapping = (str: string) => string;

export const rfc1459StrictCharmap = {
    "{": "[",
    "}": "]",
    "\\": "|",
};

export const rfc1459Charmap = {
    ...rfc1459StrictCharmap,
    "~": "^",
};

export function ascii(str: string) {
    return str.replace(/[A-Z]/g, (c) => c.toLowerCase());
}

export function rfc1459Strict(str: string) {
    return str.replace(/[A-Z{}\\]/g, (c) =>
        c >= "A" && c <= "Z" ? c.toLowerCase() : rfc1459StrictCharmap[c as keyof typeof rfc1459StrictCharmap],
    );
}

export function rfc1459(str: string) {
    return str.replace(/[A-Z{}~\\]/g, (c) =>
        c >= "A" && c <= "Z" ? c.toLowerCase() : rfc1459Charmap[c as keyof typeof rfc1459Charmap],
    );
}

export function getCaseMapping(name: string): CaseMapping | undefined {
    return { ascii, rfc1459, "rfc1459-strict": rfc1459Strict }[name];
}

export function caseMappedEquals(caseMapping: CaseMapping, s1: string) {
    const c1 = caseMapping(s1);
    return (s2: string) => caseMapping(s2) === c1;
}
