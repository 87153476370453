import { createContext, createSignal, For, JSX, onCleanup, onMount, ParentComponent } from "solid-js";

type CloseFn = () => void;

type ChildrenFn = (close: CloseFn) => JSX.Element;

type CreateFn = (children: ChildrenFn) => CloseFn;

const stack: CreateFn[] = [];

const show: CreateFn = (ch) => {
    if (stack.length) {
        return stack[stack.length - 1]!(ch);
    }
    return () => void 0;
};

export const OnDemandContext = createContext<CreateFn>(() => () => void 0);

export const OnDemandProvider: ParentComponent = (props) => {
    const [children, setChildren] = createSignal<Array<() => JSX.Element>>([], { equals: false });

    const scopedShow: CreateFn = (ch) => {
        const close = () => {
            setChildren((c) => {
                const i = c.indexOf(children);
                if (i !== -1) {
                    c.splice(i, 1);
                }
                return c;
            });
        };
        const children = () => ch(close);

        setChildren((c) => {
            c.push(children);
            return c;
        });

        return close;
    };

    onMount(() => {
        stack.push(scopedShow);
    });

    onCleanup(() => {
        stack.pop();
    });

    return (
        <OnDemandContext.Provider value={show}>
            {props.children}
            <For each={children()}>{(ch) => ch()}</For>
        </OnDemandContext.Provider>
    );
};
