import { Component, Index, JSX, splitProps, useContext } from "solid-js";

import { Lightbox } from "@/iro/objects/Lightbox";
import { OnDemandContext } from "@/iro/objects/OnDemand";
import icons from "#/icons.svg";

import { Attachment } from "../objects/Attachment";

export const AttachmentList: Component<{ attachments: Attachment[] } & JSX.HTMLAttributes<HTMLElement>> = (props_) => {
    const [props, propsRest] = splitProps(props_, ["attachments", "class"]);

    const onDemand = useContext(OnDemandContext);

    const handleImageClick = (index: number, e: MouseEvent) => {
        e.preventDefault();
        onDemand((close) => <Lightbox iconSource={icons} items={props.attachments} index={index} onClose={close} />);
    };

    return (
        <section
            class={`l-attachment-list l-attachment-list--grid l-attachment-list--grid-${props.attachments.length} ${
                props.attachments.length > 4 ? "l-attachment-list--compact" : ""
            } ${props.class}`}
            {...propsRest}
        >
            <Index each={props.attachments}>
                {(attachment, i) => <Attachment attachment={attachment()} onClick={[handleImageClick, i]} />}
            </Index>
        </section>
    );
};
