import { Exome } from "exome";

import { Draft } from "./draft";
import { MessageList } from "./messageList";
import { Network } from "./network";

export const ROOT_BUFFER = "~";

export const CHANNEL_LIST_BUFFER = "*:channels";

export interface Buffer extends Exome {
    id: string;

    network: Network;

    messages: MessageList;

    draft: Draft;

    setId(id: string): void;

    send(): void;
}
