import { Exome } from "exome";

import { CaseMappedMap } from "@/lib/caseMappedMap";
import { CaseMapping } from "@/lib/irc/caseMapping";
import { Whox } from "@/lib/irc/whox";

import { Network } from "./network";
import { User } from "./user";

export class UserList extends Exome {
    users: CaseMappedMap<User>;

    constructor(
        readonly network: Network,
        caseMapping: CaseMapping,
    ) {
        super();

        this.users = new CaseMappedMap(caseMapping);
    }

    updateCaseMapping(caseMapping: CaseMapping) {
        this.users = new CaseMappedMap(caseMapping, this.users);
    }

    private create(nickname: string, whox?: Whox) {
        const user = new User(this.network, { ...whox, nickname });
        this.users.set(nickname, user);
        return user;
    }

    private execDelete(nickname: string) {
        const user = this.users.get(nickname);
        this.users.delete(nickname);
        return user;
    }

    private execMove(oldNickname: string, newNickname: string) {
        const user = this.users.get(oldNickname);

        if (user) {
            this.users.delete(oldNickname);
            user.updateWhox({ nickname: newNickname });
            this.users.set(newNickname, user);
        }

        return user;
    }

    get = (nickname: string) => this.users.get(nickname);

    upsert = (nickname: string, whox?: Whox) => {
        let user = this.users.get(nickname);

        if (user) {
            if (whox) {
                user.updateWhox(whox);
            }
        } else {
            user = this.create(nickname, whox);
        }

        return user;
    };

    delete = (nickname: string) => {
        if (this.users.has(nickname)) {
            return this.execDelete(nickname);
        }
    };

    move = (oldNickname: string, newNickname: string) => {
        if (this.users.has(oldNickname)) {
            return this.execMove(oldNickname, newNickname);
        }
    };

    clear() {
        this.users.clear();
    }
}
