import { Exome } from "exome";

import { CaseMapping } from "@/lib/irc/caseMapping";
import { ModeChange } from "@/lib/irc/modes";
import { Linkified, linkify } from "@/lib/linkify";

import { Buffer } from "../buffer";
import { Draft } from "../draft";
import { MemberList } from "../memberList";
import { MessageList } from "../messageList";
import { Network } from "../network";

export class ChannelBuffer extends Exome implements Buffer {
    messages: MessageList = new MessageList(this);

    draft = new Draft();

    rawTopic = "";

    topic: Linkified[] = [];

    topicProvenance?: { nickname: string; setAt: Date };

    members: MemberList;

    modes = new Map<string, string | undefined>();

    createdAt?: Date;

    constructor(
        public id: string,
        readonly network: Network,
        caseMapping: CaseMapping,
    ) {
        super();

        this.members = new MemberList(this, caseMapping);
        network.who(id);
        network.mode(id);
    }

    setId(id: string) {
        this.id = id;
    }

    setTopic(topic: string) {
        this.rawTopic = topic;
        this.topic = linkify(topic).text;
        this.topicProvenance = undefined;
    }

    setTopicProvenance(nickname: string, setAt: Date) {
        this.topicProvenance = { nickname, setAt };
    }

    setModes(modes: ModeChange[]) {
        for (const mode of modes.values()) {
            if (mode.add) {
                this.modes.set(mode.mode, mode.param);
            } else {
                this.modes.delete(mode.mode);
            }
        }
    }

    setCreatedAt(value?: Date) {
        this.createdAt = value;
    }

    send = () => {
        if (this.draft.current !== "") {
            this.network.send(this.id, this.draft.current, this.draft.replyTo);
            this.draft.submit();
        }
    };

    topicCmd = (topic: string) => this.network.topic(this.id, topic);

    modeCmd = (modes: ModeChange[]) => this.network.mode(this.id, modes);

    moveMember = (oldNickname: string, newNickname: string) => this.members.move(oldNickname, newNickname);

    deleteMember = (nickname: string) => this.members.delete(nickname);

    part = () => this.network.part(this.id);
}
