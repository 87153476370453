import { function as F, ord as Or, string as S } from "fp-ts";
import { Component, createMemo, For, Match, on, Show, Switch, useContext } from "solid-js";
import { P } from "ts-pattern";

import { ActionButton } from "@/iro/objects/ActionButton";
import { Badge } from "@/iro/objects/Badge";
import { Divider } from "@/iro/objects/Divider";
import { Linkified } from "@/iro/objects/Linkified";
import { Menu, MenuItem } from "@/iro/objects/Menu";
import { Time } from "@/iro/objects/Time";
import { useStore, useStores } from "@/lib/exome/solid";
import { isMatchingN } from "@/lib/ts-pattern/util";
import { Buffer as BufferStore } from "@/store/buffer";
import { ChannelBuffer } from "@/store/buffer/channelBuffer";
import { ServerBuffer } from "@/store/buffer/serverBuffer";
import { UserBuffer } from "@/store/buffer/userBuffer";
import { ChannelUser as ChannelUser_ } from "@/store/memberList";
import { Sidebar } from "@/ui/layouts/Sidebar";
import { ModeList } from "@/ui/objects/ModeList";
import { UserConfig } from "@/userConfig";

import { Header } from "../../layouts/Header";
import { Icon } from "../../objects/Icon";
import { UserCard } from "../UserCard";

const channelUserOrd: Or.Ord<ChannelUser_> = F.pipe(
    S.Ord,
    Or.contramap((user: ChannelUser_) => user.user.whox.nickname),
);

const ChannelUser: Component<{ buffer: ChannelBuffer; user: ChannelUser_ }> = (props) => {
    const buffer = useStore(() => props.buffer);
    const user = useStore(() => props.user.user);

    const handleClick = () => {
        buffer().draft.update((s) => `${s}${!s || s.endsWith(" ") ? "" : " "}${user().whox.nickname} `);
    };

    return (
        <MenuItem onClick={handleClick}>
            <UserCard user={props.user.user}>
                <Show when={props.user.prefix}>
                    <Badge menu>{props.user.prefix}</Badge>
                </Show>
            </UserCard>
        </MenuItem>
    );
};

const ChannelBufferSidebar: Component<{ buffer: ChannelBuffer }> = (props) => {
    const [userConfig, setUserConfig] = useContext(UserConfig);

    const buffer = useStore(() => props.buffer);
    const members = useStore(() => buffer().members);
    const users = useStores(() => Array.from(members().members.values()).map((member) => member.user));

    const sortedMembers = createMemo(
        on([members, users], ([members]) => Array.from(members.members.values()).sort(channelUserOrd.compare)),
    );

    const handleClose = () => setUserConfig("channelSidebar", false);

    return (
        <Show when={userConfig.channelSidebar}>
            <Sidebar location="right">
                <Header side theme="hi">
                    <strong class="l-media__block l-media__block--main">Channel info</strong>
                    <div class="l-media__block l-header__button-end">
                        <ActionButton quiet round onClick={handleClose}>
                            <Icon id="x" block />
                        </ActionButton>
                    </div>
                </Header>

                <div class="l-sidebar__content">
                    <h2 class="u-mt-0">Topic</h2>

                    <p class="s-links s-links--colored">
                        <Linkified content={[buffer().topic]} />
                    </p>

                    <Show when={buffer().topicProvenance}>
                        {(topicProvenance) => (
                            <p class="s-links">
                                <small>
                                    Set by {topicProvenance().nickname} on <Time date={topicProvenance().setAt} />
                                </small>
                            </p>
                        )}
                    </Show>

                    <Show when={buffer().modes.size}>
                        <Divider variant="faint" class="u-mt-400 u-mb-400" />

                        <h2 class="u-mt-0">Modes</h2>

                        <ModeList scope="channel" modes={buffer().modes} class="u-mt-200" />
                    </Show>

                    <Divider variant="faint" class="u-mt-400 u-mb-400" />

                    <h2 class="u-mt-0">Members ({members().members.size})</h2>

                    <Menu pull class="u-mt-200">
                        <For each={sortedMembers()}>{(user) => <ChannelUser buffer={props.buffer} user={user} />}</For>
                    </Menu>
                </div>
            </Sidebar>
        </Show>
    );
};

const UserBufferSidebar: Component<{ buffer: UserBuffer }> = (props) => {
    const [userConfig, setUserConfig] = useContext(UserConfig);

    const buffer = useStore(() => props.buffer);
    const network = useStore(() => buffer().network);
    const user = useStore(() => buffer().user);

    const handleClose = () => setUserConfig("userSidebar", false);

    return (
        <Show when={userConfig.userSidebar}>
            <Sidebar location="right">
                <Header side theme="hi">
                    <strong class="l-media__block l-media__block--main">User info</strong>
                    <div class="l-media__block l-header__button-end">
                        <ActionButton quiet round onClick={handleClose}>
                            <Icon id="x" block />
                        </ActionButton>
                    </div>
                </Header>

                <div class="l-sidebar__content" />
            </Sidebar>
        </Show>
    );
};

const ServerBufferSidebar: Component<{ buffer: ServerBuffer }> = (props) => {
    const [userConfig, setUserConfig] = useContext(UserConfig);

    const buffer = useStore(() => props.buffer);
    const network = useStore(() => buffer().network);

    const handleClose = () => setUserConfig("networkSidebar", false);

    return (
        <Show when={userConfig.networkSidebar}>
            <Sidebar location="right">
                <Header side theme="hi">
                    <strong class="l-media__block l-media__block--main">Network info</strong>
                    <div class="l-media__block l-header__button-end">
                        <ActionButton quiet round onClick={handleClose}>
                            <Icon id="x" block />
                        </ActionButton>
                    </div>
                </Header>

                <div class="l-sidebar__content" />
            </Sidebar>
        </Show>
    );
};

export const BufferSidebar: Component<{ buffer: BufferStore }> = (props) => (
    <Switch>
        <Match when={isMatchingN(P.instanceOf(ServerBuffer), props.buffer)}>
            {(buffer) => <ServerBufferSidebar buffer={buffer()} />}
        </Match>
        <Match when={isMatchingN(P.instanceOf(ChannelBuffer), props.buffer)}>
            {(buffer) => <ChannelBufferSidebar buffer={buffer()} />}
        </Match>
        <Match when={isMatchingN(P.instanceOf(UserBuffer), props.buffer)}>
            {(buffer) => <UserBufferSidebar buffer={buffer()} />}
        </Match>
    </Switch>
);
