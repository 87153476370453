import { isEnum } from "@/lib/types";

import { parseTags } from "./rawMessage";

export enum BouncerNetworkState {
    Connected = "connected",
    Connecting = "connecting",
    Disconnected = "disconnected",
}

export const isBouncerNetworkState = isEnum(BouncerNetworkState);

export interface NetworkAttributes {
    name: string;
    state: BouncerNetworkState;
    host: string;
    port: number;
    tls: boolean;
    nickname: string;
    username: string;
    realname: string;
    pass: string;
}

export function newNetworkAttributes(): NetworkAttributes {
    return {
        name: "",
        state: BouncerNetworkState.Disconnected,
        host: "",
        port: 6697,
        tls: true,
        nickname: "",
        username: "",
        realname: "",
        pass: "",
    };
}

export function parseNetworkAttributes(attrs: string) {
    return Object.entries(parseTags(attrs)).reduce((r: Partial<NetworkAttributes>, [key, value]) => {
        switch (key) {
            case "port": {
                const port = +value;
                return value && !isNaN(port) ? { ...r, [key]: port } : r;
            }

            case "tls":
                return { ...r, [key]: value === "1" };

            case "state":
                return isBouncerNetworkState(value) ? { ...r, [key]: value } : r;

            default:
                return { ...r, [key]: value };
        }
    }, {});
}

export function renderNetworkAttributes(attrs: Partial<NetworkAttributes>) {
    return Object.entries(attrs)
        .map(([key, value]) => `${key}=${typeof value === "boolean" ? (value ? "1" : "0") : value}`)
        .join(";");
}
