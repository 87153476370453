import { useNavigate } from "@solidjs/router";
import { differenceInMinutes } from "date-fns";
import { function as F, number as N, ord as Or, string as S } from "fp-ts";
import { Component, createEffect, createMemo, createSignal, Index, on, onMount, Show } from "solid-js";
import { createStore } from "solid-js/store";

import { useScrollTrigger } from "@/iro/hooks/scrollTriggers";
import { FormItem } from "@/iro/layouts/Form";
import { ActionButton } from "@/iro/objects/ActionButton";
import { ButtonLink } from "@/iro/objects/Button";
import { Dialog } from "@/iro/objects/Dialog";
import { Linkified } from "@/iro/objects/Linkified";
import { Select } from "@/iro/objects/Select";
import { Table, TableBody, TableCell, TableHead, TableHeadCell, TableRow } from "@/iro/objects/Table";
import { TextField } from "@/iro/objects/TextField";
import { useStore, useStores } from "@/lib/exome/solid";
import { ChannelListItem as ChannelListItemObj } from "@/lib/irc/list";
import * as routes from "@/routes";
import { ChannelListStatus } from "@/store/channelList";
import { Network } from "@/store/network";
import { networkList as networkListStore } from "@/store/networkList";

import { Icon } from "../objects/Icon";

const PAGE_SIZE = 100;

enum Order {
    Clients = "clients",
    Name = "name",
}

type RenderableChannelListItem = { network: Network; channel: ChannelListItemObj };

const itemByClientsOrd: Or.Ord<RenderableChannelListItem> = F.pipe(
    N.Ord,
    Or.reverse,
    Or.contramap((item) => item.channel.clients),
);

const itemByNameOrd: Or.Ord<RenderableChannelListItem> = F.pipe(
    S.Ord,
    Or.contramap((item) => item.channel.channel),
);

const ChannelListItem: Component<{
    network: Network;
    channel: ChannelListItemObj;
    onClose?: (trigger?: string) => void;
}> = (props) => {
    const navigate = useNavigate();

    const network = useStore(() => props.network);

    const handleClick = async (e?: Event) => {
        e?.preventDefault();
        props.onClose?.();
        await network().join(props.channel.channel);
        navigate(routes.chat(network().id, encodeURIComponent(props.channel.channel)));
    };

    return (
        <TableRow>
            <TableCell>
                <strong class="u-d-block u-elp">{props.channel.channel}</strong>
                <div class="s-links s-links--colored">
                    <Linkified content={props.channel.topic} />
                </div>
            </TableCell>
            <TableCell class="u-ta-right">{props.channel.clients}</TableCell>
            <TableCell>{network().name}</TableCell>
            <TableCell class="u-ta-right">
                <ButtonLink
                    href={routes.chat(network().id, encodeURIComponent(props.channel.channel))}
                    variant="secondary"
                    onClick={handleClick}
                >
                    Join
                </ButtonLink>
            </TableCell>
        </TableRow>
    );
};

export const ExploreDialog: Component<{
    network?: Network;
    onClose?: (trigger?: string) => void;
}> = (props) => {
    let el!: HTMLTableElement;
    let queryEl!: HTMLInputElement;

    const [mountScrollTrigger, atBottom] = useScrollTrigger("bottom");

    const networkList = useStore(() => networkListStore);
    const networks = useStores(() =>
        Array.from(networkList().networks.values()).filter((network) => !network.isRoot || !network.isBouncer),
    );
    const channelLists = useStores(() => networks().map((network) => network.channels));
    const loading = createMemo(() =>
        channelLists().reduce((loading, list) => loading || list.status !== ChannelListStatus.Idle, false),
    );

    const networkOptions = createMemo(() => networks().map((network) => ({ value: network.id, label: network.name })));

    const [max, setMax] = createSignal(PAGE_SIZE);

    const [filter, setFilter] = createStore({
        order: Order.Clients,
        network: "",
        query: "",
    });
    const [appliedFilter, setAppliedFilter] = createSignal({ ...filter });

    const allItems = createMemo(() =>
        channelLists().flatMap((list) =>
            list.channels.map((channel): RenderableChannelListItem => ({ network: list.network, channel })),
        ),
    );
    const filteredItems = createMemo(() => {
        let items = allItems();

        if (appliedFilter().network) {
            items = items.filter((channel) => channel.network.id === appliedFilter().network);
        }

        if (appliedFilter().query) {
            const [, regexQuery] =
                appliedFilter()
                    .query.trim()
                    .match(/^\/(.*)\/$/) ?? [];
            let regex;

            if (regexQuery) {
                try {
                    regex = new RegExp(regexQuery, "ui");
                } catch {
                    /**/
                }
            }

            if (regex) {
                items = items.filter((item) => regex!.test(item.channel.channel) || regex!.test(item.channel.topic));
            } else {
                items = items.filter(
                    (item) =>
                        item.channel.channel.includes(appliedFilter().query) ||
                        item.channel.topic.includes(appliedFilter().query),
                );
            }
        }

        switch (appliedFilter().order) {
            case Order.Clients:
                items.sort(itemByClientsOrd.compare);
                break;

            case Order.Name:
                items.sort(itemByNameOrd.compare);
                break;
        }

        return items;
    });
    const items = createMemo(() => filteredItems().slice(0, max()));

    const handleClose = async (result?: string, e?: SubmitEvent) => {
        e?.preventDefault();
        props.onClose?.(result);
    };

    const setTextFieldHandler = (
        key: keyof typeof filter,
        e: InputEvent & { currentTarget: HTMLInputElement | HTMLSelectElement },
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
    ) => setFilter(key, e.currentTarget.value as any);

    const setNetworkHandler = (e: InputEvent & { currentTarget: HTMLSelectElement }) => {
        setFilter("network", e.currentTarget.value);
    };

    const setOrderHandler = (e: InputEvent & { currentTarget: HTMLSelectElement }) => {
        setFilter("order", e.currentTarget.value as Order);
    };

    const refresh = (force?: boolean) => {
        for (const list of channelLists()) {
            if (
                list.status !== ChannelListStatus.Loading &&
                (force || !list.lastUpdate || differenceInMinutes(list.lastUpdate, new Date()) > 30)
            ) {
                list.network.list();
            }
        }
    };

    createEffect(on(atBottom, (atBottom) => atBottom && setMax((n) => n + PAGE_SIZE)));

    createEffect(
        on(
            [() => filter.query, () => filter.network, () => filter.order],
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            ([query, network, order], _, timeout?: any) => {
                if (timeout) {
                    clearTimeout(timeout);
                }
                return setTimeout(() => setAppliedFilter({ query, network, order }), 200);
            },
        ),
    );

    onMount(() => {
        mountScrollTrigger(el);

        queryEl.focus();

        if (props.network) {
            setFilter("network", props.network.id);
        }

        refresh();
    });

    return (
        <Dialog
            title={
                <>
                    Explore{" "}
                    <Show when={loading()}>
                        <Icon id="loading" class="u-ml-50" />
                    </Show>
                </>
            }
            size="lg"
            onClose={handleClose}
            scrollable
            noCancel
            okLabel="Close"
            top={
                <div class="l-media l-media--flush" style={{ "align-items": "flex-end" }}>
                    <FormItem label="Looking for" class="l-media__block l-media__block--main">
                        <TextField value={filter.query} onInput={[setTextFieldHandler, "query"]} ref={queryEl} />
                    </FormItem>
                    <Show when={networkOptions().length > 1}>
                        <FormItem label="Network" class="l-media__block">
                            <Select
                                options={[{ value: "", label: "All" }, ...networkOptions()]}
                                value={filter.network}
                                onInput={setNetworkHandler}
                            />
                        </FormItem>
                    </Show>
                    <FormItem label="Order" class="l-media__block">
                        <Select
                            options={[
                                { value: Order.Clients, label: "Members" },
                                { value: Order.Name, label: "Name" },
                            ]}
                            value={filter.order}
                            onInput={setOrderHandler}
                        />
                    </FormItem>
                    <ActionButton class="l-media__block" onClick={[refresh, true]} disabled={loading()}>
                        <Icon id="repeat" block />
                    </ActionButton>
                </div>
            }
        >
            <Table flush fixed class="u-w-100" ref={el}>
                <TableHead>
                    <TableRow>
                        <TableHeadCell>Channel</TableHeadCell>
                        <TableHeadCell class="u-ta-right" style={{ width: "10%" }}>
                            Members
                        </TableHeadCell>
                        <TableHeadCell style={{ width: "10em" }}>Network</TableHeadCell>
                        <TableHeadCell style={{ width: "8em" }} />
                    </TableRow>
                </TableHead>
                <TableBody>
                    <Index each={items()}>
                        {(item) => <ChannelListItem network={item().network} channel={item().channel} />}
                    </Index>
                </TableBody>
            </Table>
        </Dialog>
    );
};
