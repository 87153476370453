import { Component, JSX, mergeProps, splitProps } from "solid-js";

export const MessageHeader: Component<JSX.HTMLAttributes<HTMLElement>> = (_props) => {
    const propsWithDefaults = mergeProps({ class: "" }, _props);
    const [props, propsRest] = splitProps(propsWithDefaults, ["class"]);

    return <header class={`o-message__header ${props.class}`} {...propsRest} />;
};

export const Message: Component<
    { size?: "75"; bubble?: boolean; highlight?: boolean; theme?: string } & JSX.HTMLAttributes<HTMLElement>
> = (_props) => {
    const propsWithDefaults = mergeProps({ size: "100", class: "" }, _props);
    const [props, propsRest] = splitProps(propsWithDefaults, ["size", "bubble", "highlight", "theme", "class"]);

    return (
        <article
            class={`o-message o-message--${props.size} ${props.highlight ? "o-message--highlight" : ""} ${
                props.theme ? `t-${props.theme}` : ""
            } ${props.bubble || props.theme ? "o-message--bubble" : ""} ${props.class}`}
            {...propsRest}
        />
    );
};
