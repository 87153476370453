import { Component, createMemo, mergeProps, ParentComponent, Show } from "solid-js";
import { Dynamic } from "solid-js/web";

import { cyrb53 } from "@/lib/cyrb53";
import { useStore } from "@/lib/exome/solid";
import { RenderableSource } from "@/store/messageList";

export const DynamicMention: Component<{ tag?: string; source: RenderableSource; class?: string }> = (_props) => {
    const props = mergeProps({ tag: "span", class: "" }, _props);

    const user = useStore(() => props.source.user);
    const nickname = () => props.source.nickname ?? user().whox.nickname;
    const originalNickname = () => (user().whox.nickname !== nickname() ? user().whox.nickname : undefined);
    const hue = createMemo(() => cyrb53(nickname()) % 360);

    return (
        <Dynamic component={props.tag} class={`o-mention ${props.class}`} style={{ "--mention--colors--h": hue() }}>
            {nickname()}
            <Show when={originalNickname()}>{(nickname) => ` (${nickname()})`}</Show>
        </Dynamic>
    );
};

export const StaticMention: ParentComponent<{ tag?: string; class?: string }> = (_props) => {
    const props = mergeProps({ tag: "span", class: "" }, _props);

    const hue = createMemo(() => (typeof props.children === "string" ? cyrb53(props.children) % 360 : 0));

    return (
        <Dynamic component={props.tag} class={`o-mention ${props.class}`} style={{ "--mention--colors--h": hue() }}>
            {props.children}
        </Dynamic>
    );
};
