import { Component, createSignal, Index, JSX, Match, mergeProps, onCleanup, onMount, Show, Switch } from "solid-js";

import { useClickOutside } from "../hooks/clickOutside";
import { ActionButton } from "./ActionButton";
import { Backdrop } from "./Backdrop";
import { Icon } from "./Icon";

export interface LightboxItem {
    src: string;
    thumbnail?: string;
    caption?: JSX.Element;
    type?: string;
}

function itemIcon(item: LightboxItem) {
    return item.type?.startsWith("video/") ? "video" : item.type?.startsWith("audio/") ? "volume-2" : "image";
}

export const Lightbox: Component<{
    iconSource: string;
    items: LightboxItem[];
    index?: number;
    onClose?: () => void;
}> = (_props) => {
    const props = mergeProps({ index: 0, onClose: () => void 0 }, _props);

    let el!: HTMLDivElement;

    const propsIndex = () => Math.min(Math.max(props.index, 0), props.items.length - 1);

    const [index, setIndex] = createSignal(0);
    const item = () => props.items[index()];

    const close = () => props.onClose();
    const prev = () => setIndex((i) => (i > 0 ? i - 1 : props.items.length - 1));
    const next = () => setIndex((i) => (i < props.items.length - 1 ? i + 1 : 0));

    const handleKey = (e: KeyboardEvent) => {
        if (e.defaultPrevented) {
            return;
        }

        switch (e.key) {
            case "Left":
            case "ArrowLeft":
                prev();
                break;

            case "Right":
            case "ArrowRight":
                next();
                break;

            default:
                return;
        }

        e.preventDefault();
    };

    const [mountClickOutside] = useClickOutside(close, { excludeRoot: true });

    onMount(() => {
        setIndex(propsIndex());
        mountClickOutside(el);
        window.addEventListener("keydown", handleKey, { capture: true });
    });

    onCleanup(() => {
        window.removeEventListener("keydown", handleKey, { capture: true });
    });

    return (
        <Backdrop class="t-dark">
            <div ref={el} class="o-lightbox">
                <Show when={item()}>
                    {(item) => (
                        <>
                            <header class="o-lightbox__header">
                                {item().caption}

                                <ActionButton round quiet class="o-lightbox__close-btn" onClick={close}>
                                    <Icon source={props.iconSource} id="x" />
                                </ActionButton>
                            </header>

                            <Switch>
                                <Match when={item().type?.startsWith("image/")}>
                                    <img class="o-lightbox__img" src={item().src} />
                                </Match>
                                <Match when={item().type?.startsWith("video/")}>
                                    <video controls class="o-lightbox__img">
                                        <source src={item().src} type={item().type} />
                                    </video>
                                </Match>
                                <Match when={item().type?.startsWith("audio/")}>
                                    <audio controls class="o-lightbox__img">
                                        <source src={item().src} type={item().type} />
                                    </audio>
                                </Match>
                            </Switch>
                        </>
                    )}
                </Show>

                <Show when={props.items.length > 1}>
                    <ActionButton round quiet class="o-lightbox__nav-btn o-lightbox__nav-btn--prev" onClick={prev}>
                        <Icon source={props.iconSource} id="chevron-left" />
                    </ActionButton>
                    <ActionButton round quiet class="o-lightbox__nav-btn o-lightbox__nav-btn--next" onClick={next}>
                        <Icon source={props.iconSource} id="chevron-right" />
                    </ActionButton>
                    <footer class="o-lightbox__thumbnails">
                        <Index each={props.items}>
                            {(item, i) => (
                                <button
                                    onClick={[setIndex, i]}
                                    class={`o-lightbox__thumbnail ${i === index() ? "is-selected" : ""}`}
                                >
                                    <Show
                                        when={item().type?.startsWith("image/")}
                                        fallback={
                                            <Icon
                                                source={props.iconSource}
                                                class="o-lightbox__thumbnail-icon"
                                                id={itemIcon(item())}
                                            />
                                        }
                                    >
                                        <img src={item().thumbnail ?? item().src} class="o-lightbox__thumbnail-img" />
                                    </Show>
                                </button>
                            )}
                        </Index>
                    </footer>
                </Show>
            </div>
        </Backdrop>
    );
};
