import { Accessor, createEffect, on, onCleanup } from "solid-js";

import { getScrollContainer } from "@/lib/scroll";

export interface ScrollPosition {
    hash: string;
    offset: number;
}

const positions: Record<string, ScrollPosition | undefined> = {};

export const usePersistentScroll = ({
    id,
    anchor = "bottom",
    selector,
    hashAttr,
}: {
    id: Accessor<string>;
    anchor?: "top" | "bottom";
    selector: string;
    hashAttr: string;
}) => {
    const position = () => positions[id()];
    const setPosition = (position?: ScrollPosition) => {
        positions[id()] = position;
    };

    let container: HTMLElement | undefined;
    let noSave = false;

    const ignoreSave = (v: boolean) => {
        noSave = v;
    };

    const saveTop = () => {
        if (!container || noSave) {
            return;
        }

        const selected = container.querySelectorAll<HTMLElement>(selector);
        let newPosition: ScrollPosition | undefined;

        if (container.scrollTop > 0) {
            for (let i = selected.length - 1; i >= 0; --i) {
                const el = selected[i]!;
                const offset = el.offsetTop - container.scrollTop - container.offsetTop;

                if (offset <= container.offsetTop) {
                    const hash = el.dataset[hashAttr];

                    if (hash) {
                        newPosition = { hash, offset };
                        break;
                    }
                }
            }
        }

        setPosition(newPosition);
    };

    const saveBottom = () => {
        if (!container || noSave) {
            return;
        }

        const selected = container.querySelectorAll<HTMLElement>(selector);
        let newPosition: ScrollPosition | undefined;

        if (container.scrollTop < container.scrollHeight - container.offsetHeight) {
            for (let i = 0; i < selected.length; ++i) {
                const el = selected[i]!;
                const offset = el.offsetTop + el.clientHeight - container.scrollTop - container.offsetTop;

                if (offset >= container.offsetHeight) {
                    const hash = el.dataset[hashAttr];

                    if (hash) {
                        newPosition = { hash, offset };
                        break;
                    }
                }
            }
        }

        setPosition(newPosition);
    };

    const restoreTop = () => {
        if (!container) {
            return;
        }
        const pos = position();
        if (!pos) {
            container.scrollTop = 0;
        } else {
            const el = container.querySelector<HTMLElement>(`[data-${hashAttr}="${pos.hash}"]`);

            if (el) {
                container.scrollTop = el.offsetTop - container.offsetTop - pos.offset;
            }
        }
    };

    const restoreBottom = () => {
        if (!container) {
            return;
        }
        const pos = position();
        if (!pos) {
            container.scrollTop = container.scrollHeight;
        } else {
            const el = container.querySelector<HTMLElement>(`[data-${hashAttr}="${pos.hash}"]`);

            if (el) {
                container.scrollTop = el.offsetTop + el.clientHeight - container.offsetTop - pos.offset;
            }
        }
    };

    const save = anchor === "top" ? saveTop : saveBottom;

    const restore = anchor === "top" ? restoreTop : restoreBottom;

    const reset = () => setPosition(undefined);

    const mount = (el: HTMLElement) => {
        container = getScrollContainer(el) ?? document.body;
        restore();
        container.addEventListener("scroll", save);
        window.addEventListener("resize", restore);
    };

    const unmount = () => {
        window.removeEventListener("resize", restore);
        container?.removeEventListener("scroll", save);
    };

    createEffect(on(id, restore));

    onCleanup(unmount);

    return { mount, unmount, restore, save, reset };
};
