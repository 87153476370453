/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/ban-types */

import { JSX } from "solid-js";

export function callEventHandler<T, E extends Event>(callback?: JSX.EventHandlerUnion<T, E>, ...args: any[]) {
    if (Array.isArray(callback)) {
        callback[0](callback[1], ...args);
    } else if (typeof callback === "function") {
        (callback as Function)(...args);
    }
}
