import { mapIterableIterator } from "./iterator";

export class CaseMappedMap<V> implements Map<string, V> {
    public readonly mapKey: (key: string) => string;

    readonly map = new Map<string, { key: string; value: V }>();

    constructor(mapKey: (key: string) => string, entries?: readonly (readonly [string, V])[] | CaseMappedMap<V> | null);
    constructor(map: CaseMappedMap<V>);
    constructor(
        mapKey: ((key: string) => string) | CaseMappedMap<V>,
        entries?: readonly (readonly [string, V])[] | CaseMappedMap<V> | null,
    ) {
        if (mapKey instanceof CaseMappedMap) {
            this.mapKey = mapKey.mapKey;
            this.map = new Map(mapKey.map);
        } else {
            this.mapKey = mapKey;

            if (entries) {
                if (entries instanceof CaseMappedMap && entries.mapKey === mapKey) {
                    this.map = new Map(entries.map);
                } else {
                    for (const [key, value] of entries) {
                        this.set(key, value);
                    }
                }
            }
        }
    }

    has(key: string) {
        return this.map.has(this.mapKey(key));
    }

    get(key: string) {
        return this.map.get(this.mapKey(key))?.value;
    }

    set(key: string, value: V) {
        this.map.set(this.mapKey(key), { key, value });
        return this;
    }

    delete(key: string) {
        return this.map.delete(this.mapKey(key));
    }

    clear() {
        this.map.clear();
    }

    get size() {
        return this.map.size;
    }

    entries() {
        return mapIterableIterator(this.map.values(), ({ key, value }): [string, V] => [key, value]);
    }

    keys() {
        return mapIterableIterator(this.map.values(), ({ key }) => key);
    }

    values() {
        return mapIterableIterator(this.map.values(), ({ value }) => value);
    }

    forEach(fn: (value: V, key: string, map: this) => void) {
        return this.map.forEach(({ key, value }) => fn(value, key, this));
    }

    [Symbol.iterator]() {
        return this.entries();
    }

    get [Symbol.toStringTag]() {
        return "CaseMappedMap";
    }
}
