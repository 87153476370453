import { Component, createEffect, createSignal, JSX, Match, Show, Switch } from "solid-js";

import { Button } from "@/iro/objects/Button";
import { LightboxItem } from "@/iro/objects/Lightbox";
import { callEventHandler } from "@/lib/solid/eventHandler";

import { Icon } from "../objects/Icon";

export interface Attachment extends LightboxItem {
    isSensitive?: boolean;
}

export const Attachment: Component<{
    attachment: Attachment;
    onClick?: JSX.EventHandlerUnion<HTMLElement, MouseEvent>;
}> = (props) => {
    const [hidden, setHidden] = createSignal(false);

    const onClick = (e: MouseEvent) => callEventHandler(props.onClick, e);

    createEffect(() => setHidden(!!props.attachment.isSensitive));

    return (
        <div class="o-attachment">
            <Show when={props.attachment.isSensitive}>
                <Button
                    round
                    class="o-attachment__hide-btn t-dark"
                    onClick={[setHidden, (v: boolean) => !v]}
                    title={hidden() ? "Show attachment" : "Hide attachment"}
                >
                    <Icon id={hidden() ? "eye" : "eye-slash"} />
                </Button>

                <Show when={hidden()}>
                    <button class="o-attachment__notice t-dark" onClick={[setHidden, false]}>
                        <strong>
                            <Icon id="alert-triangle" /> NSFW
                        </strong>
                        <div>Click to show</div>
                    </button>
                </Show>
            </Show>

            <Switch>
                <Match when={props.attachment.type?.startsWith("image/")}>
                    <a class="o-attachment__object o-attachment--image" href={props.attachment.src} onClick={onClick}>
                        <img class="o-attachment__img" src={props.attachment.thumbnail ?? props.attachment.src} />
                    </a>
                </Match>

                <Match when={props.attachment.type?.startsWith("video/")}>
                    <video controls class="o-attachment__object o-attachment--video">
                        <source src={props.attachment.src} type={props.attachment.type} />
                    </video>
                </Match>

                <Match when={props.attachment.type?.startsWith("audio/")}>
                    <audio controls class="o-attachment__object o-attachment--audio">
                        <source src={props.attachment.src} type={props.attachment.type} />
                    </audio>
                </Match>
            </Switch>
        </div>
    );
};
