import { IntlProvider, useIntl } from "@cookbook/solid-intl";
import { hashIntegration, Navigate, pathIntegration, Route, Router, Routes } from "@solidjs/router";
import ky from "ky";
import {
    Component,
    createEffect,
    createMemo,
    createResource,
    ErrorBoundary,
    Match,
    on,
    Switch,
    useContext,
} from "solid-js";

import { OnDemandProvider } from "@/iro/objects/OnDemand";
import { useStore } from "@/lib/exome/solid";
import * as routes from "@/routes";
import { StaticConfig } from "@/staticConfig";
import { ROOT_BUFFER } from "@/store/buffer";
import { ROOT_NETWORK } from "@/store/network";
import { networkList as networkListStore } from "@/store/networkList";
import { UserConfig } from "@/userConfig";

import { ChatView } from "./Chat";
import { ErrorView } from "./Message";
import { SetupView } from "./Setup";

export const RootView: Component = () => {
    const staticConfig = useContext(StaticConfig);
    const [userConfig] = useContext(UserConfig);

    const networkList = useStore(() => networkListStore);
    const rootNetwork = () => networkList().networks.get(ROOT_NETWORK);

    const locale = createMemo(() => userConfig.locale ?? navigator.language ?? "en-US");
    const [messages] = createResource(locale, (locale) =>
        ky.get(`./lang/${locale.split("-")[0]}.json`).json<Record<string, string>>(),
    );

    createEffect(
        on(locale, (locale, prevLocale) => {
            if (prevLocale) {
                window.location.reload();
            }
        }),
    );

    return (
        <IntlProvider
            locale={locale()}
            messages={messages() ?? {}}
            defaultRichTextElements={{ b: (c) => <strong>{c}</strong> }}
            onError={() => void 0}
        >
            <Router source={staticConfig.hashRouting ? hashIntegration() : pathIntegration()}>
                <ErrorBoundary
                    fallback={(err) => {
                        const intl = useIntl();

                        console.error(err);

                        return (
                            <ErrorView>
                                <strong>
                                    {intl.formatMessage({
                                        id: "msg.unexpected_error",
                                        defaultMessage: "An unexpected error occurred",
                                    })}
                                </strong>
                                {err}
                            </ErrorView>
                        );
                    }}
                >
                    <OnDemandProvider>
                        <Switch>
                            <Match when={!rootNetwork()}>
                                <Routes>
                                    <Route path={routes.setup()} component={SetupView} />
                                    <Route path="/*" element={<Navigate href={routes.setup()} />} />
                                </Routes>
                            </Match>
                            <Match when={rootNetwork()}>
                                {(rootNetwork) => (
                                    <Routes>
                                        <Route
                                            path={routes.setup()}
                                            element={<Navigate href={routes.chat(rootNetwork().id, ROOT_BUFFER)} />}
                                        />
                                        <Route path={routes.chat()} element={<ChatView />} />
                                        <Route
                                            path="/*"
                                            element={<Navigate href={routes.chat(rootNetwork().id, ROOT_BUFFER)} />}
                                        />
                                    </Routes>
                                )}
                            </Match>
                        </Switch>
                    </OnDemandProvider>
                </ErrorBoundary>
            </Router>
        </IntlProvider>
    );
};
