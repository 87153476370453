import { Component, Show, useContext } from "solid-js";

import { useStore } from "@/lib/exome/solid";
import { Buffer as BufferStore } from "@/store/buffer";
import { TypingIndicatorMode, UserConfig } from "@/userConfig";

import { MessageList } from "../objects/MessageList";
import { BufferHeader } from "./Buffer/Header";
import { BufferSidebar } from "./Buffer/Sidebar";
import { BufferTyping } from "./Buffer/Typing";
import { Compose } from "./Compose";

export const Buffer: Component<{ buffer: BufferStore }> = (props) => {
    const [userConfig] = useContext(UserConfig);

    const buffer = useStore(() => props.buffer);

    return (
        <>
            <BufferSidebar buffer={props.buffer} />

            <div class="l-main l-overflow">
                <BufferHeader buffer={props.buffer} />

                <div class="l-main__content l-main__content--narrow">
                    <MessageList messageList={buffer().messages} />

                    <Show when={userConfig.typingIndicator !== TypingIndicatorMode.Disabled}>
                        <BufferTyping buffer={props.buffer} />
                    </Show>
                </div>

                <Compose buffer={props.buffer} />
            </div>
        </>
    );
};
