import { useIntl } from "@cookbook/solid-intl";
import { Component, createMemo, Index, JSX, Match, mergeProps, splitProps, Switch } from "solid-js";
import { match } from "ts-pattern";

import { CHANNEL_MODE_MAP, ModeSpec, USER_MODE_MAP } from "@/data/mode";
import { Badge } from "@/iro/objects/Badge";
import { Switch as FormSwitch } from "@/iro/objects/Switch";
import { Table, TableBody, TableCell, TableRow } from "@/iro/objects/Table";
import { TextField } from "@/iro/objects/TextField";
import { ModeTypes } from "@/lib/irc/modes";
import { callEventHandler } from "@/lib/solid/eventHandler";
import { isMatchingN } from "@/lib/ts-pattern/util";
import { Narrow } from "@/lib/unionTypes";

const AlwaysArgRow: Component<{
    scope: "user" | "channel";
    value: Narrow<ModeSpec, "AlwaysArg">["value"];
    onInput?: JSX.EventHandlerUnion<HTMLInputElement, CustomEvent<{ active: boolean; param: string }>>;
}> = (props) => {
    const intl = useIntl();

    const description = () => {
        const modeMap = props.scope === "user" ? USER_MODE_MAP : CHANNEL_MODE_MAP;
        return props.value.mode in modeMap
            ? intl.formatMessage(modeMap[props.value.mode as keyof typeof modeMap])
            : undefined;
    };

    const checkInputHandler: JSX.EventHandler<HTMLInputElement, Event> = (e) => {
        callEventHandler(
            props.onInput,
            new CustomEvent("input", { detail: { active: e.currentTarget.checked, param: props.value.param } }),
        );
    };

    const textInputHandler: JSX.EventHandler<HTMLInputElement, Event> = (e) => {
        callEventHandler(
            props.onInput,
            new CustomEvent("input", { detail: { active: props.value.active, param: e.currentTarget.value } }),
        );
    };

    return (
        <TableRow>
            <TableCell class="u-w-1px">
                <FormSwitch checked={props.value.active} onInput={checkInputHandler} />
            </TableCell>

            <TableCell>
                <Badge fixed="100" class="u-mr-50">
                    {props.value.mode}
                </Badge>{" "}
                {description()}
            </TableCell>

            <TableCell>
                <TextField disabled={!props.value.active} value={props.value.param} onInput={textInputHandler} />
            </TableCell>
        </TableRow>
    );
};

const SetArgRow: Component<{
    scope: "user" | "channel";
    value: Narrow<ModeSpec, "SetArg">["value"];
    onInput?: JSX.EventHandlerUnion<HTMLInputElement, CustomEvent<{ active: boolean; param: string }>>;
}> = (props) => {
    const intl = useIntl();

    const description = () => {
        const modeMap = props.scope === "user" ? USER_MODE_MAP : CHANNEL_MODE_MAP;
        return props.value.mode in modeMap
            ? intl.formatMessage(modeMap[props.value.mode as keyof typeof modeMap])
            : undefined;
    };

    const checkInputHandler: JSX.EventHandler<HTMLInputElement, Event> = (e) => {
        callEventHandler(
            props.onInput,
            new CustomEvent("input", { detail: { active: e.currentTarget.checked, param: props.value.param } }),
        );
    };

    const textInputHandler: JSX.EventHandler<HTMLInputElement, Event> = (e) => {
        callEventHandler(
            props.onInput,
            new CustomEvent("input", { detail: { active: props.value.active, param: e.currentTarget.value } }),
        );
    };

    return (
        <TableRow>
            <TableCell class="u-w-1px">
                <FormSwitch checked={props.value.active} onInput={checkInputHandler} />
            </TableCell>

            <TableCell>
                <Badge fixed="100" class="u-mr-50">
                    {props.value.mode}
                </Badge>{" "}
                {description()}
            </TableCell>

            <TableCell>
                <TextField disabled={!props.value.active} value={props.value.param} onInput={textInputHandler} />
            </TableCell>
        </TableRow>
    );
};

const NeverArgRow: Component<{
    scope: "user" | "channel";
    value: Narrow<ModeSpec, "NeverArg">["value"];
    onInput?: JSX.EventHandlerUnion<HTMLInputElement, CustomEvent<{ active: boolean; param: string }>>;
}> = (props) => {
    const intl = useIntl();

    const description = () => {
        const modeMap = props.scope === "user" ? USER_MODE_MAP : CHANNEL_MODE_MAP;
        return props.value.mode in modeMap
            ? intl.formatMessage(modeMap[props.value.mode as keyof typeof modeMap])
            : undefined;
    };

    const inputHandler: JSX.EventHandler<HTMLInputElement, Event> = (e) => {
        const e_ = new CustomEvent("input", { detail: { active: e.currentTarget.checked, param: "" } });
        callEventHandler(props.onInput, e_);
    };

    return (
        <TableRow>
            <TableCell class="u-w-1px">
                <FormSwitch checked={props.value.active} onInput={inputHandler} />
            </TableCell>

            <TableCell>
                <Badge fixed="100" class="u-mr-50">
                    {props.value.mode}
                </Badge>{" "}
                {description()}
            </TableCell>

            <TableCell colSpan="2">
                <TextField disabled style={{ opacity: 0 }} />
            </TableCell>
        </TableRow>
    );
};

export const ModeEditor: Component<
    {
        scope: "user" | "channel";
        modes: ModeTypes;
        value: Map<string, string | undefined>;
        onInput?: JSX.EventHandlerUnion<never, CustomEvent<Map<string, string | undefined>>>;
    } & Omit<JSX.HTMLAttributes<HTMLTableElement>, "onInput">
> = (_props) => {
    const intl = useIntl();

    const propsWithDefaults = mergeProps({ class: "" }, _props);
    const [props, propsRest] = splitProps(propsWithDefaults, ["scope", "modes", "value", "class", "onInput"]);

    const modes = createMemo(() => {
        const modes: ModeSpec[] = [];

        const allModes = props.modes;
        const activeModes = props.value;

        for (const mode of allModes.alwaysArg) {
            const param = activeModes.get(mode);
            modes.push(ModeSpec.AlwaysArg({ mode, active: param !== undefined, param: param ?? "" }));
        }

        for (const mode of allModes.setArg) {
            const param = activeModes.get(mode);
            modes.push(ModeSpec.SetArg({ mode, active: param !== undefined, param: param ?? "" }));
        }

        for (const mode of allModes.neverArg) {
            modes.push(ModeSpec.NeverArg({ mode, active: activeModes.has(mode) }));
        }

        modes.sort((a, b) => a.value.mode.localeCompare(b.value.mode, intl.locale));

        return modes;
    });

    const updateHandler = (mode: ModeSpec, e: CustomEvent<{ active: boolean; param: string }>) => {
        const newValue = new Map(props.value);

        if (!e.detail.active) {
            newValue.delete(mode.value.mode);
        } else {
            const param = match(mode)
                .with(ModeSpec.NeverArg.select(), () => undefined)
                .with(ModeSpec.SetArg.select(), () => e.detail.param)
                .with(ModeSpec.AlwaysArg.select(), () => e.detail.param)
                .run();

            newValue.set(mode.value.mode, param);
        }

        callEventHandler(props.onInput, new CustomEvent("input", { detail: newValue }));
    };

    return (
        <Table flush size="50" class={`u-w-100 ${props.class}`} {...propsRest}>
            <TableBody>
                <Index each={modes()}>
                    {(mode) => (
                        <Switch>
                            <Match when={isMatchingN(ModeSpec.AlwaysArg.select(), mode())}>
                                {(mode) => (
                                    <AlwaysArgRow
                                        scope={props.scope}
                                        value={mode().value}
                                        onInput={[updateHandler, mode()]}
                                    />
                                )}
                            </Match>
                            <Match when={isMatchingN(ModeSpec.SetArg.select(), mode())}>
                                {(mode) => (
                                    <SetArgRow
                                        scope={props.scope}
                                        value={mode().value}
                                        onInput={[updateHandler, mode()]}
                                    />
                                )}
                            </Match>
                            <Match when={isMatchingN(ModeSpec.NeverArg.select(), mode())}>
                                {(mode) => (
                                    <NeverArgRow
                                        scope={props.scope}
                                        value={mode().value}
                                        onInput={[updateHandler, mode()]}
                                    />
                                )}
                            </Match>
                        </Switch>
                    )}
                </Index>
            </TableBody>
        </Table>
    );
};
