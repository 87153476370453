import { useIntl } from "@cookbook/solid-intl";
import { FormatDateOptions } from "@formatjs/intl";
import { Component } from "solid-js";

export const Time: Component<{
    date: Date;
    format?: FormatDateOptions | ((date: Date) => FormatDateOptions | undefined);
    class?: string;
}> = (props) => {
    const intl = useIntl();

    const date = () => {
        const format = typeof props.format === "function" ? props.format(props.date) : props.format;

        return intl.formatDate(
            props.date,
            format ?? {
                minute: "2-digit",
                hour: "2-digit",
                month: "long",
                day: "numeric",
                year: "numeric",
            },
        );
    };

    return (
        <time class={props.class} dateTime={props.date.toISOString()}>
            {date()}
        </time>
    );
};
