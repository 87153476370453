import { addMinutes, format, parseISO } from "date-fns";

function toUTC(date: Date) {
    return addMinutes(date, date.getTimezoneOffset());
}

export function formatIrcDate(date: Date) {
    return format(toUTC(date), "yyyy-MM-dd'T'HH:mm:ss.SSS'Z'");
}

export function parseIrcDate(date: string) {
    return parseISO(date);
}

export function parseIrcTimestamp(date: string | number) {
    return new Date(+date * 1000);
}
