import { Component, JSX, mergeProps, splitProps } from "solid-js";

export const Footer: Component<{ side?: boolean; theme?: string } & JSX.HTMLAttributes<HTMLElement>> = (_props) => {
    const propsWithDefaults = mergeProps({ class: "" }, _props);
    const [props, propsRest] = splitProps(propsWithDefaults, ["side", "theme", "class"]);

    return (
        <footer
            class={`l-footer ${props.side ? "l-footer--side" : ""} ${props.theme ? `u-themed t-${props.theme}` : ""} ${
                props.class
            }`}
            {...propsRest}
        />
    );
};
