import { Exome } from "exome";

import { Typing } from "@/lib/irc/typing";

import { Buffer } from "../buffer";
import { Draft } from "../draft";
import { MessageList } from "../messageList";
import { Network } from "../network";
import { User } from "../user";

export class UserBuffer extends Exome implements Buffer {
    messages: MessageList = new MessageList(this);

    draft = new Draft();

    typing = Typing.Done;

    constructor(
        public id: string,
        readonly network: Network,
        readonly user: User,
    ) {
        super();

        network.who(id);
    }

    setId(id: string) {
        this.id = id;
    }

    setTyping(typing: Typing) {
        this.typing = typing;
    }

    send = () => {
        if (this.draft.current !== "") {
            this.network.send(this.id, this.draft.current, this.draft.replyTo);
            this.draft.submit();
        }
    };

    close = () => this.network.buffers.delete(this.id);
}
