/* eslint-disable @typescript-eslint/no-non-null-assertion */

export const messagePattern = /^(?:@([^ ]*) +)?(?::([^ ]*) +)?([^ ]*)(?: +(.*))?(?:\r\n)?$/;

export const sourcePattern = /^([^!@]*)(?:!([^@]*))?(?:@(.*))?$/;

export const tagValueEscapeMap = {
    ";": "\\:",
    " ": "\\s",
    "\\": "\\\\",
    "\r": "\\r",
    "\n": "\\n",
};

export const tagValueUnescapeMap = Object.fromEntries(
    Object.entries(tagValueEscapeMap).map(([from, to]) => [to, from]),
);

export function escapeTagValue(s: string) {
    return s.replace(/[; \\\r\n]/g, (c) => tagValueEscapeMap[c as keyof typeof tagValueEscapeMap]);
}

export function unescapeTagValue(s: string) {
    let val = s.replace(/\\[:s\\rn]/g, (seq) => tagValueUnescapeMap[seq]!);
    if (val.endsWith("\\")) {
        val = val.slice(0, val.length - 1);
    }
    return val;
}

export type Tags = Record<string, string>;

export interface RawSource {
    nickname: string;
    username: string;
    hostname: string;
}

export interface RawMessage {
    tags: Tags;
    src?: RawSource;
    cmd: string | number;
    params: string[];
}

export function rawMessage(cmd: string | number, params: string[] = [], tags: Tags = {}): RawMessage {
    return { cmd, params, tags };
}

export function parseTags(tags: string) {
    return tags.split(";").reduce((r: Tags, tag) => {
        const i = tag.indexOf("=");
        if (i === -1) {
            return { ...r, [tag]: "" };
        }
        const key = tag.slice(0, i);
        const value = unescapeTagValue(tag.slice(i + 1));
        return { ...r, [key]: value };
    }, {});
}

export function parseSource(src: string): RawSource | undefined {
    const match = src.match(sourcePattern);
    if (!match) {
        return;
    }
    const [, nickname, username = "", hostname = ""] = match;
    return { nickname: nickname!, username, hostname };
}

export function parseParams(params: string): string[] {
    const out: string[] = [];

    for (;;) {
        if (params.startsWith(":")) {
            out.push(params.slice(1));
            break;
        }

        const i = params.indexOf(" ");

        if (i < 0) {
            if (params) {
                out.push(params);
            }
            break;
        } else if (i === 0) {
            params = params.slice(1);
        } else {
            out.push(params.slice(0, i));
            params = params.slice(i);
        }
    }

    return out;
}

export function parseRawMessage(msg: string): RawMessage | undefined {
    const match = msg.match(messagePattern);
    if (!match) {
        return;
    }
    const [, tags, src, cmd, params] = match;
    const cmdNum = +cmd!;
    return {
        tags: tags ? parseTags(tags) : {},
        src: src ? parseSource(src) : undefined,
        cmd: cmd && !isNaN(cmdNum) ? cmdNum : cmd!,
        params: params ? parseParams(params) : [],
    };
}

export function renderTags(tags: Tags) {
    return Object.entries(tags)
        .map(([key, value]) => (value ? `${key}=${escapeTagValue(value)}` : key))
        .join(";");
}

export function renderSource(source: RawSource) {
    return `${source.nickname}${source.username ? `!${source.username}` : ""}${
        source.hostname ? `@${source.hostname}` : ""
    }`;
}

export function renderParams(params: string[], out = ""): string {
    if (!params.length) {
        return out;
    }

    const [p1, ...ps] = params;

    if (!ps.length) {
        return `${out} ${!p1 || p1.startsWith(":") || p1.includes(" ") ? ":" : ""}${p1}`;
    }

    return renderParams(ps, `${out} ${p1}`);
}

export function renderRawMessage(msg: RawMessage) {
    let out = "";

    if (Object.keys(msg.tags).length) {
        out += `@${renderTags(msg.tags)} `;
    }

    if (msg.src) {
        out += `:${renderSource(msg.src)} `;
    }

    out += msg.cmd;

    if (msg.params.length) {
        out += renderParams(msg.params);
    }

    return out;
}

export function computeMaxMessageLength(msg: RawMessage) {
    // 512 seems to be too high for soju
    return 512 - new TextEncoder().encode(renderRawMessage(msg)).length - 1 - 2;
}
