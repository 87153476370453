export interface ModeTypes {
    lists: string;
    alwaysArg: string;
    setArg: string;
    neverArg: string;
    prefixModes: Array<[string, string]>;
}

export interface ModeChange {
    add: boolean;
    mode: string;
    param?: string;
}

export interface ModeListItem {
    mask: string;
    setter?: string;
    setAt?: Date;
}

export const defaultModeTypes: ModeTypes = {
    lists: "eIbq",
    alwaysArg: "k",
    setArg: "flj",
    neverArg: "CFLMPQScgimnprstz",
    prefixModes: [
        ["o", "@"],
        ["v", "+"],
    ],
};

export function parseModes(modes: string, _args: string[], types?: ModeTypes) {
    const args = [..._args];
    const res: ModeChange[] = [];
    const prefixModesOnly = types ? types.prefixModes.map(([m]) => m).join("") : "";
    let add = true;

    for (const mode of modes) {
        if (mode === "+") {
            add = true;
        } else if (mode === "-") {
            add = false;
        } else if (!types) {
            res.push({ add, mode });
        } else if (
            types.alwaysArg.includes(mode) ||
            (add && types.setArg.includes(mode)) ||
            prefixModesOnly.includes(mode) ||
            types.lists.includes(mode)
        ) {
            res.push({ add, mode, param: args.shift() ?? "" });
        } else if ((!add && types.setArg.includes(mode)) || types.neverArg.includes(mode)) {
            res.push({ add, mode });
        } else {
            return;
        }
    }

    if (!args.length) {
        return res;
    }
}

export function renderModes(modes: ModeChange[]): [string, string[]] {
    let modesStr = "";
    const args: string[] = [];
    let curAdd: boolean | undefined;

    for (const { add, mode, param } of modes) {
        if (param !== undefined) {
            args.push(param);
        }
        if (add !== curAdd) {
            curAdd = add;
            modesStr += add ? "+" : "-";
        }
        modesStr += mode;
    }

    return [modesStr, args];
}
