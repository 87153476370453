import { Exome } from "exome";
import { either as E, function as F, json as J, map as M, ord as Or, string as S } from "fp-ts";
import * as io from "io-ts";

import { ClientAuthentication } from "@/lib/client/client";
import { NetworkAttributes, newNetworkAttributes } from "@/lib/irc/bouncer";
import { staticConfig } from "@/staticConfig";

import { Network, NetworkConnection, ROOT_NETWORK } from "./network";

export const NetworkIdOrd: Or.Ord<string> = {
    ...S.Ord,
    compare: (a, b) => (a === ROOT_NETWORK ? -1 : b === ROOT_NETWORK ? 1 : S.Ord.compare(a, b)),
};

export const NetworkOrd = Or.contramap((network: Network) => network.id.toLowerCase())(NetworkIdOrd);

export const NetworkConfigC = io.intersection([
    io.type({
        socket: io.string,
        nickname: io.string,
    }),
    io.partial({
        autoConnect: io.boolean,
        password: io.string,
        username: io.string,
        realname: io.string,
        serverPassword: io.string,
    }),
]);

export type NetworkConfig = io.TypeOf<typeof NetworkConfigC>;

export interface NetworkListItem {
    network: NetworkConnection;
    attrs: NetworkAttributes;
}

export class NetworkList extends Exome {
    networks = new Map<string, Network>();

    get sorted() {
        return F.pipe(this.networks, M.values(NetworkOrd));
    }

    execDelete(id: string) {
        const network = this.networks.get(id);
        network?.disconnect();
        this.networks.delete(id);
        return network;
    }

    get = (id: string) => this.networks.get(id);

    add(id: string, attrs?: Partial<NetworkAttributes>) {
        const network = new Network(id, { ...newNetworkAttributes(), ...attrs });
        this.networks.set(id, network);
        return network;
    }

    upsert = (id: string, attrs?: Partial<NetworkAttributes>) => {
        let network = this.networks.get(id);
        if (network) {
            if (attrs) {
                network.updateAttrs(attrs);
            }
        } else {
            network = this.add(id, attrs);
        }
        return network;
    };

    delete = (id: string) => {
        if (this.networks.has(id)) {
            return this.execDelete(id);
        }
    };

    clear() {
        for (const network of this.networks.values()) {
            network.disconnect();
        }
        this.networks.clear();
    }

    clearConfig() {
        localStorage.removeItem("kitsune-irc:config");
        this.clear();
    }

    async loadConfig() {
        const config = await staticConfig;

        this.clear();

        const userConfig = F.pipe(
            localStorage.getItem("kitsune-irc:config") ?? "",
            J.parse,
            E.chainW(NetworkConfigC.decode),
        );

        if (E.isLeft(userConfig)) {
            return;
        }

        const network = this.upsert(ROOT_NETWORK);

        network.connect({
            socket: (config.lockedSocket && config.socket) || userConfig.right.socket,
            nickname: userConfig.right.nickname,
            timeout: config.timeout,
            username: userConfig.right.username,
            realname: userConfig.right.realname,
            pass: userConfig.right.serverPassword,
            sasl: userConfig.right.password
                ? ClientAuthentication.PLAIN({
                      username: userConfig.right.nickname,
                      password: userConfig.right.password,
                  })
                : undefined,
        });
    }
}

export const networkList = new NetworkList();
