import * as lfy from "linkifyjs";

import { impl, Variant } from "@/lib/unionTypes";

lfy.registerCustomProtocol("gopher");
lfy.registerCustomProtocol("gemini");

export type LinkData = ReturnType<(typeof lfy)["find"]>[number];

export type Linkified = Variant<"Link", LinkData> | Variant<"Text", string>;

export const Linkified = impl<Linkified>();

export function linkify(text: string): { text: Linkified[]; links: LinkData[] } {
    const links = lfy.find(text);

    const items: Linkified[] = [];
    let last = 0;

    for (const match of links) {
        items.push(Linkified.Text(text.slice(last, match.start)));
        last = match.end;

        if (match.type === "url" && !/^(https?|gopher|gemini):\/\//.test(match.value)) {
            items.push(Linkified.Text(match.value));
        } else {
            items.push(Linkified.Link(match));
        }
    }

    items.push(Linkified.Text(text.slice(last)));

    return { text: items, links };
}
