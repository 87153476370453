/* eslint-disable @typescript-eslint/no-non-null-assertion */

import { CaseMapping, getCaseMapping, rfc1459 } from "@/lib/irc/caseMapping";
import { defaultModeTypes, ModeTypes } from "@/lib/irc/modes";
import { isEnum } from "@/lib/types";

export enum ISupportToken {
    AwayLen = "AWAYLEN",
    Bot = "BOT",
    BouncerNetId = "BOUNCER_NETID",
    CaseMapping = "CASEMAPPING",
    ChanLimit = "CHANLIMIT",
    ChanModes = "CHANMODES",
    ChannelLen = "CHANNELLEN",
    ChanTypes = "CHANTYPES",
    ChatHistory = "CHATHISTORY",
    EList = "ELIST",
    Excepts = "EXCEPTS",
    HostLen = "HOSTLEN",
    Invex = "INVEX",
    KickLen = "KICKLEN",
    MaxList = "MAXLIST",
    MaxTargets = "MAXTARGETS",
    Modes = "MODES",
    Monitor = "MONITOR",
    Network = "NETWORK",
    NickLen = "NICKLEN",
    Prefix = "PREFIX",
    SafeList = "SAFELIST",
    Silence = "SILENCE",
    StatusMsg = "STATUSMSG",
    TargMax = "TARGMAX",
    TopicLen = "TOPICLEN",
    UserLen = "USERLEN",
    VAPID = "VAPID",
    Whox = "WHOX",
}

export const isISupportToken = isEnum(ISupportToken);

export const ISUPPORT_DEFAULTS: Pick<ISupportManager, ISupportToken> = {
    [ISupportToken.AwayLen]: Infinity,
    [ISupportToken.Bot]: "",
    [ISupportToken.BouncerNetId]: "",
    [ISupportToken.CaseMapping]: rfc1459,
    [ISupportToken.ChanLimit]: {},
    [ISupportToken.ChanModes]: defaultModeTypes,
    [ISupportToken.ChannelLen]: Infinity,
    [ISupportToken.ChanTypes]: "#&",
    [ISupportToken.ChatHistory]: 0,
    [ISupportToken.EList]: "",
    [ISupportToken.Excepts]: "e",
    [ISupportToken.HostLen]: Infinity,
    [ISupportToken.Invex]: "I",
    [ISupportToken.KickLen]: Infinity,
    [ISupportToken.MaxList]: {},
    [ISupportToken.MaxTargets]: Infinity,
    [ISupportToken.Modes]: Infinity,
    [ISupportToken.Monitor]: 0,
    [ISupportToken.Network]: "",
    [ISupportToken.NickLen]: Infinity,
    [ISupportToken.Prefix]: defaultModeTypes,
    [ISupportToken.SafeList]: false,
    [ISupportToken.Silence]: 0,
    [ISupportToken.StatusMsg]: undefined,
    [ISupportToken.TargMax]: {},
    [ISupportToken.TopicLen]: Infinity,
    [ISupportToken.UserLen]: Infinity,
    [ISupportToken.VAPID]: "",
    [ISupportToken.Whox]: false,
};

export const tokenPattern = /^(-)?([^=]+)(?:=(.*))?$/;

export const limitPattern = /^([^:]+):([0-9]*)$/;

export const prefixPattern = /^\(([^)]+)\)(.*)$/;

export type Limits = Record<string, number>;

export class ISupportManager {
    [ISupportToken.AwayLen]: number = ISUPPORT_DEFAULTS[ISupportToken.AwayLen];
    [ISupportToken.Bot]: string = ISUPPORT_DEFAULTS[ISupportToken.Bot];
    [ISupportToken.BouncerNetId]: string = ISUPPORT_DEFAULTS[ISupportToken.BouncerNetId];
    [ISupportToken.CaseMapping]: CaseMapping = ISUPPORT_DEFAULTS[ISupportToken.CaseMapping];
    [ISupportToken.ChanLimit]: Limits = ISUPPORT_DEFAULTS[ISupportToken.ChanLimit];
    [ISupportToken.ChanModes]: Omit<ModeTypes, "prefixModes"> = ISUPPORT_DEFAULTS[ISupportToken.ChanModes];
    [ISupportToken.ChannelLen]: number = ISUPPORT_DEFAULTS[ISupportToken.ChannelLen];
    [ISupportToken.ChanTypes]: string = ISUPPORT_DEFAULTS[ISupportToken.ChanTypes];
    [ISupportToken.ChatHistory]: number = ISUPPORT_DEFAULTS[ISupportToken.ChatHistory];
    [ISupportToken.EList]: string = ISUPPORT_DEFAULTS[ISupportToken.EList];
    [ISupportToken.Excepts]: string = ISUPPORT_DEFAULTS[ISupportToken.Excepts];
    [ISupportToken.HostLen]: number = ISUPPORT_DEFAULTS[ISupportToken.HostLen];
    [ISupportToken.Invex]: string = ISUPPORT_DEFAULTS[ISupportToken.Invex];
    [ISupportToken.KickLen]: number = ISUPPORT_DEFAULTS[ISupportToken.KickLen];
    [ISupportToken.MaxList]: Limits = ISUPPORT_DEFAULTS[ISupportToken.MaxList];
    [ISupportToken.MaxTargets]: number = ISUPPORT_DEFAULTS[ISupportToken.MaxTargets];
    [ISupportToken.Monitor]: number = ISUPPORT_DEFAULTS[ISupportToken.Monitor];
    [ISupportToken.Modes]: number = ISUPPORT_DEFAULTS[ISupportToken.Modes];
    [ISupportToken.Network]: string = ISUPPORT_DEFAULTS[ISupportToken.Network];
    [ISupportToken.NickLen]: number = ISUPPORT_DEFAULTS[ISupportToken.NickLen];
    [ISupportToken.Prefix]: Pick<ModeTypes, "prefixModes"> = ISUPPORT_DEFAULTS[ISupportToken.Prefix];
    [ISupportToken.SafeList]: boolean = ISUPPORT_DEFAULTS[ISupportToken.SafeList];
    [ISupportToken.Silence]: number = ISUPPORT_DEFAULTS[ISupportToken.Silence];
    [ISupportToken.StatusMsg]: string | undefined = ISUPPORT_DEFAULTS[ISupportToken.StatusMsg];
    [ISupportToken.TargMax]: Limits = ISUPPORT_DEFAULTS[ISupportToken.TargMax];
    [ISupportToken.TopicLen]: number = ISUPPORT_DEFAULTS[ISupportToken.TopicLen];
    [ISupportToken.UserLen]: number = ISUPPORT_DEFAULTS[ISupportToken.UserLen];
    [ISupportToken.VAPID]: string = ISUPPORT_DEFAULTS[ISupportToken.VAPID];
    [ISupportToken.Whox]: boolean = ISUPPORT_DEFAULTS[ISupportToken.Whox];

    constructor() {
        this.clear();
    }

    apply(tokens: string[]) {
        for (const token of tokens) {
            const [, del, name, value = ""] = token.match(tokenPattern) ?? ([, , ""] as const);
            const key = name.toUpperCase();

            if (!isISupportToken(key)) {
                continue;
            }

            if (del) {
                this.reset(key);
                continue;
            }

            switch (key) {
                case ISupportToken.AwayLen:
                    this[key] = Math.max(0, +value);
                    break;

                case ISupportToken.Bot:
                    this[key] = value;
                    break;

                case ISupportToken.BouncerNetId:
                    this[key] = value;
                    break;

                case ISupportToken.CaseMapping:
                    this[key] = getCaseMapping(value) ?? ISUPPORT_DEFAULTS[key];
                    break;

                case ISupportToken.ChanLimit:
                    this[key] = value.split(",").reduce((r: Limits, chan) => {
                        const [, prefixes, limit] = chan.match(limitPattern) ?? ([, "", ""] as const);
                        return prefixes.split("").reduce((r, prefix) => ({ ...r, [prefix]: +limit }), r);
                    }, {});
                    break;

                case ISupportToken.ChanModes: {
                    const [lists = "", alwaysArg = "", setArg = "", neverArg = ""] = value.split(",");
                    this[key] = { lists, alwaysArg, setArg, neverArg };
                    break;
                }

                case ISupportToken.ChannelLen:
                    this[key] = Math.max(0, +value);
                    break;

                case ISupportToken.ChanTypes:
                    this[key] = value;
                    break;

                case ISupportToken.ChatHistory: {
                    const n = Math.max(0, +value);
                    this[key] = n === 0 ? Infinity : n;
                    break;
                }

                case ISupportToken.EList:
                    this[key] = value;
                    break;

                case ISupportToken.Excepts:
                    this[key] = value;
                    break;

                case ISupportToken.HostLen:
                    this[key] = Math.max(0, +value);
                    break;

                case ISupportToken.Invex:
                    this[key] = value;
                    break;

                case ISupportToken.KickLen:
                    this[key] = Math.max(0, +value);
                    break;

                case ISupportToken.MaxList:
                    this[key] = value.split(",").reduce((r: Limits, chan) => {
                        const [, modes, limit] = chan.match(limitPattern) ?? ([, "", ""] as const);
                        return modes.split("").reduce((r, mode) => ({ ...r, [mode]: +limit }), r);
                    }, {});
                    break;

                case ISupportToken.MaxTargets:
                    this[key] = !value ? ISUPPORT_DEFAULTS[key] : Math.max(0, +value);
                    break;

                case ISupportToken.Modes:
                    this[key] = !value ? ISUPPORT_DEFAULTS[key] : Math.max(0, +value);
                    break;

                case ISupportToken.Monitor:
                    this[key] = !value ? Math.max(0, +value) : Infinity;
                    break;

                case ISupportToken.Network:
                    this[key] = value;
                    break;

                case ISupportToken.NickLen:
                    this[key] = Math.max(0, +value);
                    break;

                case ISupportToken.Prefix: {
                    const [, modes, prefixes] = value.match(prefixPattern) ?? ([, "", ""] as const);
                    const max = Math.min(modes.length, prefixes.length);
                    const prefixModes: [string, string][] = [];
                    for (let i = 0; i < max; ++i) {
                        prefixModes.push([modes[i]!, prefixes[i]!]);
                    }
                    this[key] = { prefixModes };
                    break;
                }

                case ISupportToken.SafeList:
                    this[key] = true;
                    break;

                case ISupportToken.Silence:
                    this[key] = !value ? ISUPPORT_DEFAULTS[key] : Math.max(0, +value);
                    break;

                case ISupportToken.StatusMsg:
                    this[key] = value;
                    break;

                case ISupportToken.TargMax:
                    this[key] = value.split(",").reduce((r: Limits, chan) => {
                        const match = chan.match(limitPattern);
                        if (!match) {
                            return r;
                        }
                        const [, command, limit] = match;
                        return { ...r, [command!]: +limit! };
                    }, {});
                    break;

                case ISupportToken.TopicLen:
                    this[key] = Math.max(0, +value);
                    break;

                case ISupportToken.UserLen:
                    this[key] = Math.max(0, +value);
                    break;

                case ISupportToken.VAPID:
                    this[key] = value;
                    break;

                case ISupportToken.Whox:
                    this[key] = true;
                    break;
            }
        }
    }

    reset(token: ISupportToken) {
        (this[token] as unknown) = ISUPPORT_DEFAULTS[token];
    }

    clear() {
        for (const token of Object.values(ISupportToken)) {
            this.reset(token);
        }
    }
}
