import { Component, JSX, mergeProps, splitProps } from "solid-js";
import { Dynamic } from "solid-js/web";

export const Badge: Component<
    {
        tag?: string;
        variant?: "primary" | "accent" | "accent-quiet" | "default";
        pad?: "100" | "200";
        fixed?: "100" | "200";
        menu?: boolean;
        size?: "sm" | "xs";
        pill?: boolean;
    } & JSX.HTMLAttributes<HTMLElement>
> = (_props) => {
    const propsWithDefaults = mergeProps({ tag: "span", variant: "default", pad: "100", class: "" }, _props);
    const [props, propsRest] = splitProps(propsWithDefaults, [
        "tag",
        "variant",
        "size",
        "fixed",
        "pad",
        "pill",
        "menu",
        "class",
    ]);

    return (
        <Dynamic
            component={props.tag}
            class={`o-badge o-badge--${props.variant} o-badge--${props.pad} ${
                props.size ? `o-badge--${props.size}` : ""
            } ${props.pill ? "o-badge--pill" : ""} ${props.fixed ? `o-badge--fixed-${props.fixed}` : ""} ${
                props.menu ? "o-menu__badge" : ""
            } ${props.class}`}
            {...propsRest}
        />
    );
};
