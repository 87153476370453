/* eslint-disable @typescript-eslint/no-explicit-any */

import { record as R } from "fp-ts";

const eventDef = Symbol();

export interface TypedEventListener<E> {
    (evt: CustomEvent<E>): void;
}

export interface TypedEventListenerObject<E> {
    handleEvent(object: CustomEvent<E>): void;
}

export type TypedEventListenerOrEventListenerObject<E> = TypedEventListener<E> | TypedEventListenerObject<E>;

export type TypedEventNames<T extends TypedEventTarget> = keyof Required<T[typeof eventDef]>;

export type TypedEventData<T extends TypedEventTarget, K extends keyof Required<T[typeof eventDef]>> = Required<
    T[typeof eventDef]
>[K];

export class TypedEventTarget<EventDef extends Record<string, unknown> = Record<string, unknown>> extends EventTarget {
    [eventDef]?: EventDef;

    public dispatchEvent(e: CustomEvent<EventDef[keyof EventDef]>): boolean {
        return super.dispatchEvent(e);
    }

    public dispatch<T extends Extract<keyof EventDef, string>, E extends EventDef[T]>(
        type: T,
        detail: E,
        init?: CustomEventInit<Record<string, never>>,
    ): boolean {
        return this.dispatchEvent(new CustomEvent(type, { ...init, detail }));
    }

    public addEventListener<T extends Extract<keyof EventDef, string>, E extends EventDef[T]>(
        type: T,
        listener: TypedEventListenerOrEventListenerObject<E> | null,
        options?: boolean | AddEventListenerOptions,
    ) {
        super.addEventListener(type, listener as EventListenerOrEventListenerObject | null, options);
    }

    public removeEventListener<T extends Extract<keyof EventDef, string>, E extends EventDef[T]>(
        type: T,
        listener: TypedEventListenerOrEventListenerObject<E> | null,
        options?: boolean | EventListenerOptions,
    ) {
        super.removeEventListener(type, listener as EventListenerOrEventListenerObject | null, options);
    }
}

export class WaitForTimeoutError extends Error {}

export class WaitForAbortError extends Error {}

export interface WaitForParams {
    timeout?: number;
    abortSignal?: AbortSignal;
}

const waitForResult = Symbol();

export type WaitForResult<T> = false | 0 | void | null | undefined | { [waitForResult]: T };

export type WaitForResolver<T extends TypedEventTarget, E extends TypedEventNames<T>, O> =
    | ((this: T, eventData: TypedEventData<T, E>) => WaitForResult<O>)
    | Array<(this: T, eventData: TypedEventData<T, E>) => WaitForResult<O>>;

export type WaitForResolvers<T extends TypedEventTarget, O> = {
    [E in TypedEventNames<T>]?: WaitForResolver<T, E, O>;
};

export const WaitFor = {
    resolve<T = void>(value?: T): WaitForResult<T> {
        return { [waitForResult]: value as T };
    },
};

export function waitFor<T extends TypedEventTarget<any>, O>(
    emitter: T,
    resolvers: WaitForResolvers<T, O>,
    params?: WaitForParams,
): Promise<O> {
    return new Promise<O>((resolve, reject) => {
        const handlers: [string, (e: CustomEvent) => void][] = [];

        const wrap = (resolver: (eventData: any) => WaitForResult<O>) => (e: CustomEvent) => {
            try {
                const result = resolver(e.detail);

                if (result) {
                    removeEventListeners();
                    resolve(result[waitForResult]);
                }
            } catch (err) {
                removeEventListeners();
                reject(err);
            }
        };

        for (const [event, handler] of R.toArray(resolvers)) {
            if (!handler) {
                continue;
            }
            if (handler instanceof Array) {
                for (const h of handler) {
                    handlers.push([event, wrap(h.bind(emitter))]);
                }
            } else {
                handlers.push([event, wrap(handler.bind(emitter))]);
            }
        }

        const handleTimeout = () => {
            removeEventListeners();
            reject(new WaitForTimeoutError());
        };

        const handleAbort = () => {
            removeEventListeners();
            reject(new WaitForAbortError());
        };

        const removeEventListeners = () => {
            params?.abortSignal?.removeEventListener("abort", handleAbort);

            for (const [event, handler] of handlers) {
                emitter.removeEventListener(event as any, handler);
            }
        };

        params?.abortSignal?.addEventListener("abort", handleAbort);

        for (const [event, handler] of handlers) {
            emitter.addEventListener(event as any, handler);
        }

        if (params?.timeout) {
            setTimeout(handleTimeout, params.timeout);
        }
    });
}
