import { Component, JSX, mergeProps, Show, splitProps } from "solid-js";

import { callEventHandler } from "@/lib/solid/eventHandler";

export const TextField: Component<
    {
        ref?: HTMLInputElement | ((el: HTMLInputElement) => void);
        before?: JSX.Element;
        bgClass?: string;
        invalid?: boolean;
    } & JSX.InputHTMLAttributes<HTMLInputElement>
> = (_props) => {
    const propsWithDefaults = mergeProps({ bgClass: "", class: "" }, _props);
    const [props, propsRest] = splitProps(propsWithDefaults, [
        "before",
        "bgClass",
        "invalid",
        "value",
        "onInput",
        "style",
        "class",
        "style",
        "children",
    ]);
    const onInput: JSX.EventHandler<HTMLInputElement, InputEvent> = (e) => {
        callEventHandler(props.onInput, e);
        if (props.value !== undefined && props.value !== e.currentTarget.value) {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            e.currentTarget.value = props.value as any;
        }
    };

    return (
        <div
            class={`o-text-field l-media l-media--flush l-media--gapless ${propsRest.disabled ? "is-disabled" : ""} ${
                props.invalid ? "is-invalid" : ""
            } ${!props.before && !props.children ? "" : "o-text-field--extended"} ${props.class}`}
            style={props.style}
        >
            <Show when={props.before}>
                <div class="u-d-contents t-hi">{props.before}</div>
            </Show>
            <input
                class="o-text-field__native l-media__block l-media__block--main"
                value={props.value}
                onInput={onInput}
                {...propsRest}
            />
            <Show when={props.children}>
                <div class="u-d-contents t-hi">{props.children}</div>
            </Show>
            <div class={`o-text-field__bg ${props.bgClass}`} />
        </div>
    );
};
