import { Component, ComponentProps, Show, splitProps, useContext } from "solid-js";

import { ActionMenu, ActionMenuItem, ActionMenuSeparator } from "@/iro/objects/ActionMenu";
import { OnDemandContext } from "@/iro/objects/OnDemand";
import { useStore } from "@/lib/exome/solid";
import { ServerBuffer } from "@/store/buffer/serverBuffer";
import { networkList } from "@/store/networkList";

import { CreateNetworkDialog } from "../modals/CreateNetwork";
import { ExploreDialog } from "../modals/Explore";
import { HelpDialog } from "../modals/Help";
import { JoinDialog } from "../modals/Join";
import { Icon } from "../objects/Icon";

export const ServerBufferMenu: Component<ComponentProps<typeof ActionMenu> & { buffer: ServerBuffer }> = (_props) => {
    const [props, propsRest] = splitProps(_props, ["buffer"]);

    const buffer = useStore(() => props.buffer);
    const network = useStore(() => buffer().network);
    const onDemand = useContext(OnDemandContext);

    const close = (fn?: () => void) => {
        fn?.();
        propsRest.onClose?.();
    };

    const viewCreateNetworkModal = () => {
        onDemand((close) => <CreateNetworkDialog onClose={close} />);
    };
    const viewEditNetworkModal = () => {
        onDemand((close) => <CreateNetworkDialog network={buffer().network} onClose={close} />);
    };
    const viewJoinModal = () => {
        onDemand((close) => <JoinDialog network={buffer().network} onClose={close} />);
    };
    const viewExploreModal = () => {
        onDemand((close) => <ExploreDialog network={buffer().network} onClose={close} />);
    };
    const viewHelp = () => {
        onDemand((close) => <HelpDialog network={buffer().network} onClose={close} />);
    };
    const disconnect = () => networkList.clearConfig();

    return (
        <ActionMenu {...propsRest}>
            <Show
                when={network().isRoot}
                fallback={
                    <>
                        <ActionMenuItem pre={<Icon id="message-plus" />} onClick={[close, viewJoinModal]}>
                            New chat
                        </ActionMenuItem>
                        <ActionMenuItem pre={<Icon id="compass" />} onClick={[close, viewExploreModal]}>
                            Explore
                        </ActionMenuItem>
                        <ActionMenuSeparator />
                        <ActionMenuItem pre={<Icon id="cog" />} onClick={[close, viewEditNetworkModal]}>
                            Settings
                        </ActionMenuItem>
                    </>
                }
            >
                <Show when={network().isBouncer}>
                    <ActionMenuItem pre={<Icon id="server-plus" />} onClick={[close, viewCreateNetworkModal]}>
                        Add network
                    </ActionMenuItem>
                    <ActionMenuSeparator />
                </Show>
                <ActionMenuItem pre={<Icon id="logout" />} onClick={[close, disconnect]}>
                    Disconnect
                </ActionMenuItem>
            </Show>
            <ActionMenuSeparator />
            <ActionMenuItem pre={<Icon id="server" />} onClick={[close, viewHelp]}>
                Server help
            </ActionMenuItem>
        </ActionMenu>
    );
};
