import { Exome } from "exome";

export class Draft extends Exome {
    current = "";

    replyTo?: string;

    update(current: string | ((_: string) => string)) {
        this.current = typeof current === "function" ? current(this.current) : current;
    }

    setReplyTo(id?: string) {
        this.replyTo = id;
    }

    submit() {
        this.current = "";
        this.replyTo = undefined;
    }
}
