import { NavLink } from "@solidjs/router";
import { createMemo, JSX, mergeProps, ParentComponent, Show } from "solid-js";
import { Dynamic } from "solid-js/web";

import { cyrb53 } from "@/lib/cyrb53";

import { Status, StatusIndicator } from "./StatusIndicator";

export const Avatar: ParentComponent<{
    href?: string;
    image?: string;
    class?: string;
    style?: JSX.CSSProperties;
    size?: "200" | "150" | "100" | "75" | "50";
    status?: Status;
    hue?: number;
    colored?: boolean;
}> = (_props) => {
    const props = mergeProps({ size: "100", class: "" }, _props);
    const content = () =>
        typeof props.children === "string" ? props.children.slice(0, props.size === "50" ? 1 : 2) : props.children;
    const hue = createMemo(() => (typeof props.children === "string" ? cyrb53(props.children) : props.hue ?? 0) % 360);

    return (
        <Dynamic
            component={props.href ? NavLink : "div"}
            href={props.href}
            class={`o-avatar o-avatar--${props.size} ${props.colored ? "o-avatar--colored" : ""} ${props.class}`}
            style={{ "--avatar--colors--h": hue(), ...props.style }}
        >
            <Show when={props.status}>
                <StatusIndicator status={props.status} class="o-avatar__status" />
            </Show>
            <Show when={props.image} fallback={<div class="o-avatar__content">{content()}</div>}>
                <img class="o-avatar__content" src={props.image} />
            </Show>
        </Dynamic>
    );
};
