import { onMount, ParentComponent } from "solid-js";

import { useResizeHandle } from "@/iro/hooks/resizeHandle";

export const Sidebar: ParentComponent<{ location: "left" | "right" }> = (props) => {
    let resizeEl!: HTMLButtonElement;
    let sidebarEl!: HTMLDivElement;

    const mountHandle = useResizeHandle(() => props.location === "right");

    onMount(() => {
        mountHandle(resizeEl, sidebarEl);
    });

    return (
        <>
            <button class={`o-resize-handle o-resize-handle--${props.location}`} ref={resizeEl} />

            <aside
                class={`l-sidebar l-sidebar--${props.location} u-themed t-hi`}
                style={{ width: "300px" }}
                ref={sidebarEl}
            >
                {props.children}
            </aside>
        </>
    );
};
