import { addMiddleware, getExomeId } from "exome";
import { Component, DEV } from "solid-js";
import { render } from "solid-js/web";

import { UserConfigProvider } from "@/userConfig";

import { StaticConfig, staticConfig } from "./staticConfig";
import { networkList } from "./store/networkList";
import { RootView } from "./ui/views/Root";

const Root: Component<{ config: StaticConfig }> = (props) => {
    return (
        <StaticConfig.Provider value={props.config}>
            <UserConfigProvider>
                <RootView />
            </UserConfigProvider>
        </StaticConfig.Provider>
    );
};

(async () => {
    networkList.loadConfig();
    const config = await staticConfig;
    render(() => <Root config={config} />, document.body);
})();

//

if (DEV) {
    (async () => {
        new EventSource("/esbuild").addEventListener("change", () => location.reload());

        addMiddleware((instance, action, payload) => {
            const id = getExomeId(instance);
            console.log(id, action, payload);
        });

        await import("solid-devtools/setup");
        const { attachDevtoolsOverlay } = await import("@solid-devtools/overlay");

        attachDevtoolsOverlay();
    })();
}

const enableFocusIndicator = (e: KeyboardEvent) => {
    if (e.key !== "Tab") {
        return;
    }

    document.body.classList.add("t-keyboard");
    document.removeEventListener("keydown", enableFocusIndicator);
    document.addEventListener("mousedown", disableFocusIndicator);
};

const disableFocusIndicator = () => {
    document.body.classList.remove("t-keyboard");

    document.removeEventListener("mousedown", disableFocusIndicator);
    document.addEventListener("keydown", enableFocusIndicator);
};

document.addEventListener("keydown", enableFocusIndicator);
