import { Component, JSX, mergeProps, splitProps } from "solid-js";

export const Main: Component<
    { centered?: boolean; variant?: "narrow" | "message" } & JSX.HTMLAttributes<HTMLDivElement>
> = (_props) => {
    const propsWithDefaults = mergeProps({ variant: "main", class: "" }, _props);
    const [props, propsRest] = splitProps(propsWithDefaults, ["centered", "variant", "class", "ref"]);

    return (
        <div ref={props.ref} class={`l-main l-overflow ${props.centered ? "l-main--centered" : ""}`}>
            <div class={`l-main__content l-main__content--${props.variant} ${props.class}`} {...propsRest} />
        </div>
    );
};
