export const WHOX_FIELDS: Record<string, Exclude<keyof Whox, "isOperator" | "isAway" | "isBot"> | "flags"> = {
    t: "token",
    c: "channel",
    u: "username",
    i: "ip",
    h: "hostname",
    s: "server",
    n: "nickname",
    f: "flags",
    l: "idle",
    a: "account",
    r: "realname",
};

export interface Whox {
    isOperator?: boolean;
    isAway?: boolean;
    isBot?: boolean;
    token?: string;
    channel?: string;
    username?: string;
    ip?: string;
    hostname?: string;
    server?: string;
    nickname?: string;
    idle?: number;
    account?: string;
    realname?: string;
}
