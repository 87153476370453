import { Link, LinkProps, NavLink, NavLinkProps } from "@solidjs/router";
import { Component, JSX, mergeProps, splitProps } from "solid-js";

export interface ActionButtonProps {
    block?: boolean;
    quiet?: boolean;
    round?: boolean;
    selected?: boolean;
    class?: string;
}

export const ActionButton: Component<ActionButtonProps & JSX.ButtonHTMLAttributes<HTMLButtonElement>> = (_props) => {
    const propsWithDefaults = mergeProps({ class: "" }, _props);
    const [props, propsRest] = splitProps(propsWithDefaults, ["block", "quiet", "round", "selected", "class"]);

    return (
        <button
            class={`o-action-button ${props.block ? "u-d-block" : ""} ${props.quiet ? "o-action-button--quiet" : ""} ${
                props.round ? "o-action-button--round" : ""
            } ${props.selected ? "is-selected" : ""} ${props.class}`}
            {...propsRest}
        />
    );
};

export const ActionLink: Component<ActionButtonProps & LinkProps> = (_props) => {
    const propsWithDefaults = mergeProps({ class: "" }, _props);
    const [props, propsRest] = splitProps(propsWithDefaults, ["block", "quiet", "round", "selected", "class"]);

    return (
        <Link
            class={`o-action-button ${props.block ? "u-d-block" : ""} ${props.quiet ? "o-action-button--quiet" : ""} ${
                props.round ? "o-action-button--round" : ""
            } ${props.selected ? "is-selected" : ""} ${props.class}`}
            {...propsRest}
        />
    );
};

export const ActionNavLink: Component<ActionButtonProps & NavLinkProps> = (_props) => {
    const propsWithDefaults = mergeProps({ class: "" }, _props);
    const [props, propsRest] = splitProps(propsWithDefaults, ["block", "quiet", "round", "selected", "class"]);

    return (
        <NavLink
            class={`o-action-button ${props.block ? "u-d-block" : ""} ${props.quiet ? "o-action-button--quiet" : ""} ${
                props.round ? "o-action-button--round" : ""
            } ${props.selected ? "is-selected" : ""} ${props.class}`}
            {...propsRest}
        />
    );
};
