/* eslint-disable @typescript-eslint/ban-types */
import { LinkProps, NavLink } from "@solidjs/router";
import { Component, createSignal, JSX, mergeProps, onCleanup, onMount, splitProps } from "solid-js";

import { useClickOutside } from "../hooks/clickOutside";

export type Alignment = "down-right" | "down-left" | "up-right" | "up-left";

export const ActionMenuSeparator: Component<JSX.HTMLAttributes<HTMLDivElement>> = (_props) => {
    const propsWithDefaults = mergeProps({ class: "" }, _props);
    const [props, propsRest] = splitProps(propsWithDefaults, ["class"]);

    return <div class={`o-action-menu__separator ${props.class}`} {...propsRest} />;
};

export const ActionMenuSlot: Component<JSX.HTMLAttributes<HTMLDivElement>> = (_props) => {
    const propsWithDefaults = mergeProps({ class: "" }, _props);
    const [props, propsRest] = splitProps(propsWithDefaults, ["class"]);

    return <div class={`o-action-menu__slot ${props.class}`} {...propsRest} />;
};

export const ActionMenuItem: Component<
    { pre?: JSX.Element; post?: JSX.Element } & Omit<JSX.ButtonHTMLAttributes<HTMLButtonElement>, "icon">
> = (_props) => {
    const [props, propsRest] = splitProps(_props, ["pre", "post", "children"]);

    return (
        <button class="o-action-menu__item" {...propsRest}>
            <div class="l-media l-media--flush">
                <div class="o-action-menu__icon-slot l-media__block">{props.pre}</div>
                <div class="l-media__block l-media__block--main">{props.children}</div>
                <div class="o-action-menu__icon-slot l-media__block">{props.post}</div>
            </div>
        </button>
    );
};

export const ActionMenuLink: Component<{ pre?: JSX.Element; post?: JSX.Element } & LinkProps> = (_props) => {
    const [props, propsRest] = splitProps(_props, ["pre", "post", "children"]);

    return (
        <NavLink class="o-action-menu__item" {...propsRest}>
            <div class="l-media l-media--flush">
                <div class="o-action-menu__icon-slot l-media__block">{props.pre}</div>
                <div class="l-media__block l-media__block--main">{props.children}</div>
                <div class="o-action-menu__icon-slot l-media__block">{props.post}</div>
            </div>
        </NavLink>
    );
};

export const ActionMenu: Component<
    {
        anchor: HTMLElement | { x: number; y: number };
        align?: Alignment;
        onClose?: () => void;
    } & JSX.HTMLAttributes<HTMLDivElement>
> = (_props) => {
    const propsWithDefaults = mergeProps({ align: "down-left", class: "" }, _props);
    const [props, propsRest] = splitProps(propsWithDefaults, ["class", "anchor", "align", "onClose"]);

    const [mountClickOutside] = useClickOutside(() => props.onClose?.(), { rightClick: true });

    const [position, setPosition] = createSignal<[number, number]>([0, 0]);

    const handleResize = () => {
        let x;
        let y;

        if (props.anchor instanceof Element) {
            /*const scrollContainer = getScrollContainer(props.anchor) ?? window;
            const rect = props.anchor.getBoundingClientRect();

            if (scrollContainer instanceof Window) {
                x = rect.left + scrollContainer.scrollX;
                y = rect.top + scrollContainer.scrollY;
            } else {
                x = rect.left + scrollContainer.scrollLeft;
                y = rect.top + scrollContainer.scrollTop;
            }*/

            const rect = props.anchor.getBoundingClientRect();
            x = rect.left;
            y = rect.top;

            switch (props.align) {
                case "up-right":
                    x += rect.width;
                    break;

                case "down-left":
                case undefined:
                    y += rect.height;
                    break;

                case "down-right":
                    x += rect.width;
                    y += rect.height;
                    break;
            }
        } else {
            x = props.anchor.x;
            y = props.anchor.y;
        }

        setPosition([x, y]);
    };

    const ro = new ResizeObserver(handleResize);

    onMount(() => {
        ro.observe(document.body);
    });

    onCleanup(() => {
        ro.disconnect();
    });

    return (
        <div
            class={`o-action-menu t-up o-action-menu--${props.align} ${props.class}`}
            style={{ "--x": `${position()[0]}px`, "--y": `${position()[1]}px` }}
            ref={mountClickOutside}
            {...propsRest}
        />
    );
};
