/* eslint-disable @typescript-eslint/no-non-null-assertion */

import { match, P } from "ts-pattern";

import { impl, Variant } from "@/lib/unionTypes";

import { NetworkAttributes, parseNetworkAttributes } from "./bouncer";
import { parseIrcDate } from "./date";
import { HistoryTarget } from "./history";
import { RawMessage, RawSource, Tags } from "./rawMessage";

export interface MessageBase {
    tags: Tags;
    time: Date;
    source: Source;
}

export interface Source extends RawSource {
    account: string;
}

export type CapsRecord = Record<string, string>;

export type Message =
    | Variant<"Unknown", MessageBase & RawMessage>
    | Variant<"Reply", MessageBase & { code: number; params: string[] }>
    | Variant<"Nick", MessageBase & { nickname: string }>
    | Variant<"Join", MessageBase & { channel: string; account?: string; realname?: string }>
    | Variant<"Away", MessageBase & { reason?: string }>
    | Variant<"Part", MessageBase & { channel: string; reason?: string }>
    | Variant<"Quit", MessageBase & { reason?: string }>
    | Variant<"Kick", MessageBase & { channel: string; nickname: string; reason?: string }>
    | Variant<"Kill", MessageBase & { nickname: string; reason?: string }>
    | Variant<"Topic", MessageBase & { channel: string; topic: string }>
    | Variant<"Privmsg", MessageBase & { target: string; content: string }>
    | Variant<"Ctcp", MessageBase & { target: string; command: string; params: string }>
    | Variant<"CtcpNotice", MessageBase & { target: string; command: string; params: string }>
    | Variant<"Notice", MessageBase & { target: string; content: string }>
    | Variant<"Mode", MessageBase & { target: string; modes: string; params: string[] }>
    | Variant<"RegisterSuccess", MessageBase & { account: string; message: string }>
    | Variant<"RegisterVerify", MessageBase & { account: string; message: string }>
    | Variant<"VerifySuccess", MessageBase & { account: string; message: string }>
    | Variant<"Authenticate", MessageBase & { content: string }>
    | Variant<"CapLs", MessageBase & { more: boolean; caps: CapsRecord }>
    | Variant<"CapList", MessageBase & { more: boolean; caps: string[] }>
    | Variant<"CapAck", MessageBase & { caps: string[] }>
    | Variant<"CapNak", MessageBase & { caps: string[] }>
    | Variant<"CapNew", MessageBase & { caps: CapsRecord }>
    | Variant<"CapDel", MessageBase & { caps: string[] }>
    | Variant<"Ping", MessageBase & { params: string[] }>
    | Variant<"Pong", MessageBase & { params: string[] }>
    | Variant<"Error", MessageBase & { error: string }>
    | Variant<
          "Fail",
          MessageBase & { command: string | number; code: string; context?: string[]; description?: string }
      >
    | Variant<
          "Warn",
          MessageBase & { command: string | number; code: string; context?: string[]; description?: string }
      >
    | Variant<
          "Note",
          MessageBase & { command: string | number; code: string; context?: string[]; description?: string }
      >
    | Variant<"BatchStart", MessageBase & { name: string; type: string; params: string[] }>
    | Variant<"BatchEnd", MessageBase & { name: string }>
    | Variant<"Account", MessageBase & { account: string }>
    | Variant<"SetName", MessageBase & { realname: string }>
    | Variant<"Chghost", MessageBase & { username: string; hostname: string }>
    | Variant<"Wallops", MessageBase & { content: string }>
    | Variant<"Invite", MessageBase & { nick: string; channel: string }>
    | Variant<"BouncerNetworkChanged", MessageBase & { id: string; attributes: Partial<NetworkAttributes> }>
    | Variant<"BouncerNetworkRemoved", MessageBase & { id: string }>
    | Variant<"BouncerAddNetwork", MessageBase & { id: string }>
    | Variant<"BouncerChangeNetwork", MessageBase & { id: string }>
    | Variant<"BouncerDelNetwork", MessageBase & { id: string }>
    | Variant<"TagMsg", MessageBase & { target: string }>
    | Variant<"Ack", MessageBase>
    | Variant<"ChatHistoryTargets", MessageBase & HistoryTarget>
    | Variant<"MarkRead", MessageBase & { target: string; timestamp?: Date }>;

export const Message = impl<Message>();

export function parseMessage(msg: RawMessage, defaultSource: RawSource): Message {
    const base = {
        tags: msg.tags,
        time: msg.tags.time ? parseIrcDate(msg.tags.time) : new Date(),
        source: { ...defaultSource, ...msg.src, account: msg.tags.account ?? "" },
    };

    return match(msg)
        .with({ cmd: P.number }, ({ cmd, params }) => Message.Reply({ ...base, code: cmd, params }))
        .with({ cmd: "CAP", params: [P._, "LS", "*", P.select()] }, (caps) =>
            Message.CapLs({ ...base, more: true, caps: parseCapsList(caps) }),
        )
        .with({ cmd: "CAP", params: [P._, "LS", P.select()] }, (caps) =>
            Message.CapLs({ ...base, more: false, caps: parseCapsList(caps) }),
        )
        .with({ cmd: "CAP", params: [P._, "LIST", "*", P.select()] }, (caps) =>
            Message.CapList({ ...base, more: true, caps: caps.split(" ") }),
        )
        .with({ cmd: "CAP", params: [P._, "LIST", P.select()] }, (caps) =>
            Message.CapList({ ...base, more: false, caps: caps.split(" ") }),
        )
        .with({ cmd: "CAP", params: [P._, "ACK", P.select()] }, (caps) =>
            Message.CapAck({ ...base, caps: caps.split(" ") }),
        )
        .with({ cmd: "CAP", params: [P._, "NAK", P.select()] }, (caps) =>
            Message.CapNak({ ...base, caps: caps.split(" ") }),
        )
        .with({ cmd: "CAP", params: [P._, "NEW", P.select()] }, (caps) =>
            Message.CapNew({ ...base, caps: parseCapsList(caps) }),
        )
        .with({ cmd: "CAP", params: [P._, "DEL", P.select()] }, (caps) =>
            Message.CapDel({ ...base, caps: caps.split(" ") }),
        )
        .with(
            { cmd: "REGISTER", params: ["SUCCESS", P.select("account"), P.select("message")] },
            ({ account, message }) => Message.RegisterSuccess({ ...base, account, message }),
        )
        .with(
            { cmd: "REGISTER", params: ["VERIFICATION_REQUIRED", P.select("account"), P.select("message")] },
            ({ account, message }) => Message.RegisterVerify({ ...base, account, message }),
        )
        .with(
            { cmd: "VERIFY", params: ["SUCCESS", P.select("account"), P.select("message")] },
            ({ account, message }) => Message.VerifySuccess({ ...base, account, message }),
        )
        .with({ cmd: "AUTHENTICATE", params: [P.select()] }, (content) => Message.Authenticate({ ...base, content }))
        .with({ cmd: "PING", params: P.select() }, (params) => Message.Ping({ ...base, params }))
        .with({ cmd: "PONG", params: P.select() }, (params) => Message.Pong({ ...base, params }))
        .with({ cmd: "ERROR", params: [P.select()] }, (error) => Message.Error({ ...base, error }))
        .with(
            {
                cmd: P.union("FAIL", "WARN", "NOTE").select("cmd"),
                params: [
                    P.select("command"),
                    P.select("code"),
                    ...P.array().select("context"),
                    P.select("description"),
                ],
            },
            ({ cmd, command, code, context, description }) => {
                const ctor = { FAIL: Message.Fail, WARN: Message.Warn, NOTE: Message.Note }[cmd];
                return ctor({ ...base, command, code, context, description });
            },
        )
        .with(
            {
                cmd: "BATCH",
                params: [P.string.startsWith("+").select("name"), P.select("type"), ...P.array().select("params")],
            },
            ({ name, type, params }) => Message.BatchStart({ ...base, name: name.slice(1), type, params }),
        )
        .with({ cmd: "BATCH", params: [P.string.startsWith("-").select()] }, (name) =>
            Message.BatchEnd({ ...base, name: name.slice(1) }),
        )
        .with({ cmd: "BOUNCER", params: ["NETWORK", P.select(), "*"] }, (id) =>
            Message.BouncerNetworkRemoved({ ...base, id }),
        )
        .with({ cmd: "BOUNCER", params: ["NETWORK", P.select("id"), P.select("attrs")] }, ({ id, attrs }) =>
            Message.BouncerNetworkChanged({ ...base, id, attributes: parseNetworkAttributes(attrs) }),
        )
        .with({ cmd: "BOUNCER", params: ["ADDNETWORK", P.select()] }, (id) =>
            Message.BouncerAddNetwork({ ...base, id }),
        )
        .with({ cmd: "BOUNCER", params: ["CHANGENETWORK", P.select()] }, (id) =>
            Message.BouncerChangeNetwork({ ...base, id }),
        )
        .with({ cmd: "BOUNCER", params: ["DELNETWORK", P.select()] }, (id) =>
            Message.BouncerDelNetwork({ ...base, id }),
        )
        .with({ cmd: "CHATHISTORY", params: ["TARGETS", P.select("name"), P.select("date")] }, ({ name, date }) =>
            Message.ChatHistoryTargets({ ...base, name, latest: parseIrcDate(date) }),
        )
        .with({ cmd: "MARKREAD", params: [P.select("target"), P.select("timestamp")] }, ({ target, timestamp }) =>
            Message.MarkRead({
                ...base,
                target,
                timestamp: timestamp !== "*" ? parseIrcDate(timestamp.slice("timestamp=".length)) : undefined,
            }),
        )
        .with({ cmd: "PRIVMSG", params: [P.select("target"), P.select("content")] }, ({ target, content }) => {
            const ctcp = parseCtcp(content);
            return ctcp ? Message.Ctcp({ ...base, target, ...ctcp }) : Message.Privmsg({ ...base, target, content });
        })
        .with({ cmd: "NOTICE", params: [P.select("target"), P.select("content")] }, ({ target, content }) => {
            const ctcp = parseCtcp(content);
            return ctcp
                ? Message.CtcpNotice({ ...base, target, ...ctcp })
                : Message.Notice({ ...base, target, content });
        })
        .with(
            { cmd: "JOIN", params: [P.select("channel"), P.select("account"), P.select("realname")] },
            ({ channel, account, realname }) =>
                Message.Join({ ...base, channel, account: account !== "*" ? account : "", realname }),
        )
        .with({ cmd: "JOIN", params: [P.select()] }, (channel) => Message.Join({ ...base, channel }))
        .with({ cmd: "AWAY", params: P.select() }, ([reason]) => Message.Away({ ...base, reason }))
        .with({ cmd: "QUIT", params: P.select() }, ([reason]) => Message.Quit({ ...base, reason }))
        .with({ cmd: "PART", params: P.select([P._, ...P.array()]) }, ([channel, reason]) =>
            Message.Part({ ...base, channel, reason }),
        )
        .with({ cmd: "NICK", params: [P.select()] }, (nickname) => Message.Nick({ ...base, nickname }))
        .with({ cmd: "KICK", params: P.select([P._, P._, ...P.array()]) }, ([channel, nickname, reason]) =>
            Message.Kick({ ...base, channel, nickname, reason }),
        )
        .with({ cmd: "KILL", params: P.select([P._, ...P.array()]) }, ([nickname, reason]) =>
            Message.Kill({ ...base, nickname, reason }),
        )
        .with({ cmd: "TOPIC", params: [P.select("channel"), P.select("topic")] }, ({ channel, topic }) =>
            Message.Topic({ ...base, channel, topic }),
        )
        .with(
            { cmd: "MODE", params: [P.select("target"), P.select("modes"), ...P.array().select("params")] },
            ({ target, modes, params }) => Message.Mode({ ...base, target, modes, params }),
        )
        .with({ cmd: "ACCOUNT", params: [P.select()] }, (account) => Message.Account({ ...base, account }))
        .with({ cmd: "SETNAME", params: [P.select()] }, (realname) => Message.SetName({ ...base, realname }))
        .with({ cmd: "CHGHOST", params: [P.select("username"), P.select("hostname")] }, ({ username, hostname }) =>
            Message.Chghost({ ...base, username, hostname }),
        )
        .with({ cmd: "WALLOPS", params: [P.select()] }, (content) => Message.Wallops({ ...base, content }))
        .with({ cmd: "INVITE", params: [P.select("nick"), P.select("channel")] }, ({ nick, channel }) =>
            Message.Invite({ ...base, nick, channel }),
        )
        .with({ cmd: "TAGMSG", params: [P.select()] }, (target) => Message.TagMsg({ ...base, target }))
        .otherwise(() => Message.Unknown({ ...base, ...msg }));
}

export const capPattern = /^([^=]+)(?:=(.*))?$/;

function parseCapsList(caps: string) {
    return caps.split(" ").reduce((r: CapsRecord, cap) => {
        const [, name, value = ""] = cap.match(capPattern) ?? ([, cap] as const);
        return { ...r, [name]: value };
    }, {});
}

const ctcpPattern = /^\x01([^ \x01]+)(?: ([^\x01]*))?\x01?$/;

function parseCtcp(txt: string): { command: string; params: string } | undefined {
    const match = txt.match(ctcpPattern);
    if (!match) {
        return;
    }
    const [, command, params = ""] = match;
    return { command: command!, params };
}
