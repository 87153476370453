import { Component, JSX, Show, splitProps } from "solid-js";

import { callEventHandler } from "@/lib/solid/eventHandler";
import icons from "#/icons.svg";

import { Icon } from "./Icon";

export const Checkbox: Component<{ label?: string } & JSX.InputHTMLAttributes<HTMLInputElement>> = (_props) => {
    const [props, propsRest] = splitProps(_props, ["label", "checked", "onChange"]);
    const onChange: JSX.EventHandler<HTMLInputElement, Event> = (e) => {
        callEventHandler(props.onChange, e);
        if (props.checked !== undefined && props.checked !== e.currentTarget.checked) {
            e.currentTarget.checked = props.checked;
        }
    };

    return (
        <label class={`o-checkbox ${!props.label?.length ? "o-checkbox--standalone" : ""}`}>
            <input
                class="o-checkbox__native"
                type="checkbox"
                checked={props.checked}
                onChange={onChange}
                {...propsRest}
            />
            <div class="o-checkbox__box">
                <Icon source={icons} id="check" block class="o-checkbox__check-icon" />
            </div>
            <Show when={props.label?.length}>
                <div class="o-checkbox__label">{props.label}</div>
            </Show>
        </label>
    );
};
