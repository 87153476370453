/* eslint-disable @typescript-eslint/ban-types */

import { Join, PrefixedKeys, Replace, ReplaceSeq, SplitBy } from "./types";

type ParamsRecFromParts<Parts> = Parts extends [infer First, ...infer Rest]
    ? First extends `:${infer Param}`
        ? { [K in Param]: string } & ParamsRecFromParts<Rest>
        : ParamsRecFromParts<Rest>
    : {};

type ParamsRec<Path> = ParamsRecFromParts<SplitBy<"/", Path>>;

type PopulatedRec<Params, Path> = Join<"/", Replace<PrefixedKeys<":", Params>, SplitBy<"/", Path>>>;

type ParamsFromParts<Parts> = Parts extends [infer First, ...infer Rest]
    ? First extends `:${string}`
        ? [string, ...ParamsFromParts<Rest>]
        : ParamsFromParts<Rest>
    : [];

type Params<Path> = ParamsFromParts<SplitBy<"/", Path>>;

type Populated<Params, Path> = Join<"/", ReplaceSeq<`:${string}`, Params, SplitBy<"/", Path>>>;

export interface Route<Path extends string> {
    (): Path;
    <P extends Params<Path>>(...params: P): Populated<P, Path>;
    <P extends ParamsRec<Path>>(params: P): PopulatedRec<P, Path>;
}

export function route<Path extends string>(path: Path): Route<Path> {
    function create(): Path;
    function create<P extends Params<Path>>(...params: P): Populated<P, Path>;
    function create<P extends ParamsRec<Path>>(params: P): PopulatedRec<P, Path>;
    function create(...params: [Record<string, string>] | string[]) {
        if (!params.length) {
            return path;
        }

        const first = params[0];

        if (typeof first === "object") {
            return path
                .split("/")
                .map((part) => (part[0] === ":" && part.slice(1) in first ? first[part.slice(1)] : part))
                .join("/");
        }

        let i = 0;

        return path
            .split("/")
            .map((part) => (part[0] === ":" ? params[i++] : part))
            .join("/");
    }

    return create;
}
