import { NavLink, NavLinkProps } from "@solidjs/router";
import { Component, JSX, mergeProps, Show, splitProps } from "solid-js";

export const MenuSeparator: Component<JSX.HTMLAttributes<HTMLDivElement>> = (_props) => {
    const propsWithDefaults = mergeProps({ class: "" }, _props);
    const [props, propsRest] = splitProps(propsWithDefaults, ["class"]);
    return <div class={`o-menu__separator ${props.class}`} {...propsRest} />;
};

export const MenuHeader: Component<JSX.HTMLAttributes<HTMLElement>> = (_props) => {
    const propsWithDefaults = mergeProps({ class: "" }, _props);
    const [props, propsRest] = splitProps(propsWithDefaults, ["class"]);
    return <header class={`o-menu__header ${props.class}`} {...propsRest} />;
};

export const MenuItem: Component<
    {
        selected?: boolean;
        header?: boolean;
        pre?: JSX.Element;
        post?: JSX.Element;
        spacing?: "75" | "100";
    } & JSX.ButtonHTMLAttributes<HTMLButtonElement>
> = (_props) => {
    const propsWithDefaults = mergeProps({ class: "", spacing: "100" }, _props);
    const [props, propsRest] = splitProps(propsWithDefaults, [
        "selected",
        "header",
        "pre",
        "post",
        "spacing",
        "children",
        "class",
    ]);

    return (
        <button
            class={`o-menu__item l-media l-media--${props.spacing} ${props.selected ? "is-selected" : ""} ${
                props.header ? "o-menu__header" : ""
            } ${props.class}`}
            {...propsRest}
        >
            <Show when={props.pre}>{(pre) => <div class="l-media__block">{pre()}</div>}</Show>
            <div class="l-media__block l-media__block--main u-elp">{props.children}</div>
            <Show when={props.post}>{(post) => <div class="l-media__block">{post()}</div>}</Show>
        </button>
    );
};

export const MenuLink: Component<
    {
        selected?: boolean;
        header?: boolean;
        pre?: JSX.Element;
        post?: JSX.Element;
        spacing?: "75" | "100";
    } & NavLinkProps
> = (_props) => {
    const propsWithDefaults = mergeProps({ class: "", spacing: "100" }, _props);
    const [props, propsRest] = splitProps(propsWithDefaults, [
        "selected",
        "header",
        "pre",
        "post",
        "spacing",
        "children",
        "class",
    ]);

    return (
        <NavLink
            inactiveClass=""
            class={`o-menu__item l-media l-media--${props.spacing} ${props.selected ? "_is-selected" : ""} ${
                props.header ? "o-menu__header" : ""
            } ${props.class}`}
            activeClass="is-selected"
            {...propsRest}
        >
            <Show when={props.pre}>{(pre) => <div class="l-media__block">{pre()}</div>}</Show>
            <div class="l-media__block l-media__block--main u-elp">{props.children}</div>
            <Show when={props.post}>{(post) => <div class="l-media__block">{post()}</div>}</Show>
        </NavLink>
    );
};

export const Menu: Component<{ pull?: boolean } & JSX.HTMLAttributes<HTMLDivElement>> = (_props) => {
    const propsWithDefaults = mergeProps({ class: "" }, _props);
    const [props, propsRest] = splitProps(propsWithDefaults, ["class", "pull"]);
    return <div class={`o-menu ${props.pull ? "o-menu--pull" : ""} ${props.class}`} {...propsRest} />;
};
