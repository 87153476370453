import { Component, JSX, mergeProps, splitProps } from "solid-js";

export const Header: Component<{ side?: boolean; theme?: string } & JSX.HTMLAttributes<HTMLElement>> = (_props) => {
    const propsWithDefaults = mergeProps({ class: "" }, _props);
    const [props, propsRest] = splitProps(propsWithDefaults, ["side", "theme", "class"]);

    return (
        <header
            class={`l-header ${props.side ? "l-header--side" : ""} l-media ${
                props.theme ? `u-themed t-${props.theme}` : ""
            } ${props.class}`}
            {...propsRest}
        />
    );
};
