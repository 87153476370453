import { useIntl } from "@cookbook/solid-intl";
import { Component, createMemo, JSX, Match, Switch } from "solid-js";
import { P } from "ts-pattern";

import { useStore } from "@/lib/exome/solid";
import { Typing } from "@/lib/irc/typing";
import { isMatchingN } from "@/lib/ts-pattern/util";
import { Buffer as BufferStore } from "@/store/buffer";
import { ChannelBuffer } from "@/store/buffer/channelBuffer";
import { UserBuffer } from "@/store/buffer/userBuffer";
import { StaticMention } from "@/ui/objects/Mention";

const ChannelBufferTyping: Component<{ buffer: ChannelBuffer }> = (props) => {
    const intl = useIntl();

    const buffer = useStore(() => props.buffer);
    const network = useStore(() => buffer().network);
    const members = useStore(() => buffer().members);

    const typing = createMemo(() =>
        Array.from(members().members.values())
            .filter((member) => member.typing === Typing.Active && member.user !== network().me)
            .map((member) => member.user),
    );

    const style = (): JSX.CSSProperties => (!typing().length ? { opacity: 0, visibility: "hidden" } : {});

    return (
        <small class="u-d-block u-mt-400 u-mb-200" style={style()}>
            <em>
                {intl.formatMessage(
                    {
                        id: "typing.text",
                        defaultMessage: `{userCount, plural,
                            one {{user1} is typing...}
                            =2 {{user1} and {user2} are typing...}
                            =3 {{user1}, {user2} and {user3} are typing...}
                            other {{user1}, {user2}, {user3} and {othersCount} others are typing...}
                        }`,
                    },
                    {
                        user1: <StaticMention>{typing()[0]?.whox.nickname}</StaticMention>,
                        user2: <StaticMention>{typing()[1]?.whox.nickname}</StaticMention>,
                        user3: <StaticMention>{typing()[2]?.whox.nickname}</StaticMention>,
                        userCount: typing().length,
                        othersCount: typing().length - 3,
                    },
                )}
            </em>
        </small>
    );
};

const UserBufferTyping: Component<{ buffer: UserBuffer }> = (props) => {
    const intl = useIntl();

    const buffer = useStore(() => props.buffer);
    const user = useStore(() => buffer().user);

    const style = (): JSX.CSSProperties =>
        buffer().typing !== Typing.Active ? { opacity: 0, visibility: "hidden" } : {};

    return (
        <small class="u-d-block u-mt-400 u-mb-200" style={style()}>
            <em>
                {intl.formatMessage(
                    {
                        id: "typing.text",
                        defaultMessage: "{user} is typing...",
                    },
                    {
                        user: user().whox.nickname,
                    },
                )}
            </em>
        </small>
    );
};

export const BufferTyping: Component<{ buffer: BufferStore }> = (props) => (
    <Switch>
        <Match when={isMatchingN(P.instanceOf(ChannelBuffer), props.buffer)}>
            {(buffer) => <ChannelBufferTyping buffer={buffer()} />}
        </Match>
        <Match when={isMatchingN(P.instanceOf(UserBuffer), props.buffer)}>
            {(buffer) => <UserBufferTyping buffer={buffer()} />}
        </Match>
    </Switch>
);
