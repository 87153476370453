import { function as F, record as R } from "fp-ts";
import { Component, createSignal, mergeProps, onMount, Show } from "solid-js";

import { Form, FormItem } from "@/iro/layouts/Form";
import { Button } from "@/iro/objects/Button";
import { Checkbox } from "@/iro/objects/Checkbox";
import { Dialog } from "@/iro/objects/Dialog";
import { TextField } from "@/iro/objects/TextField";
import { useStore } from "@/lib/exome/solid";
import { Network, ROOT_NETWORK } from "@/store/network";
import { networkList } from "@/store/networkList";

export const CreateNetworkDialog: Component<{
    network?: Network;
    onClose?: (trigger?: string) => void;
}> = (_props) => {
    const props = mergeProps({ onClose: () => void 0 }, _props);

    let targetInputEl!: HTMLInputElement;

    const networks = useStore(() => networkList);
    const bouncer = useStore(() => networks().get(ROOT_NETWORK));

    const [host, setHost] = createSignal("");
    const [port, setPort] = createSignal(6697);
    const [tls, setTls] = createSignal(true);
    const [name, setName] = createSignal("");
    const [nickname, setNickname] = createSignal("");
    const [username, setUsername] = createSignal("");
    const [realname, setRealname] = createSignal("");
    const [pass, setPass] = createSignal("");

    const setTextFieldHandler = (
        setter: (value: string) => void,
        e: InputEvent & { currentTarget: HTMLInputElement },
    ) => setter(e.currentTarget.value);

    const setNumericTextFieldHandler = (
        setter: (value: number) => void,
        e: InputEvent & { currentTarget: HTMLInputElement },
    ) => setter(+e.currentTarget.value);

    const setCheckFieldHandler = (
        setter: (value: boolean) => void,
        e: InputEvent & { currentTarget: HTMLInputElement },
    ) => setter(e.currentTarget.checked);

    const handleClose = async (result?: string) => {
        if (result === "ok") {
            let attrs = {
                host: host(),
                port: port(),
                tls: tls(),
                name: name(),
                nickname: nickname(),
                username: username(),
                realname: realname(),
                pass: pass(),
            };

            if (props.network) {
                attrs = F.pipe(
                    attrs,
                    // eslint-disable-next-line @typescript-eslint/no-explicit-any, solid/reactivity
                    R.filterWithIndex((key: keyof typeof attrs, value) => props.network!.attrs[key] !== value) as any,
                );

                bouncer()?.bouncerChangeNetwork(props.network.id, { ...attrs });
            } else {
                bouncer()?.bouncerAddNetwork(attrs);
            }
        } else if (result === "delete") {
            if (props.network) {
                bouncer()?.bouncerDelNetwork(props.network.id);
            }
        }

        props.onClose?.(result);
    };

    onMount(() => {
        if (props.network) {
            setHost(props.network.attrs.host);
            setPort(props.network.attrs.port);
            setName(props.network.attrs.name);
            setNickname(props.network.attrs.nickname);
            setUsername(props.network.attrs.username);
            setRealname(props.network.attrs.realname);
            setPass(props.network.attrs.pass);
        }

        targetInputEl.focus();
    });

    return (
        <Dialog
            title={props.network ? "Edit network" : "Add network"}
            size="sm"
            onClose={handleClose}
            footer={(close) => (
                <div class="l-button-group">
                    <Show when={props.network}>
                        <Button type="button" outline onClick={[close, "delete"]}>
                            Delete
                        </Button>{" "}
                    </Show>
                    <Button type="button" outline onClick={[close, "cancel"]} class="u-ml-auto">
                        Cancel
                    </Button>{" "}
                    <Button type="submit" variant="primary" onClick={[close, "ok"]}>
                        Apply
                    </Button>
                </div>
            )}
        >
            <Form labelPosition="left" onSubmit={[handleClose, "ok"]}>
                <FormItem label="Name">
                    <TextField ref={targetInputEl} value={name()} onInput={[setTextFieldHandler, setName]} />
                </FormItem>
                <FormItem label="Host *">
                    <TextField value={host()} onInput={[setTextFieldHandler, setHost]} />
                </FormItem>
                <FormItem label="Port">
                    <TextField type="number" value={port()} onInput={[setNumericTextFieldHandler, setPort]} />
                </FormItem>
                <FormItem>
                    <Checkbox label="TLS" checked={tls()} onInput={[setCheckFieldHandler, setTls]} />
                </FormItem>
                <FormItem label="Nickname">
                    <TextField value={nickname()} onInput={[setTextFieldHandler, setNickname]} />
                </FormItem>
                <FormItem label="Username">
                    <TextField value={username()} onInput={[setTextFieldHandler, setUsername]} />
                </FormItem>
                <FormItem label="Real name">
                    <TextField value={realname()} onInput={[setTextFieldHandler, setRealname]} />
                </FormItem>
                <FormItem label="Server password">
                    <TextField type="password" value={pass()} onInput={[setTextFieldHandler, setPass]} />
                </FormItem>
                <button class="u-d-none">Send</button>
            </Form>
        </Dialog>
    );
};
