import { JSX, ParentComponent, splitProps } from "solid-js";

import { useStore } from "@/lib/exome/solid";
import { User } from "@/store/user";
import { DynamicUserAvatar } from "@/ui/objects/UserAvatar";

import { UserName } from "../objects/UserName";

export const UserCard: ParentComponent<{ user: User } & JSX.HTMLAttributes<HTMLDivElement>> = (props_) => {
    const [props, propsRest] = splitProps(props_, ["user", "children"]);
    const user = useStore(() => props.user);

    return (
        <div class="l-media l-media--flush" {...propsRest}>
            <DynamicUserAvatar size="75" user={props.user} showStatus colored class="l-media__block" />
            <div class="l-media__block l-media__block--main">
                <div class="u-elp">
                    <strong>
                        <UserName user={props.user} />
                    </strong>
                </div>
                <small class="u-d-block u-elp">{user().whox.realname}</small>
            </div>
            {props.children}
        </div>
    );
};
