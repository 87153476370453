import {
    ComponentProps,
    createContext,
    createEffect,
    createMemo,
    createSelector,
    createSignal,
    For,
    JSX,
    on,
    onCleanup,
    onMount,
    ParentComponent,
    ParentProps,
    Show,
    splitProps,
    useContext,
} from "solid-js";
import { createStore, produce } from "solid-js/store";

import { Dialog } from "./Dialog";
import { Menu, MenuHeader, MenuItem, MenuSeparator } from "./Menu";

type Pop = () => void;

type PushCategory = (title?: string) => Pop;

type PushPage = (id: string, title: string, icon?: JSX.Element) => Pop;

interface Page {
    id: string;
    title: string;
    icon?: JSX.Element;
}

interface Category {
    title?: string;
    pages: Page[];
}

interface Context {
    isCurrentId: (val: string) => boolean;
    pushCategory: PushCategory;
    pushPage: PushPage;
}

const Context = createContext<Context>({
    isCurrentId: () => false,
    pushCategory: () => () => void 0,
    pushPage: () => () => void 0,
});

export const PageDialog = (
    _props: { selected?: string } & ParentProps<Omit<ComponentProps<typeof Dialog>, "sidebar">>,
) => {
    const [props, propsRest] = splitProps(_props, ["title", "sidebarTitle", "selected"]);

    let idInit = false;
    const [currentId, setCurrentId] = createSignal<string>();
    const isCurrentId = createSelector<string | undefined, string>(currentId);

    const [store, setStore] = createStore<{ categories: Category[] }>({ categories: [] });

    const pushCategory: PushCategory = (title) => {
        const data = { title, pages: [] };

        setStore("categories", (c) => [...c, data]);

        return () => {
            setStore(
                "categories",
                produce((c) => {
                    const i = c.indexOf(data);

                    if (i !== -1) {
                        c.splice(i, 1);
                    }
                }),
            );
        };
    };

    const pushPage: PushPage = (id, title, icon) => {
        const data = { id, title, icon };
        const i = store.categories.length - 1;

        setStore("categories", i, "pages", (p) => [...p, data]);

        if (!idInit) {
            idInit = true;
            setCurrentId(id);
        }

        return () => {
            setStore(
                "categories",
                i,
                "pages",
                produce((c) => {
                    const i = c.indexOf(data);

                    if (i !== -1) {
                        c.splice(i, 1);
                    }
                }),
            );
        };
    };

    const page = createMemo(() => {
        for (const category of store.categories) {
            const page = category.pages.find((p) => isCurrentId(p.id));
            if (page) {
                return page;
            }
        }
    });

    const ctx: Context = { isCurrentId, pushCategory, pushPage };

    createEffect(
        on(
            () => props.selected,
            (selected) => {
                if (selected) {
                    idInit = true;
                    setCurrentId(selected);
                }
            },
        ),
    );

    return (
        <Context.Provider value={ctx}>
            <Dialog
                title={page()?.title}
                sidebarTitle={props.title}
                sidebar={
                    <Menu>
                        <For each={store.categories}>
                            {(category, i) => (
                                <>
                                    <Show when={i()}>
                                        <MenuSeparator />
                                    </Show>
                                    <Show when={category.title}>
                                        <MenuHeader class={i() === 0 ? "u-pt-0" : ""}>{category.title}</MenuHeader>
                                    </Show>
                                    <For each={category.pages}>
                                        {(page) => (
                                            <MenuItem
                                                selected={isCurrentId(page.id)}
                                                onClick={[setCurrentId, page.id]}
                                                pre={page.icon}
                                            >
                                                {page.title}
                                            </MenuItem>
                                        )}
                                    </For>
                                </>
                            )}
                        </For>
                    </Menu>
                }
                {...propsRest}
            />
        </Context.Provider>
    );
};

export const PageDialogCategory: ParentComponent<{ title?: string }> = (props) => {
    const ctx = useContext(Context);

    onMount(() => {
        const pop = ctx.pushCategory(props?.title);
        onCleanup(pop);
    });

    return <>{props.children}</>;
};

PageDialog.Category = PageDialogCategory;

export const PageDialogPage: ParentComponent<{ id: string; title: string; icon?: JSX.Element }> = (props) => {
    const ctx = useContext(Context);

    onMount(() => {
        const pop = ctx.pushPage(props.id, props.title, props.icon);
        onCleanup(pop);
    });

    return <Show when={ctx.isCurrentId(props.id)}>{props.children}</Show>;
};

PageDialog.Page = PageDialogPage;
