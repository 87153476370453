import { CapsRecord as Caps } from "@/lib/irc/message";
import { isSaslMechanism, SaslMechanism } from "@/lib/sasl";
import { isEnum } from "@/lib/types";

export enum Capability {
    AccountNotify = "account-notify",
    AwayNotify = "away-notify",
    Batch = "batch",
    Chghost = "chghost",
    EchoMessage = "echo-message",
    ExtendedJoin = "extended-join",
    InviteNotify = "invite-notify",
    LabelledResponse = "labeled-response",
    MessageTags = "message-tags",
    MultiPrefix = "multi-prefix",
    Sasl = "sasl",
    ServerTime = "server-time",
    Setname = "setname",

    AccountRegistration = "draft/account-registration",
    Chathistory = "draft/chathistory",
    EventPlayback = "draft/event-playback",
    ExtendedMonitor = "draft/extended-monitor",
    ReadMarker = "draft/read-marker",
    ChannelContext = "draft/channel-context",

    BouncerNetworks = "soju.im/bouncer-networks",
    BouncerNetworksNotify = "soju.im/bouncer-networks-notify",
    WebPush = "soju.im/webpush",
    Search = "soju.im/search",
}

export const isCapability = isEnum(Capability);

export interface CapabilityData<T = undefined> {
    value: T;
    enabled: boolean;
}

export const accountRegistrationParamPattern = /^([^=]+)(?:=(.*))?$/;

export interface AccountRegistrationParams {
    beforeConnect: boolean;
    emailRequired: boolean;
    customAccountName: boolean;
}

export class CapabilityManager {
    [Capability.AccountNotify]?: CapabilityData;
    [Capability.AwayNotify]?: CapabilityData;
    [Capability.Batch]?: CapabilityData;
    [Capability.Chghost]?: CapabilityData;
    [Capability.EchoMessage]?: CapabilityData;
    [Capability.ExtendedJoin]?: CapabilityData;
    [Capability.InviteNotify]?: CapabilityData;
    [Capability.LabelledResponse]?: CapabilityData;
    [Capability.MessageTags]?: CapabilityData;
    [Capability.MultiPrefix]?: CapabilityData;
    [Capability.Sasl]?: CapabilityData<SaslMechanism[]>;
    [Capability.ServerTime]?: CapabilityData;
    [Capability.Setname]?: CapabilityData;

    [Capability.AccountRegistration]?: CapabilityData<AccountRegistrationParams>;
    [Capability.Chathistory]?: CapabilityData;
    [Capability.EventPlayback]?: CapabilityData;
    [Capability.ExtendedMonitor]?: CapabilityData;
    [Capability.ReadMarker]?: CapabilityData;
    [Capability.ChannelContext]?: CapabilityData;

    [Capability.BouncerNetworks]?: CapabilityData;
    [Capability.BouncerNetworksNotify]?: CapabilityData;
    [Capability.WebPush]?: CapabilityData;
    [Capability.Search]?: CapabilityData;

    has(cap: Capability) {
        return !!this[cap];
    }

    enabled(cap: Capability) {
        return !!this[cap]?.enabled;
    }

    get<C extends Capability>(
        cap: C,
    ): (Required<CapabilityManager>[C] extends CapabilityData<infer T> ? T : undefined) | undefined {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        return this[cap]?.value as any;
    }

    ls(caps: Caps) {
        for (const [name, value] of Object.entries(caps)) {
            const key = name.toLowerCase();

            if (!isCapability(key)) {
                continue;
            }

            switch (key) {
                case Capability.Sasl:
                    this[key] = { enabled: !!this[key]?.enabled, value: value.split(",").filter(isSaslMechanism) };
                    break;

                case Capability.AccountRegistration: {
                    const params = value.split(",").reduce(
                        (r: AccountRegistrationParams, param) => {
                            const [, key, _value] = param.match(accountRegistrationParamPattern) ?? [, param, 0];
                            switch (key) {
                                case "before-connect":
                                    return { ...r, beforeConnect: true };

                                case "email-required":
                                    return { ...r, emailRequired: true };

                                case "custom-account-name":
                                    return { ...r, customAccountName: true };

                                default:
                                    return r;
                            }
                        },
                        { beforeConnect: false, emailRequired: false, customAccountName: false },
                    );
                    this[key] = { enabled: !!this[key]?.enabled, value: params };
                    break;
                }

                default:
                    this[key] = { enabled: !!this[key]?.enabled, value: undefined };
                    break;
            }
        }
    }

    list(caps: string[]) {
        for (const name of caps) {
            const key = name.toLowerCase();

            if (!isCapability(key)) {
                continue;
            }

            const cap = this[key];
            if (cap) {
                cap.enabled = true;
            }
        }
    }

    del(caps: string[]) {
        for (const cap of caps) {
            const key = cap.toLowerCase();

            if (isCapability(key)) {
                this[key] = undefined;
            }
        }
    }

    clear() {
        for (const key of Object.values(Capability)) {
            this[key] = undefined;
        }
    }

    ack(caps: string[]) {
        for (const cap of caps) {
            const enabled = !cap.startsWith("-");
            const key = (enabled ? cap : cap.slice(1)).toLowerCase();

            if (!isCapability(key)) {
                continue;
            }

            const capData = this[key];
            if (capData) {
                capData.enabled = enabled;
            }
        }
    }

    disableAll() {
        for (const key of Object.values(Capability)) {
            const cap = this[key];
            if (cap) {
                cap.enabled = false;
            }
        }
    }

    missing(caps: Capability[]) {
        return caps.filter((cap) => this[cap]?.enabled === false);
    }
}
