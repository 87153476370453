import { onCleanup } from "solid-js";

function strToFloat(s: string, def: number) {
    const n = +s.slice(0, -2);
    return !isNaN(n) ? n : def;
}

export const useResizeHandle = (invert?: () => boolean) => {
    let handleEl: HTMLElement | undefined;
    let containerEl: HTMLElement | undefined;

    let down = false;
    let x = 0;
    let width = 0;

    const handleMouseDown = (e: MouseEvent) => {
        if (e.target === handleEl && containerEl && e.button === 0) {
            const computedStyle = getComputedStyle(containerEl);

            x = e.screenX;
            width = strToFloat(computedStyle.width, 0);
            down = true;
        }
    };

    const handleMouseMove = (e: MouseEvent) => {
        if (!down || !containerEl) {
            return;
        }

        const multiplier = invert?.() ? -1 : 1;
        const diff = e.screenX - x;

        containerEl.style.width = `${width + multiplier * diff}px`;
    };

    const handleMouseUp = (e: MouseEvent) => {
        down = false;
    };

    const mount = (handleEl_: HTMLElement, containerEl_: HTMLElement) => {
        handleEl = handleEl_;
        containerEl = containerEl_;

        document.addEventListener("mousedown", handleMouseDown);
        document.addEventListener("mousemove", handleMouseMove);
        document.addEventListener("mouseup", handleMouseUp);
    };

    onCleanup(() => {
        document.removeEventListener("mousedown", handleMouseDown);
        document.removeEventListener("mousemove", handleMouseMove);
        document.removeEventListener("mouseup", handleMouseUp);
    });

    return mount;
};
