export function createIndexFinder<T, U>(selector: (item: T) => U, compare: (a: U, b: U) => boolean) {
    return function findIndex(item: T, list: T[]) {
        let i = list.length - 1;
        const v = selector(item);

        if (i !== -1 && compare(v, selector(list[0]!))) {
            i = -1;
        } else {
            for (; i >= 0; --i) {
                if (compare(selector(list[i]!), v)) {
                    break;
                }
            }
        }

        return i;
    };
}
