import { staticConfig } from "@/staticConfig";

export interface ExtractorBackend {
    url: string;
}

export interface LinkProps {
    type?: string;
}

const urls = new Map<string, LinkProps | undefined>();

export function getCached(url: string) {
    return urls.get(url);
}

export async function get(url: string) {
    if (urls.has(url)) {
        return urls.get(url);
    }

    const config = await staticConfig;
    const reqUrl = config.backend.length ? `${config.backend}/api/v1/headers/${url}` : url;

    try {
        const res = await fetch(reqUrl, { method: "HEAD" });

        if (!res.ok) {
            return;
        }

        const value = { type: res.headers.get("Content-Type") ?? undefined };

        urls.set(url, value);
        return value;
    } catch {
        /**/
    }
}
