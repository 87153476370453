import { Component, JSX, Show, splitProps } from "solid-js";

import { callEventHandler } from "@/lib/solid/eventHandler";

export const Switch: Component<{ label?: string } & JSX.InputHTMLAttributes<HTMLInputElement>> = (_props) => {
    const [props, propsRest] = splitProps(_props, ["label", "checked", "onChange"]);
    const onChange: JSX.EventHandler<HTMLInputElement, Event> = (e) => {
        callEventHandler(props.onChange, e);
        if (props.checked !== undefined && props.checked !== e.currentTarget.checked) {
            e.currentTarget.checked = props.checked;
        }
    };

    return (
        <label class={`o-switch ${!props.label?.length ? "o-switch--standalone" : ""}`}>
            <input
                class="o-switch__native"
                type="checkbox"
                checked={props.checked}
                onChange={onChange}
                {...propsRest}
            />
            <div class="o-switch__indicator" />
            <Show when={props.label?.length}>
                <div class="o-switch__label">{props.label}</div>
            </Show>
        </label>
    );
};
