import { either as E } from "fp-ts";
import * as io from "io-ts";
import ky from "ky";
import { createContext } from "solid-js";

export const StaticConfigC = io.partial({
    autoConnect: io.boolean,
    lockedSocket: io.boolean,
    socket: io.string,
    backend: io.string,
    nickname: io.string,
    timeout: io.number,
    serverPassword: io.string,
    hashRouting: io.boolean,
    noAttachments: io.boolean,
});

export type StaticConfig = io.TypeOf<typeof StaticConfigC>;

export const staticDefaults: Required<StaticConfig> = {
    autoConnect: true,
    lockedSocket: false,
    socket: "",
    backend: "",
    nickname: "curiousfox",
    timeout: 30000,
    serverPassword: "",
    hashRouting: true,
    noAttachments: false,
};

export const staticConfig = (async () => {
    const res = await ky
        .get("./config.json")
        .json()
        .catch(() => ({}));
    const dec = StaticConfigC.decode(res);
    const config = E.isRight(dec) ? dec.right : {};

    return { ...staticDefaults, ...config };
})();

export const StaticConfig = createContext<StaticConfig>(staticDefaults);
