import { useIntl } from "@cookbook/solid-intl";
import { Component, onMount, useContext } from "solid-js";
import { createStore } from "solid-js/store";

import { FormItem } from "@/iro/layouts/Form";
import { Checkbox } from "@/iro/objects/Checkbox";
import { PageDialog } from "@/iro/objects/PageDialog";
import { Select } from "@/iro/objects/Select";
import {
    AttachmentDetectionMode,
    MessageListLayout,
    TypingIndicatorMode,
    UnreadBadgeStyle,
    UserConfig,
} from "@/userConfig";

import { Icon } from "../objects/Icon";

export const ClientSettingsDialog: Component<{
    onClose?: (trigger?: string) => void;
}> = (props) => {
    const intl = useIntl();

    const [userConfig, setUserConfig] = useContext(UserConfig);

    const [form, setForm] = createStore({ ...userConfig });

    const handleClose = async (result?: string) => {
        if (result === "ok") {
            setUserConfig(form);
        }

        props.onClose?.(result);
    };

    const setTextFieldHandler = (
        key: keyof typeof form,
        e: InputEvent & { currentTarget: HTMLInputElement | HTMLSelectElement },
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
    ) => setForm(key, e.currentTarget.value as any);

    const setCheckFieldHandler = (key: keyof typeof form, e: InputEvent & { currentTarget: HTMLInputElement }) =>
        setForm(key, e.currentTarget.checked);

    const localeHandler = (e: InputEvent & { currentTarget: HTMLInputElement | HTMLSelectElement }) =>
        setForm("locale", !e.currentTarget.value ? undefined : e.currentTarget.value);

    onMount(() => {
        setForm(userConfig);
    });

    return (
        <PageDialog
            title="Client settings"
            onClose={handleClose}
            okLabel={intl.formatMessage({
                id: "dialog.clientsettings.ok",
                defaultMessage: "Apply",
            })}
            scrollable
        >
            <PageDialog.Category>
                <PageDialog.Page id="general" title="General" icon={<Icon id="user" />}>
                    <div class="l-form">
                        <FormItem
                            label={intl.formatMessage({
                                id: "dialog.clientsettings.general.locale.label",
                                defaultMessage: "Language",
                            })}
                        >
                            <Select
                                options={[
                                    {
                                        value: "",
                                        label: "System default",
                                    },
                                    {
                                        value: "en",
                                        label: "English",
                                    },
                                ]}
                                value={form.locale ?? ""}
                                onInput={localeHandler}
                            />
                        </FormItem>
                    </div>
                </PageDialog.Page>

                <PageDialog.Page id="chatlist" title="Chat list" icon={<Icon id="sidebar" />}>
                    <div class="l-form">
                        <FormItem
                            label={intl.formatMessage({
                                id: "dialog.clientsettings.chatlist.unreadstyle.label",
                                defaultMessage: "Unread badge style",
                            })}
                        >
                            <Select
                                options={[
                                    {
                                        value: UnreadBadgeStyle.Numbered,
                                        label: intl.formatMessage({
                                            id: "dialog.clientsettings.chatlist.unreadstyle.numbered",
                                            defaultMessage: "Numbered",
                                        }),
                                    },
                                    {
                                        value: UnreadBadgeStyle.Dot,
                                        label: intl.formatMessage({
                                            id: "dialog.clientsettings.chatlist.unreadstyle.dot",
                                            defaultMessage: "Dot",
                                        }),
                                    },
                                    {
                                        value: UnreadBadgeStyle.None,
                                        label: intl.formatMessage({
                                            id: "dialog.clientsettings.chatlist.unreadstyle.none",
                                            defaultMessage: "None",
                                        }),
                                    },
                                ]}
                                value={form.unreadBadge}
                                onInput={[setTextFieldHandler, "unreadBadge"]}
                            />
                        </FormItem>

                        <div>
                            <FormItem>
                                <Checkbox
                                    label={intl.formatMessage({
                                        id: "dialog.clientsettings.chatlist.mentionbadge.label",
                                        defaultMessage: "Mention badge",
                                    })}
                                    checked={form.mentionBadge}
                                    onInput={[setCheckFieldHandler, "mentionBadge"]}
                                />
                            </FormItem>

                            <FormItem>
                                <Checkbox
                                    label={intl.formatMessage({
                                        id: "dialog.clientsettings.chatlist.messagepreviews.label",
                                        defaultMessage: "Message preview",
                                    })}
                                    checked={form.messagePreviews}
                                    onInput={[setCheckFieldHandler, "messagePreviews"]}
                                />
                            </FormItem>

                            <FormItem>
                                <Checkbox
                                    label={intl.formatMessage({
                                        id: "dialog.clientsettings.chatlist.messagepreviews.label",
                                        defaultMessage: "Last message date",
                                    })}
                                    checked={form.lastMessageDates}
                                    onInput={[setCheckFieldHandler, "lastMessageDates"]}
                                />
                            </FormItem>
                        </div>
                    </div>
                </PageDialog.Page>

                <PageDialog.Page id="notifications" title="Notifications" icon={<Icon id="bell" />}>
                    <div class="l-form">
                        <FormItem>
                            <Checkbox
                                label={intl.formatMessage({
                                    id: "dialog.clientsettings.notifications.autoread.label",
                                    defaultMessage: "Automatically mark notifications as read",
                                })}
                                checked={form.autoReadNotifications}
                                onInput={[setCheckFieldHandler, "autoReadNotifications"]}
                            />
                        </FormItem>
                    </div>
                </PageDialog.Page>

                <PageDialog.Page id="chats" title="Chat" icon={<Icon id="messages" />}>
                    <div class="l-form">
                        <FormItem
                            label={intl.formatMessage({
                                id: "dialog.clientsettings.chats.layout.label",
                                defaultMessage: "Layout",
                            })}
                        >
                            <Select
                                options={[
                                    {
                                        value: MessageListLayout.Bubbles,
                                        label: intl.formatMessage({
                                            id: "dialog.clientsettings.chats.layout.bubbles",
                                            defaultMessage: "Bubbles",
                                        }),
                                    },
                                    {
                                        value: MessageListLayout.Linear,
                                        label: intl.formatMessage({
                                            id: "dialog.clientsettings.chats.layout.linear",
                                            defaultMessage: "Linear",
                                        }),
                                    },
                                ]}
                                value={form.messageListLayout}
                                onInput={[setTextFieldHandler, "messageListLayout"]}
                            />
                        </FormItem>

                        <FormItem
                            label={intl.formatMessage({
                                id: "dialog.clientsettings.chats.attachments.label",
                                defaultMessage: "Attachment detection",
                            })}
                        >
                            <Select
                                options={[
                                    {
                                        value: AttachmentDetectionMode.Automatic,
                                        label: intl.formatMessage({
                                            id: "dialog.clientsettings.chats.attachments.auto",
                                            defaultMessage: "Automatic",
                                        }),
                                    },
                                    {
                                        value: AttachmentDetectionMode.OnDemand,
                                        label: intl.formatMessage({
                                            id: "dialog.clientsettings.chats.attachments.ondemand",
                                            defaultMessage: "On demand",
                                        }),
                                    },
                                    {
                                        value: AttachmentDetectionMode.Disabled,
                                        label: intl.formatMessage({
                                            id: "dialog.clientsettings.chats.attachments.disabled",
                                            defaultMessage: "Disabled",
                                        }),
                                    },
                                ]}
                                value={form.attachmentDetection}
                                onInput={[setTextFieldHandler, "attachmentDetection"]}
                            />
                        </FormItem>

                        <FormItem
                            label={intl.formatMessage({
                                id: "dialog.clientsettings.chats.typing.label",
                                defaultMessage: "Typing indicator mode",
                            })}
                        >
                            <Select
                                options={[
                                    {
                                        value: TypingIndicatorMode.ViewAndSend,
                                        label: intl.formatMessage({
                                            id: "dialog.clientsettings.chats.typing.viewsend",
                                            defaultMessage: "View and send",
                                        }),
                                    },
                                    {
                                        value: TypingIndicatorMode.View,
                                        label: intl.formatMessage({
                                            id: "dialog.clientsettings.chats.typing.view",
                                            defaultMessage: "View",
                                        }),
                                    },
                                    {
                                        value: TypingIndicatorMode.Disabled,
                                        label: intl.formatMessage({
                                            id: "dialog.clientsettings.chats.typing.disabled",
                                            defaultMessage: "Disabled",
                                        }),
                                    },
                                ]}
                                value={form.typingIndicator}
                                onInput={[setTextFieldHandler, "typingIndicator"]}
                            />
                        </FormItem>
                    </div>
                </PageDialog.Page>
            </PageDialog.Category>
        </PageDialog>
    );
};
