import { useIntl } from "@cookbook/solid-intl";
import { Component, createEffect, createResource, createSignal } from "solid-js";

import { Form, FormItem } from "@/iro/layouts/Form";
import { Button } from "@/iro/objects/Button";
import { Checkbox } from "@/iro/objects/Checkbox";
import { Divider } from "@/iro/objects/Divider";
import { H1 } from "@/iro/objects/Heading";
import { TextField } from "@/iro/objects/TextField";
import { staticConfig } from "@/staticConfig";
import { NetworkConfig, networkList } from "@/store/networkList";

export const SetupView: Component = () => {
    const intl = useIntl();

    const [config] = createResource(() => staticConfig);

    const [server, setServer] = createSignal("");
    const [nickname, setNickname] = createSignal("");
    const [password, setPassword] = createSignal("");
    const [autoConnect, setAutoConnect] = createSignal(true);

    const [username, setUsername] = createSignal("");
    const [realname, setRealname] = createSignal("");
    const [serverPassword, setServerPassword] = createSignal("");

    const setTextFieldHandler = (
        setter: (value: string) => void,
        e: InputEvent & { currentTarget: HTMLInputElement },
    ) => setter(e.currentTarget.value);

    const setCheckFieldHandler = (
        setter: (value: boolean) => void,
        e: InputEvent & { currentTarget: HTMLInputElement },
    ) => setter(e.currentTarget.checked);

    const submitHandler = (e: Event) => {
        e.preventDefault();

        localStorage.setItem(
            "kitsune-irc:config",
            JSON.stringify({
                socket: server(),
                nickname: nickname(),
                autoConnect: autoConnect(),
                password: password(),
                username: username(),
                realname: realname(),
                serverPassword: serverPassword(),
            } satisfies NetworkConfig),
        );

        networkList.loadConfig();
    };

    createEffect(() => {
        const c = config();

        if (!c) {
            return;
        }

        if (c.socket) {
            setServer(c.socket);
        }
        if (c.nickname) {
            setNickname(`${c.nickname}${Math.floor(Math.random() * 100)}`);
        }
        if (c.serverPassword) {
            setServerPassword(c.serverPassword);
        }
    });

    return (
        <div class="l-fullscreen">
            <div class="l-container l-container--narrow-75 u-p-700">
                <H1 level="xl" class="u-mt-0">
                    Kitsune IRC
                </H1>

                <Divider variant="medium" />

                <Form labelPosition="left" onSubmit={submitHandler} class="u-mt-400">
                    <FormItem
                        label={intl.formatMessage({ id: "view.setup.server.label", defaultMessage: "WebSocket *" })}
                        hint={intl.formatMessage({
                            id: "view.setup.server.hint",
                            defaultMessage: "e.g. wss://domain.example/socket",
                        })}
                        disabled={config()?.lockedSocket}
                    >
                        <TextField
                            value={server()}
                            onInput={[setTextFieldHandler, setServer]}
                            required
                            disabled={config()?.lockedSocket}
                        />
                    </FormItem>

                    <FormItem
                        label={intl.formatMessage({ id: "view.setup.nickname.label", defaultMessage: "Nickname *" })}
                    >
                        <TextField value={nickname()} onInput={[setTextFieldHandler, setNickname]} required />
                    </FormItem>

                    <FormItem
                        label={intl.formatMessage({ id: "view.setup.password.label", defaultMessage: "Password" })}
                    >
                        <TextField type="password" value={password()} onInput={[setTextFieldHandler, setPassword]} />
                    </FormItem>

                    <FormItem>
                        <Checkbox
                            label={intl.formatMessage({
                                id: "view.setup.autoconnect.label",
                                defaultMessage: "Auto-connect",
                            })}
                            checked={autoConnect()}
                            onInput={[setCheckFieldHandler, setAutoConnect]}
                        />
                    </FormItem>

                    <details style={{ "grid-column": "1 / -1" }}>
                        <summary>Advanced</summary>

                        <div class="l-form l-form--labels-left">
                            <FormItem
                                label={intl.formatMessage({
                                    id: "view.setup.username.label",
                                    defaultMessage: "Username",
                                })}
                            >
                                <TextField value={username()} onInput={[setTextFieldHandler, setUsername]} />
                            </FormItem>

                            <FormItem
                                label={intl.formatMessage({
                                    id: "view.setup.realname.label",
                                    defaultMessage: "Real Name",
                                })}
                            >
                                <TextField value={realname()} onInput={[setTextFieldHandler, setRealname]} />
                            </FormItem>

                            <FormItem
                                label={intl.formatMessage({
                                    id: "view.setup.serverpassword.label",
                                    defaultMessage: "Server Password",
                                })}
                                disabled={!!config()?.serverPassword}
                            >
                                <TextField
                                    type="password"
                                    value={serverPassword()}
                                    onInput={[setTextFieldHandler, setServerPassword]}
                                    disabled={!!config()?.serverPassword}
                                />
                            </FormItem>
                        </div>
                    </details>

                    <FormItem>
                        <Button variant="primary">
                            {intl.formatMessage({ id: "view.setup.connect", defaultMessage: "Connect" })}
                        </Button>
                    </FormItem>
                </Form>
            </div>
        </div>
    );
};
