import { Component, ComponentProps, JSX, mergeProps, splitProps } from "solid-js";

import { Avatar } from "../objects/Avatar";
import { Message } from "../objects/Message";

export const MessageGroupAvatar: Component<{ sticky?: boolean } & ComponentProps<typeof Avatar>> = (_props) => {
    const propsWithDefaults = mergeProps({ class: "" }, _props);
    const [props, propsRest] = splitProps(propsWithDefaults, ["sticky", "class"]);

    return (
        <Avatar
            class={`l-message-group__avatar ${props.sticky ? "u-p-sticky-top" : ""} ${props.class}`}
            {...propsRest}
        />
    );
};

export const MessageGroupMessage: Component<ComponentProps<typeof Message>> = (_props) => {
    const propsWithDefaults = mergeProps({ class: "" }, _props);
    const [props, propsRest] = splitProps(propsWithDefaults, ["class"]);

    return <Message class={`l-message-group__message ${props.class}`} {...propsRest} />;
};

export const MessageGroup: Component<
    {
        compact?: boolean;
        dir?: "left" | "right";
        arrow?: boolean;
        noAvatar?: boolean;
        merge?: boolean;
    } & Omit<JSX.HTMLAttributes<HTMLElement>, "dir">
> = (_props) => {
    const propsWithDefaults = mergeProps({ class: "" }, _props);
    const [props, propsRest] = splitProps(propsWithDefaults, ["compact", "arrow", "merge", "noAvatar", "dir", "class"]);

    return (
        <section
            class={`l-message-group ${props.compact ? "l-message-group--compact" : ""} ${
                props.merge ? "l-message-group--merge" : ""
            } ${props.arrow ? "l-message-group--arrow" : ""} ${props.class} ${
                props.dir ? `l-message-group--${props.dir}` : ""
            } ${props.noAvatar ? "l-message-group--no-avatar" : ""}`}
            {...propsRest}
        />
    );
};
