import { Exome } from "exome";
import { either as E, function as F, json as J, map as M, ord as Or, string as S } from "fp-ts";
import * as io from "io-ts";

import { CaseMappedMap } from "@/lib/caseMappedMap";
import { CaseMapping } from "@/lib/irc/caseMapping";

import { Buffer, ROOT_BUFFER } from "./buffer";
import { ChannelBuffer } from "./buffer/channelBuffer";
import { UserBuffer } from "./buffer/userBuffer";
import { Network } from "./network";

const BUFFER_ORDER = [ROOT_BUFFER];

export const BufferOrd: Or.Ord<Buffer> = Or.fromCompare((a, b) => {
    const i = -1 * BUFFER_ORDER.indexOf(a.id);
    const j = -1 * BUFFER_ORDER.indexOf(b.id);

    return i === j ? S.Ord.compare(a.id, b.id) : i < j ? -1 : 1;
});

export class BufferList extends Exome {
    buffers: CaseMappedMap<Buffer>;

    constructor(
        readonly network: Network,
        private caseMapping: CaseMapping,
    ) {
        super();

        this.buffers = new CaseMappedMap(caseMapping);
    }

    get sorted() {
        return F.pipe(this.buffers, M.values(BufferOrd));
    }

    updateCaseMapping(caseMapping: CaseMapping) {
        this.caseMapping = caseMapping;
        this.buffers = new CaseMappedMap(caseMapping, this.buffers);

        for (const buffer of this.buffers.values()) {
            if (buffer instanceof ChannelBuffer) {
                buffer.members.updateCaseMapping(caseMapping);
            }
        }
    }

    private execDelete(id: string): Buffer | undefined {
        const buffer = this.buffers.get(id);

        if (buffer) {
            this.buffers.delete(id);
            this.save();
        }
        if (buffer instanceof UserBuffer) {
            this.network.client.unmonitor(id);
        }

        return buffer;
    }

    private execMove(oldId: string, newId: string) {
        const buffer = this.buffers.get(oldId);

        if (buffer) {
            this.buffers.delete(oldId);
            buffer.setId(newId);
            this.buffers.set(newId, buffer);
        }

        return buffer;
    }

    private save = () => {
        const saveBuffers = Array.from(this.buffers.values())
            .filter((buffer) => buffer instanceof UserBuffer)
            .map((buffer) => buffer.id);

        localStorage.setItem(`kitsune-irc:network:${this.network.id}:buffers`, JSON.stringify(saveBuffers));
    };

    load = () => {
        const buffers = F.pipe(
            localStorage.getItem(`kitsune-irc:network:${this.network.id}:buffers`) ?? "",
            J.parse,
            E.chainW(io.array(io.string).decode),
        );

        if (E.isRight(buffers)) {
            for (const buffer of buffers.right) {
                this.upsert(buffer);
            }
        }
    };

    get = (id: string) => this.buffers.get(id);

    has = (id: string) => this.buffers.has(id);

    create(id: string) {
        const buffer = this.network.client.isChannel(id)
            ? new ChannelBuffer(id, this.network, this.caseMapping)
            : new UserBuffer(id, this.network, this.network.users.upsert(id));

        this.buffers.set(id, buffer);
        this.save();

        if (buffer instanceof UserBuffer) {
            this.network.client.monitor(id);
        }

        return buffer;
    }

    upsert = (id: string) => this.buffers.get(id) ?? this.create(id);

    delete = (id: string) => {
        if (this.buffers.has(id)) {
            return this.execDelete(id);
        }
    };

    move = (oldId: string, newId: string) => {
        if (this.buffers.has(oldId)) {
            return this.execMove(oldId, newId);
        }
    };

    moveMember = (oldNickname: string, newNickname: string) => {
        for (const buffer of this.buffers.values()) {
            if (buffer instanceof ChannelBuffer) {
                buffer.moveMember(oldNickname, newNickname);
            }
        }
    };

    deleteMember = (nickname: string) => {
        for (const buffer of this.buffers.values()) {
            if (buffer instanceof ChannelBuffer) {
                buffer.deleteMember(nickname);
            }
        }
    };

    clear() {
        this.buffers.clear();
    }
}
