import { Component, JSX, Show, useContext } from "solid-js";
import { Dynamic } from "solid-js/web";

import { ActionButton } from "@/iro/objects/ActionButton";
import { Divider } from "@/iro/objects/Divider";
import { Linkified } from "@/iro/objects/Linkified";
import { Message, MessageHeader } from "@/iro/objects/Message";
import { TextArea } from "@/iro/objects/TextArea";
import { useStore } from "@/lib/exome/solid";
import { Typing } from "@/lib/irc/typing";
import { Buffer } from "@/store/buffer";
import { TypingIndicatorMode, UserConfig } from "@/userConfig";

import { Footer } from "../layouts/Footer";
import { Icon } from "../objects/Icon";
import { DynamicMention } from "../objects/Mention";

export const Compose: Component<{ buffer: Buffer }> = (props) => {
    let el!: HTMLTextAreaElement;
    let pressedEnter = false;

    const [userConfig] = useContext(UserConfig);

    const buffer = useStore(() => props.buffer);
    const draft = useStore(() => buffer().draft);
    const messages = useStore(() => buffer().messages);

    const replyingTo = () => {
        const replyTo = draft().replyTo;
        return replyTo ? messages().messagesById.get(replyTo) : undefined;
    };

    const send = () => buffer().send();

    const insert = (str: string) => {
        el.focus();
        const selStart = el.selectionStart;
        draft().update((text) => text.slice(0, selStart) + str + text.slice(el.selectionEnd));
        el.selectionStart = el.selectionEnd = selStart + str.length;
    };

    const handleClearReply = () => draft().setReplyTo();

    const handleInput: JSX.EventHandler<HTMLTextAreaElement, Event> = (e) => {
        if (userConfig.typingIndicator === TypingIndicatorMode.ViewAndSend) {
            buffer().network.typing(buffer().id, Typing.Active);
        }

        if (!pressedEnter) {
            draft().update(e.currentTarget.value);
        } else {
            pressedEnter = false;
        }
    };

    const handleKeyPress = (e: KeyboardEvent) => {
        const pe = e.code === "Enter" && !e.shiftKey;

        if (pe) {
            e.preventDefault();
            send();
        }

        pressedEnter = pe;
    };

    const handlePaste = () => {
        pressedEnter = false;
    };

    return (
        <Footer class="u-pt-0">
            <TextArea
                ref={el}
                value={draft().current}
                enterkeyhint="send"
                onInput={handleInput}
                onKeyPress={handleKeyPress}
                onPaste={handlePaste}
                top={
                    <Show when={replyingTo()}>
                        {(replyingTo) => (
                            <>
                                <Message size="75" class="l-media l-media--flush l-media--gapless">
                                    <div class="l-media__block l-media__block--main">
                                        <MessageHeader>
                                            <small>
                                                In reply to <DynamicMention source={replyingTo().value.source} />
                                            </small>
                                        </MessageHeader>
                                        <Dynamic
                                            component={replyingTo().value.action ? "em" : "span"}
                                            class="s-links s-links--colored"
                                        >
                                            <Linkified content={replyingTo().value.content} />
                                        </Dynamic>
                                    </div>
                                    <div class="l-media__block">
                                        <ActionButton quiet onClick={handleClearReply}>
                                            <Icon id="x" block />
                                        </ActionButton>
                                    </div>
                                </Message>
                                <Divider variant="faint" class="u-mt-0 u-mb-0" />
                            </>
                        )}
                    </Show>
                }
                after={
                    <div class="l-media__block">
                        <ActionButton quiet onClick={send} disabled={!draft().current} title="Send">
                            <Icon id="send" block />
                        </ActionButton>
                    </div>
                }
            />
        </Footer>
    );
};
