import { Exome } from "exome";
import { subscribe } from "exome";
import { Accessor, createMemo, createSignal, onCleanup } from "solid-js";

export function useStore<T extends Exome | undefined>(
    getStore: Accessor<T>,
    options?: { name?: string },
): Accessor<Readonly<T>> {
    const [state, setState] = createSignal(getStore(), { equals: false, name: options?.name ?? "Exome Store" });
    const unsubscribe = createMemo<() => void>(
        (unsubscribe) => {
            unsubscribe();
            const store = getStore();
            setState(() => store);
            return store ? subscribe(store, setState) : () => void 0;
        },
        () => void 0,
        { name: `Sub: ${options?.name ?? "Exome Store"}` },
    );

    onCleanup(() => unsubscribe()());

    return state;
}

export function useStores<T extends Exome>(
    getStores: Accessor<T[]>,
    options?: { name?: string },
): Accessor<ReadonlyArray<Readonly<T>>> {
    const [state, setState] = createSignal(getStores(), { equals: false, name: options?.name ?? "Exome Stores" });
    const unsubscribe = createMemo<() => void>(
        (unsubscribe) => {
            unsubscribe();
            const stores = getStores();
            setState(() => stores);
            const unsubscribes = stores.map((s, i) =>
                subscribe(s, (store) =>
                    setState((state) => {
                        state.splice(i, 1, store);
                        return state;
                    }),
                ),
            );
            return () => unsubscribes.forEach((unsubscribe) => unsubscribe());
        },
        () => void 0,
        { name: `Sub: ${options?.name ?? "Exome Stores"}` },
    );

    onCleanup(() => unsubscribe()());

    return state;
}
