import { useIntl } from "@cookbook/solid-intl";
import { differenceInMinutes, isAfter, isSameDay } from "date-fns";
import { getExomeId } from "exome";
import { option as O } from "fp-ts";
import {
    Component,
    createEffect,
    createMemo,
    createResource,
    createSignal,
    For,
    JSX,
    Match,
    on,
    onMount,
    Show,
    Switch,
    useContext,
} from "solid-js";
import { Dynamic } from "solid-js/web";
import { P } from "ts-pattern";

import { useOnDemand } from "@/iro/hooks/onDemand";
import { usePersistentScroll } from "@/iro/hooks/persistentScroll";
import { useScrollTrigger } from "@/iro/hooks/scrollTriggers";
import { MessageGroup, MessageGroupMessage } from "@/iro/layouts/MessageGroup";
import { Badge } from "@/iro/objects/Badge";
import { Divider } from "@/iro/objects/Divider";
import { Linkified } from "@/iro/objects/Linkified";
import { Message as IroMessage, MessageHeader } from "@/iro/objects/Message";
import { Time } from "@/iro/objects/Time";
import { useStore } from "@/lib/exome/solid";
import * as linkExtractor from "@/lib/linkExtractor";
import { isMatchingN } from "@/lib/ts-pattern/util";
import { Narrow } from "@/lib/unionTypes";
import { StaticConfig } from "@/staticConfig";
import { Buffer as BufferStore } from "@/store/buffer";
import { MessageList as MessageListStore, RenderableMessage, TrafficLeave } from "@/store/messageList";
import { AttachmentDetectionMode, MessageListLayout, UserConfig } from "@/userConfig";

import { AttachmentList } from "../layouts/AttachmentList";
import { MessageMenu } from "../menus/Message";
import { Icon } from "../objects/Icon";
import { DynamicMention, StaticMention } from "../objects/Mention";
import { UserAvatar } from "../objects/UserAvatar";

const groups: Record<RenderableMessage["type"], number> = {
    Info: 0,
    Motd: 0,
    Message: 1,
    Traffic: 2,
    Nick: 2,
    Mode: 2,
    Topic: 2,
    Reply: 3,
};

const NoteMessage: Component<{ message: Narrow<RenderableMessage, "Motd" | "Info">; grouped?: boolean }> = (props) => (
    <MessageGroup
        compact
        arrow
        dir="left"
        noAvatar
        class={`l-message-list__item l-message-list__item--note ${props.grouped ? "l-message-list__item--group" : ""}`}
    >
        <MessageGroupMessage size="75" theme="up" data-msgkey={props.message.value.hash}>
            <Switch>
                <Match when={isMatchingN(RenderableMessage.Motd.select(), props.message)}>
                    {(message) => (
                        <>
                            <MessageHeader>
                                <strong>Message of the Day</strong>
                            </MessageHeader>
                            <pre class="s-links s-links--colored">
                                <Linkified content={message().value.content} />
                            </pre>
                        </>
                    )}
                </Match>
                <Match when={isMatchingN(RenderableMessage.Info.select(), props.message)}>
                    {(message) => (
                        <>
                            <MessageHeader>
                                <strong>Info</strong>
                            </MessageHeader>
                            <pre>{message().value.content}</pre>
                        </>
                    )}
                </Match>
            </Switch>
            <small class="o-message__suffix">
                <Time date={props.message.value.time} format={{ hour: "2-digit", minute: "2-digit" }} />
            </small>
        </MessageGroupMessage>
    </MessageGroup>
);

const ReplyMessage: Component<{ message: Narrow<RenderableMessage, "Reply">; grouped?: boolean }> = (props) => (
    <div
        class={`o-event-message l-message-list__item l-message-list__item--reply ${
            props.grouped ? "l-message-list__item--group" : ""
        }`}
        data-msgkey={props.message.value.hash}
    >
        <small class="o-event-message__time">
            <Time date={props.message.value.time} format={{ hour: "2-digit", minute: "2-digit" }} />
        </small>
        <small class="o-event-message__icon">
            <Badge pad="200">{props.message.value.code.toString().padStart(3, "0")}</Badge>
        </small>
        <pre class="o-event-message__content" style={{ "white-space": "pre-wrap" }}>
            {props.message.value.params.join(", ")}
        </pre>
    </div>
);

const NickEventMessage: Component<{ message: Narrow<RenderableMessage, "Nick"> }> = (props) => {
    const intl = useIntl();

    const changes = createMemo(() => {
        const changes = props.message.value.changes.map((change) =>
            intl.formatMessage(
                {
                    id: "message.nick.one",
                    defaultMessage: `{user} is now known as {nickname}`,
                },
                {
                    user: <DynamicMention source={change.source} />,
                    nickname: <StaticMention>{change.nickname}</StaticMention>,
                },
            ),
        );

        const changesLast = changes.pop();

        return { changes, changesLast };
    });

    const merge = (r: JSX.Element, el: JSX.Element) =>
        r ? (
            <>
                {r}, {el}
            </>
        ) : (
            el
        );

    return (
        <>
            {intl.formatMessage(
                {
                    id: "message.nick",
                    defaultMessage: `{changesCount, plural,
                =0 {{last}}
                other {{changes}, and {last}}
            }`,
                },
                {
                    changesCount: changes().changes.length,
                    changes: changes().changes.reduce(merge, undefined),
                    last: changes().changesLast,
                },
            )}
        </>
    );
};

const TrafficEventMessage: Component<{ message: Narrow<RenderableMessage, "Traffic"> }> = (props) => {
    const intl = useIntl();

    const groups = createMemo(() => {
        const join: JSX.Element[] = [];
        const part: JSX.Element[] = [];
        const quit: JSX.Element[] = [];

        for (const item of Object.values(props.message.value.traffic)) {
            if (item.join) {
                join.push(<DynamicMention source={item.source} />);
            }
            if (item.leave === TrafficLeave.Part) {
                part.push(<DynamicMention source={item.source} />);
            }
            if (item.leave === TrafficLeave.Quit) {
                quit.push(<DynamicMention source={item.source} />);
            }
        }

        const joinLast = join.pop();
        const partLast = part.pop();
        const quitLast = quit.pop();

        return {
            join,
            joinLast,
            part,
            partLast,
            quit,
            quitLast,
        };
    });

    const merge = (r: JSX.Element, el: JSX.Element) =>
        r ? (
            <>
                {r}, {el}
            </>
        ) : (
            el
        );

    return (
        <>
            <Show when={groups().join.length || groups().joinLast}>
                {intl.formatMessage(
                    {
                        id: "message.traffic.join",
                        defaultMessage: `{userCount, plural,
                            =0 {{last} has joined}
                            other {{users} and {last} have joined}
                        }`,
                    },
                    {
                        userCount: groups().join.length,
                        users: groups().join.reduce(merge, undefined),
                        last: groups().joinLast,
                    },
                )}
                <Show when={groups().part.length || groups().partLast || groups().quit.length || groups().quitLast}>
                    ;{" "}
                </Show>
            </Show>
            <Show when={groups().part.length || groups().partLast}>
                {intl.formatMessage(
                    {
                        id: "message.traffic.join",
                        defaultMessage: `{userCount, plural,
                            =0 {{last} has left}
                            other {{users} and {last} have left}
                        }`,
                    },
                    {
                        userCount: groups().part.length,
                        users: groups().part.reduce(merge, undefined),
                        last: groups().partLast,
                    },
                )}
                <Show when={groups().quit.length || groups().quitLast}>; </Show>
            </Show>
            <Show when={groups().quit.length || groups().quitLast}>
                {intl.formatMessage(
                    {
                        id: "message.traffic.join",
                        defaultMessage: `{userCount, plural,
                            =0 {{last} has quit}
                            other {{users} and {last} have quit}
                        }`,
                    },
                    {
                        userCount: groups().quit.length,
                        users: groups().quit.reduce(merge, undefined),
                        last: groups().quitLast,
                    },
                )}
            </Show>
        </>
    );
};

const ModeEventMessage: Component<{ message: Narrow<RenderableMessage, "Mode"> }> = (props) => (
    <>
        <DynamicMention source={props.message.value.source} /> set the following modes:{" "}
        {props.message.value.changes.map((change) => (
            <>
                <Badge>
                    {change.add ? "+" : "-"}
                    {change.mode}
                    <Show when={change.param}> {change.param}</Show>
                </Badge>{" "}
            </>
        ))}
    </>
);

const EventMessage: Component<{
    message: Narrow<RenderableMessage, "Traffic" | "Nick" | "Topic" | "Mode">;
    grouped?: boolean;
}> = (props) => {
    const until = () => ("until" in props.message.value ? props.message.value.until : undefined);

    return (
        <div
            class={`o-event-message l-message-list__item l-message-list__item--event ${
                props.grouped ? "l-message-list__item--group" : ""
            }`}
            data-msgkey={props.message.value.hash}
        >
            <small class="o-event-message__time">
                <Time date={props.message.value.time} format={{ hour: "2-digit", minute: "2-digit" }} />
                {/*<Show when={until()}>
                    {(until) => (
                        <>
                            {" "}
                            — <Time date={until()} format={{ hour: "2-digit", minute: "2-digit" }} />
                        </>
                    )}
                </Show>*/}
            </small>
            <div class="o-event-message__icon">
                <Switch>
                    <Match when={isMatchingN(RenderableMessage.Traffic.select(), props.message)}>
                        <Icon id="exchange-h" />
                    </Match>
                    <Match when={isMatchingN(RenderableMessage.Nick.select(), props.message)}>
                        <Icon id="user" />
                    </Match>
                    <Match when={isMatchingN(RenderableMessage.Topic.select(), props.message)}>
                        <Icon id="text" />
                    </Match>
                    <Match when={isMatchingN(RenderableMessage.Mode.select(), props.message)}>
                        <Icon id="sliders" />
                    </Match>
                </Switch>
            </div>
            <small class="o-event-message__content">
                <Switch>
                    <Match when={isMatchingN(RenderableMessage.Traffic.select(), props.message)}>
                        {(message) => <TrafficEventMessage message={message()} />}
                    </Match>
                    <Match when={isMatchingN(RenderableMessage.Nick.select(), props.message)}>
                        {(message) => <NickEventMessage message={message()} />}
                    </Match>
                    <Match when={isMatchingN(RenderableMessage.Topic.select(), props.message)}>
                        {(message) => (
                            <>
                                <DynamicMention source={message().value.source} /> set a new topic:{" "}
                                <em class="s-links s-links--colored">
                                    <Linkified content={[message().value.topic]} />
                                </em>
                            </>
                        )}
                    </Match>
                    <Match when={isMatchingN(RenderableMessage.Mode.select(), props.message)}>
                        {(message) => <ModeEventMessage message={message()} />}
                    </Match>
                </Switch>
            </small>
        </div>
    );
};

const MessageMessage: Component<{
    buffer: BufferStore;
    message: Narrow<RenderableMessage, "Message">;
    grouped?: boolean;
    prev?: RenderableMessage;
}> = (props) => {
    const [onDemand] = useOnDemand();
    const staticConfig = useContext(StaticConfig);
    const [userConfig] = useContext(UserConfig);

    const buffer = useStore(() => props.buffer);
    const messages = useStore(() => buffer().messages);
    const network = useStore(() => buffer().network);
    const user = useStore(() => props.message.value.source.user);

    const isMe = () => user() === network().me;
    const grouped = () =>
        props.grouped &&
        props.prev &&
        RenderableMessage.Message.is(props.prev) &&
        props.message.value.source.user === props.prev.value.source.user;
    const parent = createMemo(() =>
        props.message.value.parentId ? messages().messagesById.get(props.message.value.parentId) : undefined,
    );

    const [showAttachments, setShowAttachments] = createSignal(
        userConfig.attachmentDetection === AttachmentDetectionMode.Automatic,
    );

    const links = createMemo(() =>
        !staticConfig.noAttachments ? props.message.value.links.filter((link) => link.type === "url") : [],
    );

    const [resolvedAttachments] = createResource(
        () => [links(), showAttachments()] as const,
        async ([links, showAttachments]) => {
            if (!showAttachments) {
                return [];
            }

            const resolvedAttachments = await Promise.all(
                links.map(async (link) => {
                    const info = await linkExtractor.get(link.href);

                    if (info?.type && /^(image|video|audio)\//.test(info.type)) {
                        return { src: link.href, type: info.type };
                    }
                }),
            );

            return resolvedAttachments.filter(
                (attachment): attachment is NonNullable<typeof attachment> => !!attachment,
            );
        },
    );

    const attachments = createMemo(() => {
        if (!showAttachments()) {
            return [];
        }

        return (
            resolvedAttachments() ??
            links()
                .map((link) => {
                    const info = linkExtractor.getCached(link.href);

                    if (info?.type && /^(image|video|audio)\//.test(info.type)) {
                        return { src: link.href, type: info.type };
                    }
                })
                .filter((attachment): attachment is NonNullable<typeof attachment> => !!attachment)
        );
    });

    const showAttachmentsHandler = (e: Event) => {
        e.preventDefault();
        setShowAttachments(true);
    };

    const handleContextMenu = (e: MouseEvent) => {
        e.preventDefault();
        onDemand((close) => (
            <MessageMenu
                buffer={props.buffer}
                message={props.message}
                anchor={{ x: e.pageX, y: e.pageY }}
                onClose={close}
            />
        ));
    };

    createEffect(
        on(
            () => userConfig.attachmentDetection,
            (attachmentDetection) =>
                setShowAttachments(
                    (v) =>
                        v ||
                        attachmentDetection === AttachmentDetectionMode.Automatic ||
                        links().every((link) => !!linkExtractor.getCached(link.href)),
                ),
        ),
    );

    return (
        <MessageGroup
            compact
            arrow
            merge={grouped()}
            class={`l-message-list__item l-message-list__item--message ${isMe() ? "l-message-list__item--self" : ""}`}
        >
            <UserAvatar
                source={props.message.value.source}
                class="l-message-group__avatar l-message-list__item-avatar"
                colored
            />

            <MessageGroupMessage
                size="75"
                theme={userConfig.messageListLayout === MessageListLayout.Bubbles ? "up" : undefined}
                highlight={props.message.value.highlight}
                data-msgkey={props.message.value.hash}
                class={`l-message-list__item-message`}
                onContextMenu={handleContextMenu}
            >
                <MessageHeader class="l-message-group__merge-hide l-message-list__item-header u-mb-50">
                    <DynamicMention tag="strong" source={props.message.value.source} />
                    <Show when={userConfig.messageListLayout !== MessageListLayout.Bubbles}>
                        {" "}
                        <small class="u-ml-50">
                            <Time date={props.message.value.time} format={{ hour: "2-digit", minute: "2-digit" }} />
                        </small>
                    </Show>
                </MessageHeader>

                <Show when={parent()}>
                    {(parent) => (
                        <IroMessage bubble size="75" class="u-bt-1 u-br-1 u-bb-1 u-bl-1 u-mb-100">
                            <small class="l-media l-media--flush">
                                <div class="l-media__block l-media__block--main">
                                    <DynamicMention source={parent().value.source} />:{" "}
                                    <Dynamic
                                        component={parent().value.action ? "em" : "span"}
                                        class="s-links s-links--colored"
                                    >
                                        <Linkified content={parent().value.content} />
                                    </Dynamic>
                                </div>
                            </small>
                        </IroMessage>
                    )}
                </Show>

                <Dynamic component={props.message.value.action ? "em" : "span"} class="s-links s-links--colored">
                    <Linkified content={props.message.value.content} />
                </Dynamic>

                <Switch>
                    <Match when={attachments().length}>
                        <AttachmentList attachments={attachments()} class="u-mt-100" />
                    </Match>
                    <Match
                        when={
                            links().length &&
                            !showAttachments() &&
                            userConfig.attachmentDetection === AttachmentDetectionMode.OnDemand
                        }
                    >
                        <br />
                        <small class="u-d-inline-block s-links s-links--invisible">
                            <a href="#" onClick={showAttachmentsHandler}>
                                Load attachments
                            </a>
                        </small>
                    </Match>
                    <Match
                        when={
                            links().length &&
                            showAttachments() &&
                            userConfig.attachmentDetection === AttachmentDetectionMode.OnDemand
                        }
                    >
                        <br />
                        <small class="u-d-inline-block">
                            <em>No attachments found</em>
                        </small>
                    </Match>
                </Switch>

                <Show when={userConfig.messageListLayout === MessageListLayout.Bubbles}>
                    <small class="o-message__suffix">
                        <Time date={props.message.value.time} format={{ hour: "2-digit", minute: "2-digit" }} />
                    </small>
                </Show>
            </MessageGroupMessage>
        </MessageGroup>
    );
};

const Message: Component<{
    buffer: BufferStore;
    message: RenderableMessage;
    prev?: RenderableMessage;
    new?: boolean;
}> = (props) => {
    const newDay = () => !props.prev || !isSameDay(props.message.value.time, props.prev.value.time);

    const grouped = () =>
        !newDay() &&
        !props.new &&
        props.prev &&
        groups[props.prev.type] === groups[props.message.type] &&
        differenceInMinutes(props.message.value.time, props.prev.value.time) < 10;

    return (
        <>
            <Show when={newDay()}>
                <Divider variant="faint" class="l-message-list__item l-message-list__item--divider">
                    <Time date={props.message.value.time} format={{ year: "numeric", month: "long", day: "numeric" }} />
                </Divider>
            </Show>
            <Show when={props.new}>
                <Divider variant="medium" color="red" class="l-message-list__item l-message-list__item--divider">
                    NEW
                </Divider>
            </Show>
            <Switch>
                <Match when={isMatchingN(RenderableMessage.Message.select(), props.message)}>
                    {(message) => (
                        <MessageMessage
                            buffer={props.buffer}
                            message={message()}
                            grouped={grouped()}
                            prev={props.prev}
                        />
                    )}
                </Match>
                <Match
                    when={isMatchingN(
                        P.union(
                            RenderableMessage.Traffic.select(),
                            RenderableMessage.Nick.select(),
                            RenderableMessage.Topic.select(),
                            RenderableMessage.Mode.select(),
                        ),
                        props.message,
                    )}
                >
                    {(message) => <EventMessage message={message()} grouped={grouped()} />}
                </Match>
                <Match when={isMatchingN(RenderableMessage.Reply.select(), props.message)}>
                    {(message) => <ReplyMessage message={message()} grouped={grouped()} />}
                </Match>
                <Match
                    when={isMatchingN(
                        P.union(RenderableMessage.Motd.select(), RenderableMessage.Info.select()),
                        props.message,
                    )}
                >
                    {(message) => <NoteMessage message={message()} grouped={grouped()} />}
                </Match>
            </Switch>
        </>
    );
};

export const MessageList: Component<{ messageList: MessageListStore }> = (props) => {
    let el!: HTMLDivElement;

    const [userConfig] = useContext(UserConfig);

    const messageList = useStore(() => props.messageList);
    const id = () => getExomeId(messageList().buffer);

    const [lastRead, setLastRead] = createSignal<O.Option<Date>>();

    const lastReadIndex = createMemo(() => {
        const lastRead_ = lastRead();

        if (!lastRead_) {
            return;
        }

        if (O.isNone(lastRead_)) {
            return 0;
        }

        return messageList().messages.findIndex((message) => isAfter(message.value.time, lastRead_.value));
    });

    const persistentScroll = usePersistentScroll({
        id,
        selector: ".o-message, .o-event-message",
        hashAttr: "msgkey",
    });

    const [mountScrollTrigger, atTop] = useScrollTrigger("top");

    createEffect(on(atTop, (atTop) => atTop && messageList().historyBefore()));

    createEffect(
        on(messageList, (messageList, prevMessageList) => {
            persistentScroll.restore();

            if (prevMessageList !== messageList || !lastRead()) {
                setLastRead(messageList.lastRead);
            } else {
                const messages = Array.from(messageList.messages.values());
                const lastRead_ = lastRead();

                if (lastRead_) {
                    for (let i = messages.length - 1; i >= 0; --i) {
                        const message = messages[i]!;

                        if (O.isSome(lastRead_) && !isAfter(message.value.time, lastRead_.value)) {
                            break;
                        }

                        if (
                            RenderableMessage.Message.is(message) &&
                            messageList.buffer.network.me === message.value.source.user
                        ) {
                            setLastRead(undefined);
                            break;
                        }
                    }
                }
            }

            messageList.markRead();
        }),
    );

    onMount(() => {
        persistentScroll.mount(el);
        mountScrollTrigger(el);
    });

    return (
        <div class={`l-message-list l-message-list--${userConfig.messageListLayout}`} ref={el}>
            <For each={messageList().messages}>
                {(message, i) => (
                    <Message
                        buffer={messageList().buffer}
                        message={message}
                        prev={messageList().messages[i() - 1]}
                        new={i() === lastReadIndex()}
                    />
                )}
            </For>
        </div>
    );
};
