import { array as A, function as F, option as O } from "fp-ts";
import { isMatching, match, P } from "ts-pattern";

import { CaseMappedMap } from "@/lib/caseMappedMap";
import { Batch, BatchItem } from "@/lib/irc/batch";
import { NetworkAttributes } from "@/lib/irc/bouncer";
import { caseMappedEquals, CaseMapping, rfc1459 } from "@/lib/irc/caseMapping";
import * as Code from "@/lib/irc/codes";
import * as Command from "@/lib/irc/commands";
import { HistoryTarget } from "@/lib/irc/history";
import { ChannelListItem } from "@/lib/irc/list";
import { CapsRecord, Message, parseMessage, Source } from "@/lib/irc/message";
import { ModeListItem } from "@/lib/irc/modes";
import { parseRawMessage, RawMessage, renderRawMessage, Tags } from "@/lib/irc/rawMessage";
import { Whois } from "@/lib/irc/whois";
import { Whox, WHOX_FIELDS } from "@/lib/irc/whox";
import { SaslMechanism } from "@/lib/sasl";
import { ExponentialBackoff } from "@/lib/timer";
import {
    TypedEventTarget,
    WaitFor,
    waitFor,
    WaitForParams,
    WaitForResolvers,
    WaitForResult,
} from "@/lib/typedEventTarget";
import { impl, Narrow, Variant } from "@/lib/unionTypes";
import { WS_NORMAL_CLOSURE, WS_UNSUPPORTED_DATA } from "@/lib/webSocket";

import { Typing } from "../irc/typing";
import { Capability, CapabilityManager } from "./capabilityManager";
import { IrcFailError, IrcFatalError, IrcReplyError } from "./errors";
import { ISupportManager, ISupportToken } from "./isupportManager";
import { TransactionManager } from "./transactionManager";

export const STATIC_CAPS: Capability[] = [
    Capability.AccountNotify,
    Capability.AwayNotify,
    Capability.Batch,
    Capability.Chghost,
    Capability.EchoMessage,
    Capability.ExtendedJoin,
    Capability.InviteNotify,
    Capability.LabelledResponse,
    Capability.MessageTags,
    Capability.MultiPrefix,
    Capability.Sasl,
    Capability.ServerTime,
    Capability.Setname,

    Capability.AccountRegistration,
    Capability.Chathistory,
    Capability.EventPlayback,
    Capability.ExtendedMonitor,
    Capability.ReadMarker,
    Capability.ChannelContext,

    Capability.BouncerNetworks,
];

export enum ModeListType {
    BanList = "BANLIST",
    QuietList = "QUIETLIST",
    InvexList = "INVEXLIST",
    ExceptList = "EXCEPTLIST",
}

export enum ClientStatus {
    Disconnected,
    Connecting,
    Registering,
    Registered,
    Connected,
}

export type ClientEvents = {
    status: ClientStatus;
    error: Error;
    caseMapping: CaseMapping;
    message: Message;
    batch: Batch;
    motd: string[];
    names: { channel: string; members: CaseMappedMap<string> };
    modeList: { type: ModeListType; list: ModeListItem[] };
    list: ChannelListItem[];
    whois: { nickname: string; whois: Whois };
    whowas: { nickname: string; whois: Whois };
    who: { target: string; who: Whox[] };
    help: string[];
    info: string[];
    nickname: string;
};

export type ClientAuthentication =
    | Variant<SaslMechanism.Plain, { username: string; password: string }>
    | Variant<SaslMechanism.External>;

export const ClientAuthentication = impl<ClientAuthentication>();

export interface ClientParams {
    socket: string;
    timeout: number;
    username: string;
    realname: string;
    nickname: string;
    pass: string;
    sasl?: ClientAuthentication;
    bouncerNetwork: string;
}

let messageCounter = 0;

let whoxCounter = 0;

export class Client extends TypedEventTarget<ClientEvents> {
    private _version = "Kitsune IRC";

    private _status = ClientStatus.Disconnected;

    private _caseMapping: CaseMapping = rfc1459;

    private socket?: WebSocket;

    private _nickname = "";

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    private helpEndTimeout?: any;

    private reconnectTimer = new ExponentialBackoff();

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    private reconnectTimeoutId?: any;

    get nickname() {
        return this._nickname;
    }

    set nickname(value: string) {
        this._nickname = value;
        this.dispatch("nickname", value);
    }

    server: Source = {
        account: "",
        nickname: "",
        username: "",
        hostname: "",
    };

    isupport = new ISupportManager();

    caps = new CapabilityManager();

    transactions = new TransactionManager(this.caseMapping);

    supportsCaps = false;

    private batches = new Map<string, Batch>();

    monitored = new CaseMappedMap<boolean>(this.caseMapping);

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    typings = new CaseMappedMap<any>(this.caseMapping);

    params: ClientParams = {
        socket: "",
        timeout: 60000,
        username: "",
        realname: "",
        nickname: "",
        pass: "",
        bouncerNetwork: "",
    };

    pendingCommands = new Map<string, Promise<unknown>>();

    autoReconnect = true;

    get status() {
        return this._status;
    }

    set status(status: ClientStatus) {
        this._status = status;
        this.dispatch("status", status);
    }

    get caseMapping() {
        return this._caseMapping;
    }

    set caseMapping(caseMapping: CaseMapping) {
        if (this._caseMapping !== caseMapping) {
            this._caseMapping = caseMapping;
            this.monitored = new CaseMappedMap(caseMapping, this.monitored);
            this.transactions.setCaseMapping(caseMapping);
            this.typings = new CaseMappedMap(caseMapping, this.typings);

            this.dispatch("caseMapping", caseMapping);
        }
    }

    private dispatchError(err: Error) {
        this.dispatch("error", err);
    }

    isMe(nickname: string) {
        return this.caseMapping(nickname) === this.caseMapping(this.nickname);
    }

    isServer(name: string) {
        return name === "*" || this.caseMapping(name) === this.caseMapping(this.server.nickname);
    }

    isChannel(name: string) {
        return this.isupport[ISupportToken.ChanTypes].includes(name.slice(0, 1));
    }

    parsePrefixed(nickname: string, prefixes: string): { nickname: string; prefix: string } {
        let prefix = "";

        while (nickname.length && prefixes.includes(nickname[0]!)) {
            prefix += nickname[0];
            nickname = nickname.slice(1);
        }

        return { nickname, prefix };
    }

    async connect(params: Partial<ClientParams> & Pick<ClientParams, "socket" | "nickname">) {
        if (this.status !== ClientStatus.Disconnected) {
            this.disconnect();

            await this.waitFor({
                status(status) {
                    return status === ClientStatus.Disconnected && WaitFor.resolve();
                },
            });
        }

        this.autoReconnect = true;

        this.params = {
            ...this.params,
            ...params,
            username: params.username ? params.username : params.nickname,
            realname: params.realname ? params.realname : params.nickname,
        };
        this._nickname = this.params.nickname;
        this.server = {
            account: "",
            nickname: "",
            username: "",
            hostname: "",
        };

        this.status = ClientStatus.Connecting;

        const socket = new WebSocket(this.params.socket);
        this.socket = socket;
        this.socket.addEventListener("open", this.handleOpen.bind(this));
        this.socket.addEventListener("message", this.handleMessage.bind(this));
        this.socket.addEventListener("close", this.handleClose.bind(this));

        await this.waitFor(
            {
                motd() {
                    return WaitFor.resolve();
                },
                status(status) {
                    if (status === ClientStatus.Disconnected) {
                        throw new Error("Disconnected");
                    }
                },
            },
            { timeout: undefined },
        );

        this.status = ClientStatus.Connected;
    }

    disconnect() {
        this.autoReconnect = false;

        if (this.reconnectTimeoutId) {
            clearTimeout(this.reconnectTimeoutId);
            this.reconnectTimeoutId = undefined;
        }

        this.socket?.close(WS_NORMAL_CLOSURE);
    }

    waitFor<T>(
        resolvers: { [E in keyof ClientEvents]?: (this: this, eventData: ClientEvents[E]) => WaitForResult<T> },
        params?: WaitForParams,
    ): Promise<T> {
        return waitFor(this, resolvers as WaitForResolvers<Client, T>, {
            timeout: this.params.timeout,
            ...params,
        });
    }

    prepare(msg: RawMessage): RawMessage {
        const newMsg: RawMessage = { ...msg, params: [...msg.params], tags: { ...msg.tags } };

        if (this.caps.enabled(Capability.LabelledResponse) && !newMsg.tags.label) {
            newMsg.tags.label = `${messageCounter++}`;
        }

        return newMsg;
    }

    send(msg: RawMessage): void;
    send<T>(
        msg: RawMessage,
        resolvers: { [E in keyof ClientEvents]?: (this: this, eventData: ClientEvents[E]) => WaitForResult<T> },
        params?: WaitForParams,
    ): Promise<T>;
    send<T>(
        msg: RawMessage,
        resolvers?: { [E in keyof ClientEvents]?: (this: this, eventData: ClientEvents[E]) => WaitForResult<T> },
        params?: WaitForParams,
    ): void | Promise<T> {
        if (!this.socket || this.status === ClientStatus.Disconnected || this.status === ClientStatus.Connecting) {
            throw new Error("Connection is closed.");
        }

        let promise: Promise<T> | undefined;

        msg = this.prepare(msg);

        if (resolvers) {
            const cmd = msg.cmd;
            const label = msg.tags.label;
            const resolver = resolvers.message;

            resolvers.message = (msg) => {
                const labelIn = this.getMessageLabel(msg.value);
                if (labelIn && labelIn !== label) {
                    return;
                }

                const isError = match(msg)
                    .with(Message.Fail.select({ command: cmd }), (msg) => new IrcFailError(msg))
                    .with(
                        Message.Reply.pattern({ code: Code.ERR_UNKNOWNERROR }),
                        Message.Reply.pattern({ code: Code.ERR_UNKNOWNCOMMAND, params: [P._, `${cmd}`, P._] }),
                        Message.Reply.pattern({ code: Code.ERR_NEEDMOREPARAMS }),
                        Message.Reply.pattern({ code: Code.RPL_TRYAGAIN, params: [P._, `${cmd}`, P._] }),
                        (msg) => new IrcReplyError(msg.value),
                    )
                    .otherwise(() => undefined);

                if (isError) {
                    throw isError;
                }

                return resolver?.call(this, msg);
            };
        }

        if (resolvers) {
            promise = this.waitFor(resolvers, params);
        }

        this.socket.send(renderRawMessage(msg));

        return promise;
    }

    sendBatched(msg: RawMessage, type: string, params?: WaitForParams): Promise<Batch> {
        let name = "";

        return this.send(
            msg,
            {
                message(msg) {
                    return match(msg)
                        .with(Message.BatchStart.select({ type }), (msg) => {
                            name = msg.name;
                        })
                        .with(
                            Message.BatchEnd.select({ name }),
                            (_msg) => !!name,
                            (msg) => {
                                const batch = this.batches.get(msg.name);
                                if (!batch) {
                                    throw new Error("Batch does not exist");
                                }
                                return WaitFor.resolve(batch);
                            },
                        )
                        .otherwise(() => void 0);
                },
            },
            params,
        );
    }

    // PRIVATE

    private handleOpen() {
        this.status = ClientStatus.Registering;
        this.reconnectTimer.reset();

        this.capLs().then(async () => {
            this.supportsCaps = true;

            if (this.caps.enabled(Capability.Sasl) && this.params.sasl) {
                await this.authenticate(this.params.sasl).catch(() => void 0);
            }
            if (this.caps.enabled(Capability.BouncerNetworks) && this.params.bouncerNetwork) {
                this.send(Command.bouncerBind(this.params.bouncerNetwork));
            }

            this.send(Command.capEnd());
        });

        if (this.params.pass) {
            this.send(Command.pass(this.params.pass));
        }
        this.send(Command.nick(this.params.nickname));
        this.send(Command.user(this.params.username, this.params.realname));

        this.waitFor({
            motd(motd) {
                return WaitFor.resolve(motd);
            },
        });
    }

    private handleClose() {
        this.socket = undefined;
        this.status = ClientStatus.Disconnected;
        this.isupport.clear();
        this.caps.clear();
        this.supportsCaps = false;
        this.batches.clear();
        this.monitored.clear();
        this.pendingCommands.clear();
        this.nickname = this.params.nickname;

        if (this.autoReconnect) {
            if (!navigator.onLine) {
                console.info("Waiting for network to go back online");

                const handleOnline = () => {
                    window.removeEventListener("online", handleOnline);
                    this.reconnectTimer.reset();
                    this.connect(this.params);
                };
                window.addEventListener("online", handleOnline);
            } else {
                const delay = this.reconnectTimer.next();

                console.info(`Reconnecting to server in ${delay / 1000} seconds`);

                if (this.reconnectTimeoutId) {
                    clearTimeout(this.reconnectTimeoutId);
                }
                this.reconnectTimeoutId = setTimeout(() => {
                    this.connect(this.params);
                }, delay);
            }
        }
    }

    private handleMessage(e: MessageEvent) {
        if (typeof e.data !== "string") {
            this.socket?.close(WS_UNSUPPORTED_DATA);
            return;
        }

        const rawMsg = parseRawMessage(e.data);

        if (!rawMsg) {
            return;
        }

        const msg = parseMessage(rawMsg, this.server);

        if (!Message.BatchStart.is(msg) && !Message.BatchEnd.is(msg)) {
            this.getBatch(msg.value)?.items.push(BatchItem.Message(msg));

            if (this.findBatchByType(msg.value, "chathistory") || this.findBatchByType(msg.value, "soju.im/search")) {
                return;
            }
        }

        const postActions: Array<() => unknown> = [];

        match(msg)
            .with(
                Message.Ctcp.select({ command: "PING" }),
                (msg) => !this.isMe(msg.value.source.nickname),
                (msg) => this.send(Command.privmsg(msg.source.nickname, `\x01PING ${msg.params}\x01`)),
            )
            .with(
                Message.CtcpNotice.select({ command: "PING" }),
                (msg) => !this.isMe(msg.value.source.nickname),
                (msg) => this.send(Command.notice(msg.source.nickname, `\x01PING ${msg.params}\x01`)),
            )
            .with(
                Message.Ctcp.select({ command: "CLIENTINFO" }),
                (msg) => !this.isMe(msg.value.source.nickname),
                (msg) =>
                    this.send(
                        Command.privmsg(msg.source.nickname, `\x01CLIENTINFO ACTION CLIENTINFO PING TIME VERSION\x01`),
                    ),
            )
            .with(
                Message.CtcpNotice.select({ command: "CLIENTINFO" }),
                (msg) => !this.isMe(msg.value.source.nickname),
                (msg) =>
                    this.send(
                        Command.notice(msg.source.nickname, `\x01CLIENTINFO ACTION CLIENTINFO PING TIME VERSION\x01`),
                    ),
            )
            .with(
                Message.Ctcp.select({ command: "TIME" }),
                (msg) => !this.isMe(msg.value.source.nickname),
                (msg) => this.send(Command.privmsg(msg.source.nickname, `\x01TIME ${new Date().toISOString()}\x01`)),
            )
            .with(
                Message.CtcpNotice.select({ command: "TIME" }),
                (msg) => !this.isMe(msg.value.source.nickname),
                (msg) => this.send(Command.notice(msg.source.nickname, `\x01TIME ${new Date().toISOString()}\x01`)),
            )
            .with(
                Message.Ctcp.select({ command: "VERSION" }),
                (msg) => !this.isMe(msg.value.source.nickname),
                (msg) => this.send(Command.privmsg(msg.source.nickname, `\x01VERSION ${this._version}\x01`)),
            )
            .with(
                Message.CtcpNotice.select({ command: "VERSION" }),
                (msg) => !this.isMe(msg.value.source.nickname),
                (msg) => this.send(Command.notice(msg.source.nickname, `\x01VERSION ${this._version}\x01`)),
            )
            .with(Message.Reply.select(), (msg) => this.handleReplyMessage(msg))
            .with(Message.CapNew.select(), (msg) => {
                this.caps.ls(msg.caps);
                this.capReq();
            })
            .with(Message.CapDel.select(), (msg) => {
                this.caps.del(msg.caps);
            })
            .with(Message.Ping.select(), (msg) => this.send(Command.pong(msg.params)))
            .with(
                Message.Nick.select(),
                (msg) => this.isMe(msg.value.source.nickname),
                (msg) =>
                    postActions.push(() => {
                        this.nickname = msg.nickname;
                    }),
            )
            .with(Message.BatchStart.select(), (msg) => this.batches.set(msg.name, { ...msg, items: [] }))
            .with(Message.BatchEnd.select(), (msg) => {
                const batch = this.batches.get(msg.name);

                if (!batch) {
                    return;
                }

                postActions.push(() => {
                    this.dispatch("batch", batch);
                    this.batches.delete(batch.name);
                });

                const parentBatch = this.getBatch(msg);
                if (parentBatch) {
                    parentBatch.items.push(BatchItem.Batch(batch));
                }
            })
            .with(Message.Error.select(), (msg) => {
                this.dispatchError(new IrcFatalError(msg));
                this.disconnect();
            })
            .with(
                Message.Fail.pattern({
                    command: "BOUNCER",
                    context: ["BIND", ...P.array()],
                }),
                (_) => this.status === ClientStatus.Registering,
                (msg) => {
                    this.dispatchError(new IrcFailError(msg.value));
                    this.disconnect();
                },
            )
            .with(
                Message.Fail.pattern({ code: "ACCOUNT_REQUIRED" }),
                (_) => this.status === ClientStatus.Registering,
                (msg) => {
                    this.dispatchError(new IrcFailError(msg.value));
                    this.disconnect();
                },
            )
            .otherwise(() => void 0);

        this.dispatch("message", msg);

        if (Message.Unknown.is(msg)) {
            console.log("UNKNOWN MESSAGE", msg);
        }

        for (const action of postActions) {
            action();
        }
    }

    private handleReplyMessage(msg: Narrow<Message, "Reply">["value"]) {
        match(msg)
            .with({ code: Code.RPL_WELCOME, params: [P.select()] }, (client) => {
                this.nickname = client;
                this.status = ClientStatus.Registered;
                this.server = msg.source;
            })
            .with(
                {
                    code: Code.RPL_ISUPPORT,
                    params: [P._, P.select("first"), ...P.array().select("rest"), P._],
                },
                ({ first, rest }) => {
                    const prevMonitor = this.isupport[ISupportToken.Monitor];

                    this.isupport.apply([first, ...rest]);
                    this.caseMapping = this.isupport[ISupportToken.CaseMapping];

                    const curMonitor = this.isupport[ISupportToken.Monitor];
                    if (prevMonitor === 0 && this.monitored.size > 0 && curMonitor > 0) {
                        const targets = Array.from(this.monitored.keys()).slice(0, curMonitor);
                        this.send(Command.monitorAdd(targets));
                    }
                },
            )
            .with({ code: Code.RPL_MOTD, params: [P._, P.select()] }, (line) => {
                this.transactions.pushMotd(line.startsWith("- ") ? line.slice(2) : line);
            })
            .with({ code: Code.RPL_ENDOFMOTD }, () => this.dispatch("motd", this.transactions.endMotd()))
            .with({ code: Code.ERR_NOMOTD, params: [P._, P.select()] }, (line) => {
                this.transactions.pushMotd(line);
                this.dispatch("motd", this.transactions.endMotd());
            })
            .with(
                { code: Code.RPL_NAMREPLY, params: [P._, P._, P.select("channel"), P.select("users")] },
                ({ channel, users }) => {
                    const usersList = users.split(" ");
                    const prefixes = this.isupport[ISupportToken.Prefix].prefixModes.map((m) => m[1]).join("");

                    for (const user of usersList) {
                        const { nickname, prefix } = this.parsePrefixed(user, prefixes);
                        this.transactions.pushNames(channel, nickname, prefix);
                    }
                },
            )
            .with({ code: Code.RPL_ENDOFNAMES, params: [P._, P.select(), P._] }, (channel) => {
                this.dispatch("names", { channel, members: this.transactions.endNames(channel) });
            })
            .with(
                {
                    code: P.union(Code.RPL_BANLIST, Code.RPL_QUIETLIST, Code.RPL_INVEXLIST, Code.RPL_EXCEPTLIST),
                    params: [P._, P._, P._, ...P.array()],
                },
                ({ code, params: [, target, mask, setter, time = ""] }) => {
                    const type = {
                        [Code.RPL_BANLIST]: ModeListType.BanList,
                        [Code.RPL_QUIETLIST]: ModeListType.QuietList,
                        [Code.RPL_INVEXLIST]: ModeListType.InvexList,
                        [Code.RPL_EXCEPTLIST]: ModeListType.ExceptList,
                    }[code];
                    const timeNum = +time;

                    this.transactions.pushModeList(`${type} ${target}`, {
                        mask,
                        setter,
                        setAt: time && !isNaN(timeNum) ? new Date(timeNum) : undefined,
                    });
                },
            )
            .with(
                {
                    code: P.union(
                        Code.RPL_ENDOFBANLIST,
                        Code.RPL_ENDOFQUIETLIST,
                        Code.RPL_ENDOFINVEXLIST,
                        Code.RPL_ENDOFEXCEPTLIST,
                    ),
                    params: [P._, P._, P._, ...P.array()],
                },
                ({ code, params: [, target] }) => {
                    const type = {
                        [Code.RPL_ENDOFBANLIST]: ModeListType.BanList,
                        [Code.RPL_ENDOFQUIETLIST]: ModeListType.QuietList,
                        [Code.RPL_ENDOFINVEXLIST]: ModeListType.InvexList,
                        [Code.RPL_ENDOFEXCEPTLIST]: ModeListType.ExceptList,
                    }[code];
                    const list = this.transactions.endModeList(`${type} ${target}`);

                    this.dispatch("modeList", { type, list });
                },
            )
            .with(
                {
                    code: Code.RPL_LIST,
                    params: [P._, P.select("channel"), P.string.regex(/^\d+$/).select("clients"), P.select("topic")],
                },
                ({ channel, clients, topic }) => {
                    this.transactions.pushList({
                        channel,
                        clients: +clients,
                        topic,
                    });
                },
            )
            .with({ code: Code.RPL_LISTEND }, () => this.dispatch("list", this.transactions.endList()))
            .with(
                { code: Code.ERR_PASSWDMISMATCH },
                { code: Code.ERR_ERRONEUSNICKNAME },
                { code: Code.ERR_NICKNAMEINUSE },
                { code: Code.ERR_NICKCOLLISION },
                { code: Code.ERR_UNAVAILRESOURCE },
                { code: Code.ERR_NOPERMFORHOST },
                { code: Code.ERR_YOUREBANNEDCREEP },
                (msg) => {
                    this.dispatchError(new IrcReplyError(msg));
                    if (this.status === ClientStatus.Registering) {
                        this.disconnect();
                    }
                },
            )
            .with({ code: Code.RPL_WHOISREGNICK, params: [P._, P.select(), P._] }, (nickname) =>
                this.transactions.pushWhois(nickname, { isAuthenticated: true }),
            )
            .with(
                {
                    code: Code.RPL_WHOISUSER,
                    params: [
                        P._,
                        P.select("nickname"),
                        P.select("username"),
                        P.select("host"),
                        "*",
                        P.select("realname"),
                    ],
                },
                ({ nickname, ...data }) => {
                    this.transactions.pushWhois(nickname, data);
                },
            )
            .with(
                {
                    code: Code.RPL_WHOISSERVER,
                    params: [P._, P.select("nickname"), P.select("server"), P.select("serverInfo")],
                },
                ({ nickname, ...data }) => this.transactions.pushWhois(nickname, data),
            )
            .with({ code: Code.RPL_WHOISOPERATOR, params: [P._, P.select(), P._] }, (nickname) =>
                this.transactions.pushWhois(nickname, { isOperator: true }),
            )
            .with(
                {
                    code: Code.RPL_WHOISIDLE,
                    params: [
                        P._,
                        P.select("nickname"),
                        P.string.regex(/^\d+$/).select("idle"),
                        P.string.regex(/^\d+$/).select("loggedInAt"),
                        P._,
                    ],
                },
                ({ nickname, idle, loggedInAt }) => {
                    this.transactions.pushWhois(nickname, { idle: +idle });
                    this.transactions.pushWhois(nickname, { loggedInAt: new Date(+loggedInAt * 1000) });
                },
            )
            .with(
                { code: Code.RPL_WHOISCHANNELS, params: [P._, P.select("nickname"), P.select("channels")] },
                ({ nickname, channels }) => this.transactions.pushWhois(nickname, { channels: channels.split(" ") }),
            )
            .with(
                { code: Code.RPL_WHOISSPECIAL, params: [P._, P.select("nickname"), P.select("special")] },
                ({ nickname, special }) => this.transactions.pushWhois(nickname, { special }),
            )
            .with(
                { code: Code.RPL_WHOISACCOUNT, params: [P._, P.select("nickname"), P.select("account"), P._] },
                ({ nickname, account }) => this.transactions.pushWhois(nickname, { account }),
            )
            .with({ code: Code.RPL_WHOISBOT, params: [P._, P.select("nickname"), P._] }, ({ nickname }) =>
                this.transactions.pushWhois(nickname, { isBot: true }),
            )
            .with({ code: Code.RPL_ENDOFWHOIS, params: [P._, P.select(), P._] }, (nickname) => {
                this.dispatch("whois", { nickname, whois: this.transactions.endWhois(nickname) });
            })
            .with(
                {
                    code: Code.RPL_WHOWASUSER,
                    params: [
                        P._,
                        P.select("nickname"),
                        P.select("username"),
                        P.select("host"),
                        "*",
                        P.select("realname"),
                    ],
                },
                ({ nickname, ...data }) => {
                    this.transactions.pushWhois(nickname, data);
                },
            )
            .with({ code: Code.RPL_ENDOFWHOWAS, params: [P._, P.select(), P._] }, (nickname) => {
                this.dispatch("whowas", { nickname, whois: this.transactions.endWhois(nickname) });
            })
            .with(
                {
                    code: Code.RPL_WHOREPLY,
                    params: [
                        P._,
                        P.select("channel"),
                        P.select("username"),
                        P.select("host"),
                        P.select("server"),
                        P.select("nickname"),
                        P.select("flags"),
                        P.select("hopAndRealname"),
                    ],
                },
                ({ flags, hopAndRealname, ...data }) => {
                    const isOperator = flags.includes("*");
                    const isAway = flags.includes("G");
                    const isBot = flags.includes(this.isupport[ISupportToken.Bot]);
                    const realname = hopAndRealname.split(" ")[1];
                    this.transactions.pushWho({ ...data, realname, isOperator, isAway, isBot });
                },
            )
            .with({ code: Code.RPL_WHOSPCRPL, params: P.select([P._, P._, ...P.array()]) }, (params) => {
                const token = params[1];
                const fields = this.transactions.getWhox(token);
                const whox: Whox = { token };
                let i = 1;

                for (const [field, key] of Object.entries(WHOX_FIELDS)) {
                    if (!fields?.includes(field)) {
                        continue;
                    }

                    const value = params[i++];

                    if (value === undefined) {
                        continue;
                    }

                    if (key === "idle") {
                        const idle = +value;
                        if (value && !isNaN(idle)) {
                            whox.idle = +value;
                        }
                    } else if (key === "flags") {
                        whox.isOperator = value.includes("*");
                        whox.isAway = value.includes("G");
                        whox.isBot = value.includes(this.isupport[ISupportToken.Bot]);
                    } else if (key === "account" && value === "0") {
                        whox.account = undefined;
                    } else {
                        whox[key] = value;
                    }
                }

                this.transactions.pushWho(whox, token);
            })
            .with({ code: Code.RPL_ENDOFWHO, params: [P._, P.select(), P._] }, (target) => {
                this.dispatch("who", { target, who: this.transactions.endWho(target) });
            })
            .with({ code: Code.RPL_HELPSTART, params: [P._, P._, P.select()] }, (line) => {
                this.transactions.pushHelp(line);
            })
            .with({ code: Code.RPL_HELPTXT, params: [P._, P._, P.select()] }, (line) => {
                this.transactions.pushHelp(line);
            })
            .with({ code: Code.RPL_ENDOFHELP, params: [P._, P._, P.select()] }, (line) => {
                this.transactions.pushHelp(line);
                this.dispatch("help", this.transactions.endHelp());
            })
            .with({ code: Code.ERR_HELPNOTFOUND, params: [P._, P._, P.select()] }, (line) => {
                this.transactions.pushHelp(line);
                this.dispatch("help", this.transactions.endHelp());
            })
            .with({ code: Code.RPL_DATASTR, params: [P._, P.select()] }, (line) => {
                this.transactions.pushHelp(line);
                this.detectHelpEnd();
            })
            .with({ code: Code.RPL_HELPOP, params: [P._, P.select()] }, (line) => {
                this.transactions.pushHelp(line);
                this.detectHelpEnd();
            })
            .with({ code: Code.RPL_HELPTLR, params: [P._, P.select()] }, (line) => {
                this.transactions.pushHelp(line);
                this.detectHelpEnd();
            })
            .with({ code: Code.RPL_HELPHLP, params: [P._, P.select()] }, (line) => {
                this.transactions.pushHelp(line);
                this.detectHelpEnd();
            })
            .with({ code: Code.RPL_HELPFWD, params: [P._, P.select()] }, (line) => {
                this.transactions.pushHelp(line);
                this.detectHelpEnd();
            })
            .with({ code: Code.RPL_HELPIGN, params: [P._, P.select()] }, (line) => {
                this.transactions.pushHelp(line);
                this.detectHelpEnd();
            })
            .with({ code: Code.RPL_INFOSTART, params: [P._, P.select()] }, (line) => {
                this.transactions.pushInfo(line);
            })
            .with({ code: Code.RPL_INFO, params: [P._, P.select()] }, (line) => {
                this.transactions.pushInfo(line);
            })
            .with({ code: Code.RPL_ENDOFINFO }, () => {
                this.dispatch("info", this.transactions.endInfo());
            })
            .otherwise(() => void 0);
    }

    private async authenticate(params: ClientAuthentication) {
        const mechanisms = this.caps.get(Capability.Sasl);
        if (!mechanisms) {
            throw new Error("SASL authentication not supported by the server");
        }
        if (mechanisms.length && !mechanisms.includes(params.type)) {
            throw new Error(`SASL ${params.type} authentication not supported by the server`);
        }

        try {
            await this.send(Command.authenticate(params.type), {
                message: (msg) =>
                    match(msg)
                        .with(Message.Authenticate.pattern({ content: "+" }), () => WaitFor.resolve())
                        .with(Message.Authenticate.select(), (msg) => {
                            throw new Error(`Expected an empty challenge, got ${msg.content}`);
                        })
                        .with(
                            Message.Reply.pattern({
                                code: P.union(Code.ERR_SASLFAIL, Code.ERR_SASLALREADY, Code.RPL_SASLMECHS),
                            }),
                            (msg) => {
                                throw new IrcReplyError(msg.value);
                            },
                        )
                        .otherwise(() => void 0),
            });
        } catch (e) {
            this.send(Command.authenticate("*"));
            throw e;
        }

        const authMsgs = match(params)
            .with(ClientAuthentication.PLAIN.select(), ({ username, password }) =>
                Command.authenticates(btoa(`\0${username}\0${password}`)),
            )
            .otherwise(() => Command.authenticates(btoa("")));

        const promise = this.waitFor({
            message(msg) {
                if (!Message.Reply.is(msg)) {
                    return;
                }
                return match(msg.value)
                    .with({ code: Code.RPL_SASLSUCCESS }, () => WaitFor.resolve())
                    .with(
                        {
                            code: P.union(
                                Code.RPL_NICKLOCKED,
                                Code.ERR_SASLFAIL,
                                Code.ERR_SASLTOOLONG,
                                Code.ERR_SASLABORTED,
                                Code.ERR_SASLALREADY,
                            ),
                        },
                        () => {
                            throw new IrcReplyError(msg.value);
                        },
                    )
                    .otherwise(() => void 0);
            },
        });

        authMsgs.forEach(this.send, this);
        await promise;
    }

    private modeList(target: string, mode: string, itemCode: number, endCode: number, params?: WaitForParams) {
        const list: ModeListItem[] = [];
        const isTarget = caseMappedEquals(this.caseMapping, target);

        return this.send(
            Command.mode(target, [`+${mode}`]),
            {
                message: (msg) =>
                    match(msg)
                        .with(
                            Message.Reply.pattern({
                                code: itemCode,
                                params: P.select([P._, P._, P._, ...P.array()]),
                            }),
                            (msg) => isTarget(msg.value.params[1]),
                            ([, , mask, setter, time = ""]) => {
                                const timeNum = +time;
                                list.push({
                                    mask,
                                    setter,
                                    setAt: time && !isNaN(timeNum) ? new Date(timeNum) : undefined,
                                });
                            },
                        )
                        .with(
                            Message.Reply.pattern({ code: endCode, params: [P._, P._, P._] }),
                            (msg) => isTarget(msg.value.params[1]),
                            () => WaitFor.resolve(list),
                        )
                        .with(
                            Message.Reply.pattern({
                                code: Code.ERR_CHANOPRIVSNEEDED,
                                params: [P._, P._, P._],
                            }),
                            (msg) => isTarget(msg.value.params[1]),
                            (msg) => {
                                throw new IrcReplyError(msg.value);
                            },
                        )
                        .otherwise(() => void 0),
            },
            params,
        );
    }

    private synchronized<T>(key: string, promiseFn: () => Promise<T>): Promise<T> {
        const syncPromise = (this.pendingCommands.get(key) ?? Promise.resolve()).then(promiseFn);
        this.pendingCommands.set(
            key,
            syncPromise.catch(() => void 0),
        );
        return syncPromise;
    }

    private detectHelpEnd() {
        if (this.helpEndTimeout !== undefined) {
            clearTimeout(this.helpEndTimeout);
        }

        this.helpEndTimeout = setTimeout(() => {
            this.dispatch("help", this.transactions.endHelp());
            this.helpEndTimeout = undefined;
        }, 1000);
    }

    // UTILS

    getBatch(msg: { tags: Tags }) {
        if (msg.tags.batch) {
            return this.batches.get(msg.tags.batch);
        }
    }

    findBatchByType(msg: { tags: Tags }, type: string) {
        for (let batch = this.getBatch(msg); batch; batch = this.getBatch(batch)) {
            if (batch.type === type) {
                return batch;
            }
        }
    }

    findBatchByName(msg: { tags: Tags }, name: string) {
        for (let batch = this.getBatch(msg); batch; batch = this.getBatch(batch)) {
            if (batch.name === name) {
                return batch;
            }
        }
    }

    getMessageLabel(msg: { tags: Tags }) {
        if (msg.tags.label) {
            return msg.tags.label;
        }
        for (let batch = this.getBatch(msg); batch; batch = this.getBatch(batch)) {
            if (batch.tags.label) {
                return batch.tags.label;
            }
        }
    }

    // COMMANDS

    setAway(reason?: string) {
        return this.send(Command.away(reason ? reason : "I'm now away"));
    }

    setOnline() {
        return this.send(Command.away(""));
    }

    nick(nickname: string, params?: WaitForParams) {
        return this.send(
            Command.nick(nickname),
            {
                message: (msg) =>
                    match(msg)
                        .with(
                            Message.Nick.pattern({ nickname: P.select() }),
                            (msg) => this.isMe(msg.value.source.nickname),
                            (nickname) => WaitFor.resolve(nickname),
                        )
                        .with(
                            Message.Reply.select({
                                code: P.union(
                                    Code.ERR_NONICKNAMEGIVEN,
                                    Code.ERR_ERRONEUSNICKNAME,
                                    Code.ERR_NICKNAMEINUSE,
                                    Code.ERR_NICKCOLLISION,
                                ),
                            }),
                            (msg) => {
                                throw new IrcReplyError(msg);
                            },
                        )
                        .otherwise(() => void 0),
            },
            params,
        );
    }

    setname(realname: string, params?: WaitForParams) {
        return this.send(
            Command.setname(realname),
            {
                message: (msg) =>
                    match(msg)
                        .with(
                            Message.SetName.pattern({ realname: P.select() }),
                            (msg) => this.isMe(msg.value.source.nickname),
                            (realname) => WaitFor.resolve(realname),
                        )
                        .with(Message.Fail.select({ command: "SETNAME" }), (msg) => {
                            throw new IrcFailError(msg);
                        })
                        .otherwise(() => void 0),
            },
            params,
        );
    }

    privmsg(target: string, msg: string, replyTo?: string) {
        return this.send(Command.privmsg(target, msg, replyTo));
    }

    topic(target: string, topic?: string, params?: WaitForParams) {
        const isTarget = caseMappedEquals(this.caseMapping, target);

        return this.send(
            Command.topic(target, topic),
            {
                message: (msg) =>
                    match(msg)
                        .with(
                            Message.Topic.pattern({ topic: P.select() }),
                            (msg) => isTarget(msg.value.channel),
                            (topic) => WaitFor.resolve(topic),
                        )
                        .with(
                            Message.Reply.select({
                                code: Code.RPL_TOPIC,
                                params: [P._, P._, P.select()],
                            }),
                            (msg) => isTarget(msg.value.params[1]),
                            (topic) => WaitFor.resolve(topic),
                        )
                        .with(
                            Message.Reply.select({
                                code: Code.RPL_NOTOPIC,
                                params: [P._, P._, P._],
                            }),
                            (msg) => isTarget(msg.value.params[1]),
                            () => WaitFor.resolve(""),
                        )
                        .with(
                            Message.Reply.select({
                                code: P.union(Code.ERR_NOSUCHCHANNEL, Code.ERR_NOTONCHANNEL, Code.ERR_CHANOPRIVSNEEDED),
                                params: [P._, P._, P._],
                            }),
                            (msg) => isTarget(msg.value.params[1]),
                            (msg) => {
                                throw new IrcReplyError(msg);
                            },
                        )
                        .otherwise(() => void 0),
            },
            params,
        );
    }

    async capLs(params?: WaitForParams) {
        const isCapLs = isMatching(Message.CapLs.select());
        let caps: CapsRecord = {};

        await this.send(
            Command.capLs(),
            {
                message(msg) {
                    if (isCapLs(msg)) {
                        caps = { ...caps, ...msg.value.caps };
                        return !msg.value.more && WaitFor.resolve();
                    }
                },
            },
            params,
        );

        this.caps.ls(caps);
        await this.capReq(params);
        return caps;
    }

    async capList(params?: WaitForParams) {
        const isCapList = isMatching(Message.CapList.select());
        const caps: string[] = [];

        await this.send(
            Command.capList(),
            {
                message(msg) {
                    if (isCapList(msg)) {
                        caps.push(...msg.value.caps);
                        return !msg.value.more && WaitFor.resolve();
                    }
                },
            },
            params,
        );

        this.caps.list(caps);
        return caps;
    }

    capReq(params?: WaitForParams) {
        const wantedCaps = [...STATIC_CAPS];

        if (!this.params.bouncerNetwork) {
            wantedCaps.push(Capability.BouncerNetworksNotify);
        }

        const missing = this.caps.missing(wantedCaps);

        if (!missing.length) {
            return Promise.resolve(true);
        }

        return this.send(
            Command.capReq(missing),
            {
                message(msg) {
                    if (Message.CapAck.is(msg) || Message.CapNak.is(msg)) {
                        const match = missing.every((cap) => msg.value.caps.includes(cap));
                        if (match) {
                            if (Message.CapAck.is(msg)) {
                                this.caps.ack(msg.value.caps);
                            }
                            return WaitFor.resolve();
                        }
                    }
                },
            },
            params,
        );
    }

    names(channel: string, params?: WaitForParams) {
        const isChannel = caseMappedEquals(this.caseMapping, channel);

        return this.send(
            Command.names(channel),
            { names: (data) => isChannel(data.channel) && WaitFor.resolve(data.members) },
            params,
        );
    }

    banList(target: string, mode = "b", params?: WaitForParams) {
        return this.modeList(target, mode, Code.RPL_BANLIST, Code.RPL_ENDOFBANLIST, params);
    }

    exceptList(target: string, mode = "e", params?: WaitForParams) {
        return this.modeList(target, mode, Code.RPL_EXCEPTLIST, Code.RPL_ENDOFEXCEPTLIST, params);
    }

    quietList(target: string, mode = "q", params?: WaitForParams) {
        return this.modeList(target, mode, Code.RPL_QUIETLIST, Code.RPL_ENDOFQUIETLIST, params);
    }

    inviteExceptionList(target: string, mode = "I", params?: WaitForParams) {
        return this.modeList(target, mode, Code.RPL_INVEXLIST, Code.RPL_ENDOFINVEXLIST, params);
    }

    list(channels: string[] = [], params?: WaitForParams) {
        const list: ChannelListItem[] = [];

        return this.send(
            Command.list(channels),
            {
                message: (msg) =>
                    match(msg)
                        .with(
                            Message.Reply.pattern({
                                code: Code.RPL_LIST,
                                params: [
                                    P._,
                                    P.select("channel"),
                                    P.string.regex(/^\d+$/).select("clients"),
                                    P.select("topic"),
                                ],
                            }),
                            ({ channel, clients, topic }) => {
                                list.push({ channel, clients: +clients, topic });
                            },
                        )
                        .with(Message.Reply.pattern({ code: Code.RPL_LISTEND }), () => WaitFor.resolve(list))
                        .otherwise(() => void 0),
            },
            params,
        );
    }

    motd(params?: WaitForParams) {
        return this.send(Command.motd(), { motd: (motd) => WaitFor.resolve(motd) }, params);
    }

    join(channel: string, key?: string, params?: WaitForParams) {
        const isChannel = caseMappedEquals(this.caseMapping, channel);

        return this.send(
            Command.join(channel, key),
            {
                message: (msg) =>
                    match(msg)
                        .with(
                            Message.Join.select(),
                            (msg) => this.isMe(msg.value.source.nickname) && isChannel(msg.value.channel),
                            () => WaitFor.resolve(),
                        )
                        .with(
                            Message.Reply.select({
                                code: P.union(
                                    Code.ERR_NOSUCHCHANNEL,
                                    Code.ERR_TOOMANYCHANNELS,
                                    Code.ERR_BADCHANNELKEY,
                                    Code.ERR_BANNEDFROMCHAN,
                                    Code.ERR_CHANNELISFULL,
                                    Code.ERR_INVITEONLYCHAN,
                                ),
                                params: [P._, P._, P._],
                            }),
                            (msg) => isChannel(msg.value.params[1]),
                            (msg) => {
                                throw new IrcReplyError(msg);
                            },
                        )
                        .otherwise(() => void 0),
            },
            params,
        );
    }

    part(channel: string) {
        return this.send(Command.part(channel));
    }

    whois(nickname: string, params?: WaitForParams) {
        const isNickname = caseMappedEquals(this.caseMapping, nickname);
        const isError = isMatching(
            Message.Reply.pattern({
                code: Code.ERR_NOSUCHNICK,
                params: [P._, P.string, P._],
            }),
        );

        return this.send(
            Command.whois([nickname]),
            {
                whois: (data) => isNickname(data.nickname) && WaitFor.resolve(data.whois),
                message(msg) {
                    if (isError(msg) && isNickname(msg.value.params[1])) {
                        throw new IrcReplyError(msg.value);
                    }
                },
            },
            params,
        );
    }

    whowas(nickname: string, params?: WaitForParams) {
        const isNickname = caseMappedEquals(this.caseMapping, nickname);
        const isError = isMatching(
            Message.Reply.pattern({
                code: Code.ERR_WASNOSUCHNICK,
                params: [P._, P.string, P._],
            }),
        );

        return this.send(
            Command.whois([nickname]),
            {
                whowas: (data) => isNickname(data.nickname) && WaitFor.resolve(data.whois),
                message(msg) {
                    if (isError(msg) && isNickname(msg.value.params[1])) {
                        throw new IrcReplyError(msg.value);
                    }
                },
            },
            params,
        );
    }

    who(target: string, fields?: string, params?: WaitForParams) {
        const isTarget = caseMappedEquals(this.caseMapping, target);

        if (this.transactions.whoxTokens.has(target)) {
            return this.waitFor({
                who(e) {
                    return isTarget(e.target) && WaitFor.resolve(e.who);
                },
            });
        }

        if (!this.isupport[ISupportToken.Whox]) {
            fields = undefined;
        } else if (!fields) {
            fields = "tfhnrua";
        }

        const token = fields && `${whoxCounter++ % 1000}`;

        if (token && fields) {
            this.transactions.initWhox(target, token, fields);
        }

        const run = (): Promise<Whox[]> =>
            this.send(
                Command.who(target, fields, token),
                { who: (data) => isTarget(data.target) && WaitFor.resolve(data.who) },
                params,
            );

        return fields ? run() : this.synchronized("who", run);
    }

    mode(target: string, modes?: string, modeArgs: string[] = [], params?: WaitForParams) {
        const isTarget = caseMappedEquals(this.caseMapping, target);

        return this.send(
            Command.mode(target, modes ? [modes, ...modeArgs] : []),
            {
                message: (msg) =>
                    match(msg)
                        .with(
                            Message.Mode.select(),
                            (msg) => this.isMe(msg.value.source.nickname) && isTarget(msg.value.target),
                            () => WaitFor.resolve(),
                        )
                        .with(Message.Reply.select({ code: Code.RPL_UMODEIS }), () => WaitFor.resolve())
                        .with(
                            Message.Reply.select({
                                code: Code.RPL_CHANNELMODEIS,
                                params: [P._, P._, P._, ...P.array()],
                            }),
                            (msg) => isTarget(msg.value.params[1]),
                            () => WaitFor.resolve(),
                        )
                        .with(
                            Message.Reply.select({
                                code: P.union(Code.ERR_NOSUCHNICK, Code.ERR_NOSUCHCHANNEL, Code.ERR_CHANOPRIVSNEEDED),
                                params: [P._, P._, P._],
                            }),
                            (msg) => isTarget(msg.value.params[1]),
                            (msg) => {
                                throw new IrcReplyError(msg);
                            },
                        )
                        .with(Message.Reply.select({ code: Code.ERR_USERSDONTMATCH }), (msg) => {
                            throw new IrcReplyError(msg);
                        })
                        .otherwise(() => void 0),
            },
            params,
        );
    }

    help(subject?: string, params?: WaitForParams) {
        return this.synchronized("help", () =>
            this.send(
                Command.help(subject),
                {
                    help: (data) => WaitFor.resolve(data),
                },
                params,
            ),
        );
    }

    info(params?: WaitForParams) {
        return this.send(
            Command.info(),
            {
                info: (text) => WaitFor.resolve(text),
            },
            params,
        );
    }

    typing(target: string, typing: Typing) {
        let timer = this.typings.get(target);

        if (typing === Typing.Active) {
            if (timer !== undefined) {
                return;
            }

            timer = setTimeout(() => this.typings.delete(target), 3000);
            this.typings.set(target, timer);
        } else if (timer !== undefined) {
            clearTimeout(timer);
        }

        this.send(Command.typing(target, typing));
    }

    async historyBefore(target: string, anchor: Date, limit: number, params?: WaitForParams) {
        const max = Math.max(Math.min(limit, this.isupport[ISupportToken.ChatHistory]), 0);
        const messages: Message[] = [];
        let collect = true;

        while (collect) {
            const batch = await this.synchronized("chathistory", () =>
                this.sendBatched(Command.historyBefore(target, anchor, max), "chathistory", params),
            );
            const items = F.pipe(
                batch.items,
                A.filterMap((item) => (BatchItem.Message.is(item) ? O.some(item.value) : O.none)),
            );
            const first = items[0];

            if (!first) {
                break;
            }

            messages.unshift(...items);

            anchor = first.value.time;
            collect = messages.length < max;
        }

        return messages;
    }

    async historyAfter(target: string, anchor: Date | string, limit: number, params?: WaitForParams) {
        const max = Math.max(Math.min(limit, this.isupport[ISupportToken.ChatHistory]), 0);
        const messages: Message[] = [];
        let collect = true;

        while (collect) {
            const batch = await this.synchronized("chathistory", () =>
                this.sendBatched(Command.historyAfter(target, anchor, max), "chathistory", params),
            );
            const items = F.pipe(
                batch.items,
                A.filterMap((item) => (BatchItem.Message.is(item) ? O.some(item.value) : O.none)),
            );
            const last = items[items.length - 1];

            if (!last) {
                break;
            }

            messages.push(...items);

            anchor = last.value.time;
            collect = messages.length < max;
        }

        return messages;
    }

    async historyTargets(after: Date, before: Date, params?: WaitForParams): Promise<HistoryTarget[]> {
        const isChatHistoryTarget = isMatching(BatchItem.Message.pattern(Message.ChatHistoryTargets.select()));
        const batch = await this.synchronized("chathistory-targets", () =>
            this.sendBatched(Command.historyTargets(after, before, 1000), "draft/chathistory-targets", params),
        );
        return F.pipe(
            batch.items,
            A.filterMap((item) => (isChatHistoryTarget(item) ? O.some(item.value.value) : O.none)),
        );
    }

    async search(
        query: { in?: string; from?: string; after?: Date; before?: Date; text: string },
        limit?: number,
        params?: WaitForParams,
    ) {
        const batch = await this.synchronized("search", () =>
            this.sendBatched(Command.search(query), "soju.im/search", params),
        );
        const items = F.pipe(
            batch.items,
            A.filterMap((item) => (BatchItem.Message.is(item) ? O.some(item.value) : O.none)),
        );

        return items;
    }

    monitor(target: string) {
        if (this.monitored.has(target)) {
            return;
        }

        this.monitored.set(target, true);

        if (this.monitored.size + 1 > this.isupport[ISupportToken.Monitor]) {
            return;
        }

        this.send(Command.monitorAdd([target]));
    }

    unmonitor(target: string) {
        if (!this.monitored.has(target)) {
            return;
        }

        this.monitored.delete(target);

        if (this.isupport[ISupportToken.Monitor] <= 0) {
            return;
        }

        this.send(Command.monitorRemove([target]));
    }

    bouncerAddNetwork(
        attrs: Partial<Omit<NetworkAttributes, "state">> & Pick<NetworkAttributes, "host">,
        params?: WaitForParams,
    ) {
        const isBouncerAddNetwork = isMatching(Message.BouncerAddNetwork.select());
        return this.send(
            Command.bouncerAddNetwork(attrs),
            { message: (msg) => isBouncerAddNetwork(msg) && WaitFor.resolve() },
            params,
        );
    }

    bouncerChangeNetwork(id: string, attrs: Partial<Omit<NetworkAttributes, "state">>, params?: WaitForParams) {
        const isBouncerChangeNetwork = isMatching(Message.BouncerChangeNetwork.pattern({ id }));
        return this.send(
            Command.bouncerChangeNetwork(id, attrs),
            { message: (msg) => isBouncerChangeNetwork(msg) && WaitFor.resolve() },
            params,
        );
    }

    bouncerDelNetwork(id: string, params?: WaitForParams) {
        const isBouncerDelNetwork = isMatching(Message.BouncerDelNetwork.pattern({ id }));
        return this.send(
            Command.bouncerDelNetwork(id),
            { message: (msg) => isBouncerDelNetwork(msg) && WaitFor.resolve() },
            params,
        );
    }

    markRead(target: string, timestamp?: Date, params?: WaitForParams) {
        const isMarkRead = isMatching(Message.MarkRead.pattern({ target }));
        return this.send(
            Command.markRead(target, timestamp),
            { message: (msg) => isMarkRead(msg) && WaitFor.resolve() },
            params,
        );
    }
}
