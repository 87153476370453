import { Component, JSX, mergeProps, splitProps } from "solid-js";
import { match } from "ts-pattern";

export type Status = "primary" | "green" | "yellow" | "red" | "online" | "away" | "offline";

export const StatusIndicator: Component<
    {
        status?: Status;
    } & JSX.HTMLAttributes<HTMLDivElement>
> = (_props) => {
    const propsWithDefaults = mergeProps({ class: "" }, _props);
    const [props, propsRest] = splitProps(propsWithDefaults, ["status", "class"]);

    const status = () =>
        match(props.status)
            .with("online", () => "green")
            .with("away", () => "yellow")
            .with("offline", () => undefined)
            .otherwise((status) => status);

    return <div class={`o-status-indicator is-${status()} ${props.class}`} {...propsRest} />;
};
