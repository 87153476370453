enum Enum {}

export function isEnum<T extends typeof Enum>(e: T) {
    const keys = Object.keys(e).filter((k) => !/^\d/.test(k));
    const values = keys.map((k) => (e as Record<string, unknown>)[k]);
    return (token: unknown): token is T[keyof T] => {
        return values.includes(token);
    };
}

export type UnionToIntersection<T> = (T extends any ? (x: T) => any : never) extends (x: infer R) => any ? R : never;

export type SplitBy<Delim extends string, Input> = Input extends `${infer First}${Delim}${infer Rest}`
    ? [First, ...SplitBy<Delim, Rest>]
    : [Input];

export type SplitEach<Delim extends string, Input> = Input extends [infer Head, ...infer Tail]
    ? [...SplitBy<Delim, Head>, ...SplitEach<Delim, Tail>]
    : [];

export type Join<Delim extends string, Parts> = Parts extends [infer First, ...infer Rest]
    ? First extends string
        ? `${First}${Delim}${Join<Delim, Rest>}`
        : never
    : "";

export type Replace<Subst, Parts> = Parts extends [infer First, ...infer Rest]
    ? First extends keyof Subst
        ? [Subst[First], ...Replace<Subst, Rest>]
        : [First, ...Replace<Subst, Rest>]
    : Parts;

export type ReplaceSeq<Search, New, Parts> = Parts extends [infer First, ...infer Rest]
    ? First extends Search
        ? New extends [infer NewFirst, ...infer NewRest]
            ? [NewFirst, ...ReplaceSeq<Search, NewRest, Rest>]
            : [First, ...ReplaceSeq<Search, New, Rest>]
        : [First, ...ReplaceSeq<Search, New, Rest>]
    : Parts;

export type PrefixedKeys<Prefix extends string, Rec> = UnionToIntersection<
    {
        [K in Extract<keyof Rec, string>]: { [_ in `${Prefix}${K}`]: Rec[K] };
    }[Extract<keyof Rec, string>]
>;

export type Counter<N extends number, R extends 0[] = []> = N extends R["length"] ? R : Counter<N, [0, ...R]>;

type DropLeft_<Parts, N> = Parts extends [any, ...infer Rest]
    ? N extends [0, ...infer N2]
        ? DropLeft_<Rest, N2>
        : Parts
    : Parts;

export type DropLeft<Parts, N extends number> = DropLeft_<Parts, Counter<N>>;
