import { either as E, function as F, json as J } from "fp-ts";
import * as io from "io-ts";
import { createContext, createEffect, createSignal, onMount, ParentComponent } from "solid-js";
import { createStore, SetStoreFunction } from "solid-js/store";

export enum UnreadBadgeStyle {
    Numbered = "numbered",
    Dot = "dot",
    None = "none",
}

export enum MessageListLayout {
    Bubbles = "bubbles",
    Linear = "linear",
}

export enum TypingIndicatorMode {
    ViewAndSend = "viewAndSend",
    View = "view",
    Disabled = "disabled",
}

export enum AttachmentDetectionMode {
    Automatic = "auto",
    OnDemand = "onDemand",
    Disabled = "disabled",
}

export const UserConfigC = io.partial({
    locale: io.string,
    unreadBadge: io.union([
        io.literal(UnreadBadgeStyle.Numbered),
        io.literal(UnreadBadgeStyle.Dot),
        io.literal(UnreadBadgeStyle.None),
    ]),
    mentionBadge: io.boolean,
    messagePreviews: io.boolean,
    lastMessageDates: io.boolean,
    autoReadNotifications: io.boolean,
    messageListLayout: io.union([io.literal(MessageListLayout.Bubbles), io.literal(MessageListLayout.Linear)]),
    attachmentDetection: io.union([
        io.literal(AttachmentDetectionMode.Automatic),
        io.literal(AttachmentDetectionMode.OnDemand),
        io.literal(AttachmentDetectionMode.Disabled),
    ]),
    typingIndicator: io.union([
        io.literal(TypingIndicatorMode.ViewAndSend),
        io.literal(TypingIndicatorMode.View),
        io.literal(TypingIndicatorMode.Disabled),
    ]),
    channelSidebar: io.boolean,
    userSidebar: io.boolean,
    networkSidebar: io.boolean,
});

export interface UserConfig {
    locale?: string;
    unreadBadge: UnreadBadgeStyle;
    messagePreviews: boolean;
    lastMessageDates: boolean;
    mentionBadge: boolean;
    autoReadNotifications: boolean;
    messageListLayout: MessageListLayout;
    attachmentDetection: AttachmentDetectionMode;
    typingIndicator: TypingIndicatorMode;
    channelSidebar: boolean;
    userSidebar: boolean;
    networkSidebar: boolean;
}

const userDefaults: UserConfig = {
    unreadBadge: UnreadBadgeStyle.Dot,
    messagePreviews: true,
    lastMessageDates: true,
    mentionBadge: true,
    autoReadNotifications: false,
    messageListLayout: MessageListLayout.Bubbles,
    attachmentDetection: AttachmentDetectionMode.OnDemand,
    typingIndicator: TypingIndicatorMode.ViewAndSend,
    channelSidebar: true,
    userSidebar: true,
    networkSidebar: true,
};

export const UserConfig = createContext<[config: UserConfig, setConfig: SetStoreFunction<UserConfig>]>([
    userDefaults,
    () => void 0,
]);

export const UserConfigProvider: ParentComponent = (props) => {
    const [form, setForm] = createStore(userDefaults);
    const [init, setInit] = createSignal(false);

    createEffect(() => init() && localStorage.setItem("kitsune-irc:user", JSON.stringify(form)));

    onMount(() => {
        const userConfig = F.pipe(
            localStorage.getItem("kitsune-irc:user") ?? "",
            J.parse,
            E.chainW(UserConfigC.decode),
        );

        if (E.isRight(userConfig)) {
            setForm(userConfig.right);
        }

        setInit(true);
    });

    return <UserConfig.Provider value={[form, setForm]}>{props.children}</UserConfig.Provider>;
};
