import { useMatch, useParams } from "@solidjs/router";
import { isThisYear, isToday } from "date-fns";
import {
    array as A,
    boolean as B,
    date as D,
    function as F,
    nonEmptyArray as NEA,
    option as O,
    ord as Or,
    string as S,
} from "fp-ts";
import { Component, createMemo, For, JSX, Match, on, Show, Switch, useContext } from "solid-js";
import { match, P } from "ts-pattern";

import { useOnDemand } from "@/iro/hooks/onDemand";
import { ActionButton } from "@/iro/objects/ActionButton";
import { Avatar } from "@/iro/objects/Avatar";
import { Badge } from "@/iro/objects/Badge";
import { Menu, MenuHeader, MenuLink } from "@/iro/objects/Menu";
import { StatusIndicator } from "@/iro/objects/StatusIndicator";
import { Time } from "@/iro/objects/Time";
import { ClientStatus } from "@/lib/client/client";
import { useStore, useStores } from "@/lib/exome/solid";
import { renderModes } from "@/lib/irc/modes";
import { isMatchingN } from "@/lib/ts-pattern/util";
import { Narrow } from "@/lib/unionTypes";
import * as routes from "@/routes";
import { ChannelBuffer } from "@/store/buffer/channelBuffer";
import { ServerBuffer } from "@/store/buffer/serverBuffer";
import { UserBuffer } from "@/store/buffer/userBuffer";
import { RenderableMessage, TrafficLeave } from "@/store/messageList";
import { networkList as networkListStore } from "@/store/networkList";
import { Notification } from "@/store/notificationList";
import { User } from "@/store/user";
import { UnreadBadgeStyle, UserConfig } from "@/userConfig";

import { Footer } from "../layouts/Footer";
import { Header } from "../layouts/Header";
import { Sidebar } from "../layouts/Sidebar";
import { ChannelBufferMenu } from "../menus/ChannelBuffer";
import { SelfMenu } from "../menus/Self";
import { ServerBufferMenu } from "../menus/ServerBuffer";
import { UserBufferMenu } from "../menus/UserBuffer";
import { ExploreDialog } from "../modals/Explore";
import { JoinDialog } from "../modals/Join";
import { NotificationsDialog } from "../modals/Notifications";
import { Icon } from "../objects/Icon";
import { DynamicUserAvatar } from "../objects/UserAvatar";
import { UserName } from "../objects/UserName";
import { UserCard } from "./UserCard";

const userBufferOrd: Or.Ord<UserBuffer> = F.pipe(
    S.Ord,
    Or.contramap((buffer) => buffer.id),
);

const userBufferByTimeOrd: Or.Ord<UserBuffer> = F.pipe(
    O.getOrd(D.Ord),
    Or.reverse,
    Or.contramap((buffer) => O.fromNullable(buffer.messages.lastMessage?.value.time)),
);

const channelBufferOrd: Or.Ord<ChannelBuffer> = F.pipe(
    S.Ord,
    Or.contramap((buffer) => buffer.id),
);

const serverBufferOrdType: Or.Ord<ServerBuffer> = F.pipe(
    B.Ord,
    Or.reverse,
    Or.contramap((buffer) => buffer.network.isRoot),
);

const serverBufferOrdName: Or.Ord<ServerBuffer> = F.pipe(
    S.Ord,
    Or.contramap((buffer) => buffer.network.name),
);

const serverBufferOrd = Or.getSemigroup<ServerBuffer>().concat(serverBufferOrdType, serverBufferOrdName);

const statusIconMap = {
    [ClientStatus.Connecting]: "loading",
    [ClientStatus.Registering]: "loading",
    [ClientStatus.Registered]: "loading",
    [ClientStatus.Disconnected]: "server-slash",
    [ClientStatus.Connected]: undefined,
};

const TrafficMessage: Component<{ message: Narrow<RenderableMessage, "Traffic"> }> = (props) => {
    const last = createMemo(() => O.toUndefined(A.last(Object.values(props.message.value.traffic))));

    return (
        <Show when={last()}>
            {(last) => (
                <Switch>
                    <Match when={last().join}>{last().source.nickname} joined</Match>
                    <Match when={last().leave === TrafficLeave.Part}>{last().source.nickname} left</Match>
                    <Match when={last().leave === TrafficLeave.Quit}>{last().source.nickname} quit</Match>
                </Switch>
            )}
        </Show>
    );
};

const ModeMessage: Component<{ message: Narrow<RenderableMessage, "Mode"> }> = (props) => {
    const changes = createMemo(() => renderModes(props.message.value.changes));

    return (
        <>
            {props.message.value.source.nickname} set modes: {changes()[0]} {changes()[1].join(" ")}
        </>
    );
};

const Message: Component<{ message: RenderableMessage }> = (props) => {
    const user = useStore(() => ("source" in props.message.value ? props.message.value.source.user : undefined));

    return (
        <Switch>
            <Match when={isMatchingN(RenderableMessage.Motd.select(), props.message)}>
                {(msg) => <>MOTD: {msg().value.raw}</>}
            </Match>
            <Match when={isMatchingN(RenderableMessage.Info.select(), props.message)}>
                {(msg) => <>Info: {msg().value.content[0]}</>}
            </Match>
            <Match when={isMatchingN(RenderableMessage.Traffic.select(), props.message)}>
                {(msg) => <TrafficMessage message={msg()} />}
            </Match>
            <Match when={isMatchingN(RenderableMessage.Nick.select(), props.message)}>
                {(msg) => (
                    <>
                        {NEA.last(msg().value.changes).source.nickname} is now {NEA.last(msg().value.changes).nickname}
                    </>
                )}
            </Match>
            <Match when={isMatchingN(RenderableMessage.Topic.select(), props.message)}>
                {(msg) => (
                    <>
                        {msg().value.source.nickname} set topic: {msg().value.raw}
                    </>
                )}
            </Match>
            <Match when={isMatchingN(RenderableMessage.Mode.select(), props.message)}>
                {(msg) => <ModeMessage message={msg()} />}
            </Match>
            <Match when={isMatchingN(RenderableMessage.Message.select(), props.message)}>
                {(msg) => (
                    <>
                        {user()?.whox.nickname ?? msg().value.source.nickname}: {msg().value.raw}
                    </>
                )}
            </Match>
            <Match when={isMatchingN(RenderableMessage.Reply.select(), props.message)}>
                {(msg) => (
                    <>
                        {msg().value.code}: {msg().value.params.join(", ")}
                    </>
                )}
            </Match>
        </Switch>
    );
};

const SidebarItem: Component<{ buffer: ChannelBuffer | ServerBuffer | UserBuffer }> = (props) => {
    const [userConfig] = useContext(UserConfig);
    const [createMenu, menuVisible] = useOnDemand();

    const buffer = useStore(() => props.buffer);
    const messages = useStore(() => buffer().messages);
    const network = useStore(() => buffer().network);
    const notifications = useStore(() => network().notifications);

    const href = () => routes.chat(network().id, encodeURIComponent(buffer().id));
    const hrefMatch = useMatch(href);

    const dateFormat = (date: Date): Intl.DateTimeFormatOptions | undefined =>
        isToday(date)
            ? { minute: "2-digit", hour: "2-digit" }
            : isThisYear(date)
            ? { month: "short", day: "numeric" }
            : { year: "numeric", month: "short", day: "numeric" };

    const handleContextMenu = (e: MouseEvent) => {
        e.preventDefault();

        match(props.buffer)
            .with(P.instanceOf(UserBuffer), (buffer: UserBuffer) =>
                createMenu((close) => (
                    <UserBufferMenu buffer={buffer} anchor={{ x: e.pageX, y: e.pageY }} onClose={close} />
                )),
            )
            .with(P.instanceOf(ChannelBuffer), (buffer: ChannelBuffer) =>
                createMenu((close) => (
                    <ChannelBufferMenu buffer={buffer} anchor={{ x: e.pageX, y: e.pageY }} onClose={close} />
                )),
            )
            .with(P.instanceOf(ServerBuffer), (buffer: ServerBuffer) =>
                createMenu((close) => (
                    <ServerBufferMenu buffer={buffer} anchor={{ x: e.pageX, y: e.pageY }} onClose={close} />
                )),
            )
            .run();
    };

    const mentioned = createMemo(() =>
        notifications().grouped.unread.some((notification) => {
            if (!Notification.Mention.is(notification)) {
                return;
            }
            if (notification.value.channel) {
                return notification.value.channel === buffer().id;
            } else {
                return notification.value.user.whox.nickname === buffer().id;
            }
        }),
    );

    const indicator = () => (
        <Switch>
            <Match when={mentioned() && userConfig.mentionBadge}>
                <Badge tag="strong" variant="primary" pill size="xs">
                    @
                </Badge>
            </Match>
            <Match when={!hrefMatch() && messages().numUnread}>
                <Switch>
                    <Match when={userConfig.unreadBadge === UnreadBadgeStyle.Numbered}>
                        <Badge tag="strong" variant="primary" pill size="xs">
                            {messages().numUnread}
                        </Badge>
                    </Match>
                    <Match when={userConfig.unreadBadge === UnreadBadgeStyle.Dot}>
                        <StatusIndicator status="primary" />
                    </Match>
                </Switch>
            </Match>
        </Switch>
    );

    return (
        <MenuLink
            href={href()}
            pre={
                <Switch>
                    <Match when={isMatchingN(P.instanceOf(ServerBuffer), buffer())}>
                        <Avatar size="75">
                            <Icon id={statusIconMap[network().status] ?? "server"} />
                        </Avatar>
                    </Match>
                    <Match when={isMatchingN(P.instanceOf(ChannelBuffer), buffer())}>
                        <Avatar size="75">
                            <Icon id="hash" />
                        </Avatar>
                    </Match>
                    <Match when={isMatchingN(P.instanceOf(UserBuffer), buffer())}>
                        {(buffer) => <DynamicUserAvatar size="75" user={buffer().user} showStatus colored />}
                    </Match>
                </Switch>
            }
            selected={menuVisible()}
            onContextMenu={handleContextMenu}
        >
            <div class="l-media l-media--flush">
                <strong class="l-media__block l-media__block--main u-elp">
                    <Switch>
                        <Match when={isMatchingN(P.instanceOf(ServerBuffer), buffer())}>{network().name}</Match>
                        <Match when={isMatchingN(P.instanceOf(ChannelBuffer), buffer())}>{buffer().id.slice(1)}</Match>
                        <Match when={isMatchingN(P.instanceOf(UserBuffer), buffer())}>
                            {(buffer) => <UserName user={buffer().user} />}
                        </Match>
                    </Switch>
                </strong>
                <Show when={userConfig.lastMessageDates && messages().lastMessage?.value.time}>
                    {(lastDate) => (
                        <small class="l-media__block">
                            <Time date={lastDate()} format={dateFormat} />
                        </small>
                    )}
                </Show>
                <Show when={!userConfig.messagePreviews}>
                    <div class="l-media__block">{indicator()}</div>
                </Show>
            </div>
            <div class="l-media l-media--flush">
                <Show when={userConfig.messagePreviews}>
                    <small class="l-media__block l-media__block--main u-elp">
                        <Show when={messages().lastMessage}>{(message) => <Message message={message()} />}</Show>
                    </small>
                    <div class="l-media__block">{indicator()}</div>
                </Show>
            </div>
        </MenuLink>
    );
};

const Me: Component<{ me: User }> = (props) => {
    const [userConfig] = useContext(UserConfig);
    const [onDemand] = useOnDemand();

    const networkList = useStore(() => networkListStore);
    const networks = useStores(() => Array.from(networkList().networks.values()));
    const notificationLists = useStores(() => networks().map((network) => network.notifications));

    const notificationCount = createMemo(() => notificationLists().reduce((sum, list) => sum + list.numUnread, 0));

    const viewNotificationsModal = () => {
        onDemand((close) => <NotificationsDialog onClose={close} />);
    };
    const handleUserClick: JSX.EventHandler<HTMLButtonElement, MouseEvent> = (e) => {
        const target = e.currentTarget;
        onDemand((close) => <SelfMenu user={props.me} anchor={target} align="up-left" onClose={close} />);
    };

    return (
        <Footer side class="l-media u-bt-1">
            <div class="l-media__block l-media__block--main">
                <UserCard user={props.me}>
                    <div class="l-footer__button-end l-media__block">
                        <ActionButton quiet round onClick={viewNotificationsModal}>
                            <Icon id="bell" />
                            <Show when={notificationCount()}>
                                <Switch>
                                    <Match when={userConfig.unreadBadge === UnreadBadgeStyle.Numbered}>
                                        <Badge
                                            tag="strong"
                                            variant="primary"
                                            pill
                                            size="xs"
                                            style={{
                                                position: "relative",
                                                top: "-.8em",
                                            }}
                                        >
                                            {notificationCount()}
                                        </Badge>
                                    </Match>
                                    <Match when={userConfig.unreadBadge === UnreadBadgeStyle.Dot}>
                                        <StatusIndicator
                                            status="primary"
                                            style={{
                                                position: "relative",
                                                top: "-.7em",
                                            }}
                                        />
                                    </Match>
                                </Switch>
                            </Show>
                        </ActionButton>{" "}
                        <ActionButton quiet round onClick={handleUserClick}>
                            <Icon id="ellipsis-v" />
                        </ActionButton>
                    </div>
                </UserCard>
            </div>
        </Footer>
    );
};

export const MainSidebar: Component = () => {
    const [userConfig] = useContext(UserConfig);
    const [onDemand] = useOnDemand();
    const params = useParams();

    const networkId = () => (params.network ? decodeURIComponent(params.network) : "");

    const networkList = useStore(() => networkListStore);
    const networks = useStores(() => Array.from(networkList().networks.values()));
    const bufferLists = useStores(() => networks().flatMap((network) => network.buffers));

    const messages = useStores(() =>
        bufferLists()
            .flatMap((bufferList) =>
                Array.from(bufferList.buffers.values()).filter(
                    (buffer): buffer is UserBuffer => buffer instanceof UserBuffer,
                ),
            )
            .map((buffer) => buffer.messages),
    );

    const userBuffers = createMemo(
        on([bufferLists, messages], ([bufferLists]) =>
            bufferLists
                .flatMap((bufferList) =>
                    Array.from(bufferList.buffers.values()).filter(
                        (buffer): buffer is UserBuffer => buffer instanceof UserBuffer,
                    ),
                )
                .sort((userConfig.lastMessageDates ? userBufferByTimeOrd : userBufferOrd).compare),
        ),
    );
    const channelBuffers = createMemo(() =>
        bufferLists()
            .flatMap((bufferList) =>
                Array.from(bufferList.buffers.values()).filter(
                    (buffer): buffer is ChannelBuffer => buffer instanceof ChannelBuffer,
                ),
            )
            .sort(channelBufferOrd.compare),
    );
    const serverBuffers = createMemo(() =>
        networks()
            .map((network) => network.serverBuffer)
            .sort(serverBufferOrd.compare),
    );

    const network = useStore(() => {
        const id = networkId();
        return id ? networkList().get(id) : undefined;
    });

    const viewExploreModal = () => {
        onDemand((close) => <ExploreDialog onClose={close} />);
    };
    const viewJoinModal = () => {
        onDemand((close) => <JoinDialog onClose={close} />);
    };

    return (
        <Sidebar location="left">
            <Header side theme="hi">
                <div class="l-media__block l-media__block--main">
                    <strong>Kitsune IRC</strong>
                </div>
                <div class="l-media__block l-header__button-end">
                    <ActionButton quiet round onClick={viewExploreModal}>
                        <Icon id="compass" />
                    </ActionButton>{" "}
                    <ActionButton quiet round onClick={viewJoinModal}>
                        <Icon id="message-plus" />
                    </ActionButton>
                </div>
            </Header>

            <div class="l-sidebar__content">
                <Menu pull>
                    <Show when={userBuffers().length}>
                        <MenuHeader>Conversations</MenuHeader>
                        <For each={userBuffers()}>{(buffer) => <SidebarItem buffer={buffer} />}</For>
                    </Show>

                    <Show when={channelBuffers().length}>
                        <MenuHeader>Channels</MenuHeader>
                        <For each={channelBuffers()}>{(buffer) => <SidebarItem buffer={buffer} />}</For>
                    </Show>

                    <Show when={serverBuffers().length}>
                        <MenuHeader>Networks</MenuHeader>
                        <For each={serverBuffers()}>{(buffer) => <SidebarItem buffer={buffer} />}</For>
                    </Show>
                </Menu>
            </div>

            <Show when={network()?.me}>{(me) => <Me me={me()} />}</Show>
        </Sidebar>
    );
};
