import { function as F, readonlyNonEmptyArray as RNEA, string as S } from "fp-ts";

import { NetworkAttributes, renderNetworkAttributes } from "./bouncer";
import { formatIrcDate } from "./date";
import { RawMessage, rawMessage } from "./rawMessage";
import { Typing } from "./typing";

function nonempty(s: string) {
    return s ? [s] : [];
}

function defined(s?: string) {
    return s !== undefined ? [s] : [];
}

export function privmsg(target: string, msg: string, replyTo?: string) {
    return rawMessage("PRIVMSG", [target, msg], replyTo ? { "+draft/reply": replyTo } : {});
}

export function cprivmsg(nickname: string, chan: string, msg: string) {
    return rawMessage("CPRIVMSG", [nickname, chan, msg]);
}

export function cnotice(nickname: string, chan: string, msg: string) {
    return rawMessage("CNOTICE", [nickname, chan, msg]);
}

export function knock(chan: string, msg = "") {
    return rawMessage("KNOCK", [chan, ...nonempty(msg)]);
}

export function notice(target: string, msg: string) {
    return rawMessage("NOTICE", [target, msg]);
}

export function mode(target: string, params: string[]) {
    return rawMessage("MODE", [target, ...params]);
}

export function names(target: string) {
    return rawMessage("NAMES", [target]);
}

export function monitorAdd(targets: string[]) {
    return rawMessage("MONITOR", ["+", ...targets]);
}

export function monitorRemove(targets: string[]) {
    return rawMessage("MONITOR", ["-", ...targets]);
}

export function monitorClear() {
    return rawMessage("MONITOR", ["C"]);
}

export function monitorList() {
    return rawMessage("MONITOR", ["L"]);
}

export function monitorShow() {
    return rawMessage("MONITOR", ["S"]);
}

export function whois(params: string[]) {
    return rawMessage("WHOIS", params);
}

export function whowas(params: string[]) {
    return rawMessage("WHOWAS", params);
}

export function who(mask: string, fields?: string, token?: string) {
    const params = [mask];

    if (fields) {
        if (!fields.startsWith("%")) {
            fields = `%${fields}`;
        }
        if (token) {
            params.push(`${fields},${token}`);

            if (!fields.includes("t")) {
                fields += "t";
            }
        } else {
            params.push(`${fields}`);
        }
    }

    return rawMessage("WHO", params);
}

export function wallops(msg: string) {
    return rawMessage("WHOWAS", [msg]);
}

export function nick(nickname: string) {
    return rawMessage("NICK", [nickname]);
}

export function part(chan: string, msg = "") {
    return rawMessage("PART", [chan, ...nonempty(msg)]);
}

export function join(chan: string, key?: string) {
    return rawMessage("JOIN", [chan, ...defined(key)]);
}

export function invite(nickname: string, channel: string) {
    return rawMessage("INVITE", [nickname, channel]);
}

export function topic(chan: string, topic = "") {
    return rawMessage("TOPIC", [chan, ...nonempty(topic)]);
}

export function kick(chan: string, nickname: string, reason = "") {
    return rawMessage("KICK", [chan, nickname, ...nonempty(reason)]);
}

export function kill(client: string, reason = "") {
    return rawMessage("KILL", [client, ...nonempty(reason)]);
}

export function kline(mins: string, mask: string, reason: string) {
    return rawMessage("KLINE", [mins, mask, reason]);
}

export function unkline(mask: string, server = "") {
    return rawMessage("UNKLINE", [mask, ...(server ? ["ON", server] : [])]);
}

export function undline(mask: string, server = "") {
    return rawMessage("UNDLINE", [mask, ...(server ? ["ON", server] : [])]);
}

export function unxline(mask: string, server = "") {
    return rawMessage("UNXLINE", [mask, ...(server ? ["ON", server] : [])]);
}

export function unresv(mask: string, server = "") {
    return rawMessage("UNRESV", [mask, ...(server ? ["ON", server] : [])]);
}

export function testline(mask: string) {
    return rawMessage("TESTLINE", [mask]);
}

export function testmask(mask: string, gecos = "") {
    return rawMessage("TESTMASK", [mask, ...nonempty(gecos)]);
}

export function masktrace(mask: string, gecos: string) {
    return rawMessage("MASKTRACE", [mask, gecos]);
}

export function chantrace(chan: string) {
    return rawMessage("CHANTRACE", [chan]);
}

export function etrace(arg = "") {
    return rawMessage("ETRACE", nonempty(arg));
}

export function remove(chan: string, nickname: string, reason: string) {
    return rawMessage("REMOVE", [chan, nickname, ...nonempty(reason)]);
}

export function quit(reason: string) {
    return rawMessage("QUIT", [reason]);
}

export function pass(password: string) {
    return rawMessage("PASS", [password]);
}

export function list(params: string[]) {
    return rawMessage("LIST", params);
}

export function ping(params: string[]) {
    return rawMessage("PING", params);
}

export function pong(params: string[]) {
    return rawMessage("PONG", params);
}

export function ison(nicknames: string[]) {
    return rawMessage("ISON", [nicknames.join(" ")]);
}

export function time(server = "") {
    return rawMessage("TIME", nonempty(server));
}

export function userhost(params: string[]) {
    return rawMessage("USERHOST", params);
}

export function userip(params: string[]) {
    return rawMessage("USERIP", params);
}

export function users(server = "") {
    return rawMessage("USERS", nonempty(server));
}

export function stats(params: string[]) {
    return rawMessage("STATS", params);
}

export function oper(username: string, password: string) {
    return rawMessage("OPER", [username, password]);
}

export function links(params: string[]) {
    return rawMessage("LINKS", params);
}

export function away(message: string) {
    return rawMessage("AWAY", nonempty(message));
}

export function map() {
    return rawMessage("MAP");
}

export function info() {
    return rawMessage("INFO");
}

export function rules(server = "") {
    return rawMessage("RULES", nonempty(server));
}

export function version(server = "") {
    return rawMessage("VERSION", nonempty(server));
}

export function lusers(params: string[]) {
    return rawMessage("LUSERS", params);
}

export function motd(server = "") {
    return rawMessage("MOTD", nonempty(server));
}

export function admin(target = "") {
    return rawMessage("ADMIN", nonempty(target));
}

export function trace(params: string[]) {
    return rawMessage("TRACE", params);
}

export function user(username: string, realname: string) {
    return rawMessage("USER", [username, "0", "*", realname]);
}

export function setname(realname: string) {
    return rawMessage("SETNAME", [realname]);
}

export function help(subject = "") {
    return rawMessage("HELP", nonempty(subject));
}

export function capReq(caps: string[]) {
    return rawMessage("CAP", ["REQ", caps.join(" ")]);
}

export function capEnd() {
    return rawMessage("CAP", ["END"]);
}

export function capLs() {
    return rawMessage("CAP", ["LS", "302"]);
}

export function capList() {
    return rawMessage("CAP", ["LIST"]);
}

export function register(account: string | undefined, email: string | undefined, password: string) {
    return rawMessage("REGISTER", [account ? account : "*", email ? email : "*", password]);
}

export function verify(account: string, code: string) {
    return rawMessage("VERIFY", [account, code]);
}

export function authenticate(mechanism: string) {
    return rawMessage("AUTHENTICATE", [mechanism]);
}

export function authenticates(payload: string): readonly RawMessage[] {
    return F.pipe(
        payload,
        S.split(""),
        RNEA.chunksOf(400),
        RNEA.map((pl) => rawMessage("AUTHENTICATE", [pl.join("")])),
    );
}

export function bouncerBind(id: string) {
    return rawMessage("BOUNCER", ["BIND", id]);
}

export function bouncerListNetworks() {
    return rawMessage("BOUNCER", ["LISTNETWORKS"]);
}

export function bouncerAddNetwork(attrs: Partial<Omit<NetworkAttributes, "state">> & Pick<NetworkAttributes, "host">) {
    return rawMessage("BOUNCER", ["ADDNETWORK", renderNetworkAttributes(attrs)]);
}

export function bouncerChangeNetwork(id: string, attrs: Partial<Omit<NetworkAttributes, "state">>) {
    return rawMessage("BOUNCER", ["CHANGENETWORK", id, renderNetworkAttributes(attrs)]);
}

export function bouncerDelNetwork(id: string) {
    return rawMessage("BOUNCER", ["DELNETWORK", id]);
}

export function historyBefore(target: string, anchor: Date | string, limit: number) {
    return rawMessage("CHATHISTORY", [
        "BEFORE",
        target,
        anchor instanceof Date ? `timestamp=${formatIrcDate(anchor)}` : `msgid=${anchor}`,
        `${limit}`,
    ]);
}

export function historyAfter(target: string, anchor: Date | string, limit: number) {
    return rawMessage("CHATHISTORY", [
        "AFTER",
        target,
        anchor instanceof Date ? `timestamp=${formatIrcDate(anchor)}` : `msgid=${anchor}`,
        `${limit}`,
    ]);
}

export function historyLatest(target: string, anchor: Date | string, limit: number) {
    return rawMessage("CHATHISTORY", [
        "LATEST",
        target,
        anchor instanceof Date ? `timestamp=${formatIrcDate(anchor)}` : anchor === "*" ? anchor : `msgid=${anchor}`,
        `${limit}`,
    ]);
}

export function historyAround(target: string, anchor: Date | string, limit: number) {
    return rawMessage("CHATHISTORY", [
        "AROUND",
        target,
        anchor instanceof Date ? `timestamp=${formatIrcDate(anchor)}` : `msgid=${anchor}`,
        `${limit}`,
    ]);
}

export function historyBetween(target: string, after: Date | string, before: Date | string, limit: number) {
    return rawMessage("CHATHISTORY", [
        "BETWEEN",
        target,
        after instanceof Date ? `timestamp=${formatIrcDate(after)}` : `msgid=${after}`,
        before instanceof Date ? `timestamp=${formatIrcDate(before)}` : `msgid=${before}`,
        `${limit}`,
    ]);
}

export function historyTargets(after: Date, before: Date, limit: number) {
    return rawMessage("CHATHISTORY", [
        "TARGETS",
        `timestamp=${formatIrcDate(after)}`,
        `timestamp=${formatIrcDate(before)}`,
        `${limit}`,
    ]);
}

export function search(
    query: { in?: string; from?: string; after?: Date; before?: Date; text: string },
    limit?: number,
) {
    const params = [];

    for (const [key, value] of Object.entries({ ...query, ...(limit ? { limit: `${limit}` } : {}) })) {
        params.push(`${key}=${value instanceof Date ? formatIrcDate(value) : value}`);
    }

    return rawMessage("SEARCH", [params.join(";")]);
}

export function markRead(target: string, timestamp?: Date) {
    return rawMessage("MARKREAD", [
        target,
        ...defined(timestamp ? `timestamp=${formatIrcDate(timestamp)}` : undefined),
    ]);
}

export function typing(target: string, typing: Typing) {
    return rawMessage("TAGMSG", [target], { "+typing": typing });
}
