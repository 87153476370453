import { isAfter } from "date-fns";
import { Exome } from "exome";
import { either as E, function as F, json as J } from "fp-ts";
import { date as D, option as O } from "fp-ts";
import * as io from "io-ts";
import { DateFromISOString } from "io-ts-types/DateFromISOString";

import { createIndexFinder } from "@/lib/array";
import { impl, Variant } from "@/lib/unionTypes";

import { Network } from "./network";
import { User } from "./user";

export interface NotificationBase {
    time: Date;
}

export type Notification =
    | Variant<"Invite", NotificationBase & { user: User; channel: string }>
    | Variant<"Mention", NotificationBase & { user: User; channel?: string; message: string; keyword?: string }>;

export const Notification = impl<Notification>();

export const UserFromNicknameC = (network: Network) =>
    new io.Type<User, string, unknown>(
        "UserFromNickname",
        (u): u is User => u instanceof User,
        (u, c) =>
            F.pipe(
                io.string.validate(u, c),
                E.chain((nickname) => io.success(network.users.upsert(nickname))),
            ),
        (u) => u.whox.nickname,
    );

export const NotificationBaseC = io.type({
    time: DateFromISOString,
});

export const NotificationC = (network: Network) =>
    io.union([
        io.type({
            type: io.literal("Invite"),
            value: io.intersection([
                NotificationBaseC,
                io.type({
                    user: UserFromNicknameC(network),
                    channel: io.string,
                }),
            ]),
        }),
        io.type({
            type: io.literal("Mention"),
            value: io.intersection([
                NotificationBaseC,
                io.type({
                    user: UserFromNicknameC(network),
                    message: io.string,
                }),
                io.partial({
                    channel: io.string,
                    keyword: io.string,
                }),
            ]),
        }),
    ]);

export const NotificationListC = (network: Network) =>
    io.intersection([
        io.type({
            notifications: io.array(NotificationC(network)),
        }),
        io.partial({
            lastRead: DateFromISOString,
        }),
    ]);

const findInsertionIndex = createIndexFinder(
    (item: { value: { time: Date } }) => item.value.time,
    (a, b) => a <= b,
);

export class NotificationList extends Exome {
    notifications: Notification[] = [];

    lastRead?: Date;

    private codec = NotificationListC(this.network);

    constructor(readonly network: Network) {
        super();
        this.load();
    }

    private execSetLastRead(timestamp?: Date) {
        this.lastRead = timestamp;
        this.save();
    }

    private execInsert(notifications: Notification[]) {
        for (const notification of notifications) {
            const i = findInsertionIndex(notification, this.notifications);
            this.notifications.splice(i + 1, 0, notification);
        }
        this.save();
    }

    private save = () => {
        const saveNotifications = this.codec.encode({
            lastRead: this.lastRead,
            notifications: Array.from(this.notifications.values()),
        });

        localStorage.setItem(`kitsune-irc:network:${this.network.id}:notifications`, JSON.stringify(saveNotifications));
    };

    load = () => {
        const notificationList = F.pipe(
            localStorage.getItem(`kitsune-irc:network:${this.network.id}:notifications`) ?? "",
            J.parse,
            E.chainW(this.codec.decode),
        );

        if (E.isRight(notificationList)) {
            this.setLastRead(notificationList.right.lastRead);
            this.insert(notificationList.right.notifications);
        }
    };

    setLastRead = (timestamp?: Date) => {
        if (!this.lastRead || !O.getEq(D.Eq).equals(O.fromNullable(this.lastRead), O.fromNullable(timestamp))) {
            this.execSetLastRead(timestamp);
        }
    };

    insert = (notifications: Notification[]) => {
        if (notifications.length) {
            this.execInsert(notifications);
        }
    };

    get numUnread() {
        if (!this.lastRead) {
            return this.notifications.length;
        }

        let n = 0;

        for (let i = this.notifications.length - 1; i >= 0; --i) {
            const message = this.notifications[i]!;

            if (!isAfter(message.value.time, this.lastRead)) {
                break;
            }

            ++n;
        }

        return n;
    }

    get grouped() {
        const read: Notification[] = [];
        const unread: Notification[] = [];

        for (const notification of this.notifications) {
            if (!this.lastRead || isAfter(notification.value.time, this.lastRead)) {
                unread.push(notification);
            } else {
                read.push(notification);
            }
        }

        return { read, unread };
    }
}
