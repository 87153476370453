import { useIntl } from "@cookbook/solid-intl";
import { Component, For, JSX, mapArray, onMount, Show } from "solid-js";
import { createStore } from "solid-js/store";

import { CHANNEL_MODE_MAP, MODE_LIST_ICONS } from "@/data/mode";
import { FormItem } from "@/iro/layouts/Form";
import { PageDialog } from "@/iro/objects/PageDialog";
import { TextField } from "@/iro/objects/TextField";
import { Time } from "@/iro/objects/Time";
import { useStore } from "@/lib/exome/solid";
import { ModeChange } from "@/lib/irc/modes";
import { ChannelBuffer } from "@/store/buffer/channelBuffer";

import { Icon } from "../objects/Icon";
import { ModeEditor } from "../objects/ModeEditor";
import { ModeList } from "../objects/ModeList";

export const ChannelDialog: Component<{
    onClose?: (trigger?: string) => void;
    buffer: ChannelBuffer;
}> = (props) => {
    const intl = useIntl();

    const buffer = useStore(() => props.buffer);
    const network = useStore(() => buffer().network);

    const [form, setForm] = createStore<{
        topic: string;
        modes: Map<string, string | undefined>;
    }>({
        topic: "",
        modes: new Map(),
    });

    const handleClose = async (result?: string) => {
        if (result === "ok") {
            if (form.topic !== buffer().rawTopic) {
                await buffer().topicCmd(form.topic);
            }

            const modes = new Set([...form.modes.keys(), ...buffer().modes.keys()]);
            const modeChanges: ModeChange[] = [];

            for (const mode of modes) {
                if (buffer().modes.has(mode) && !form.modes.has(mode)) {
                    modeChanges.push({ add: false, mode });
                } else if (!buffer().modes.has(mode) && form.modes.has(mode)) {
                    modeChanges.push({ add: true, mode, param: form.modes.get(mode) });
                } else if (buffer().modes.has(mode) && buffer().modes.get(mode) !== form.modes.get(mode)) {
                    modeChanges.push({ add: true, mode, param: form.modes.get(mode) });
                }
            }

            await buffer().modeCmd(modeChanges);
        }

        props.onClose?.(result);
    };

    const lists = mapArray(
        () => network().modes.lists.split(""),
        (mode) => {
            const description =
                mode in CHANNEL_MODE_MAP
                    ? intl.formatMessage(CHANNEL_MODE_MAP[mode as keyof typeof CHANNEL_MODE_MAP])
                    : undefined;

            return {
                mode,
                description,
            };
        },
    );

    const setTopicHandler: JSX.EventHandler<HTMLInputElement, InputEvent> = (e) =>
        setForm("topic", e.currentTarget.value);

    const setModesHandler: JSX.EventHandler<never, CustomEvent<Map<string, string | undefined>>> = (e) =>
        setForm("modes", e.detail);

    onMount(() => {
        setForm({
            topic: buffer().rawTopic,
            modes: new Map(buffer().modes),
        });
    });

    return (
        <PageDialog
            title={buffer().id}
            onClose={handleClose}
            okLabel={intl.formatMessage({
                id: "dialog.channel.ok",
                defaultMessage: "Apply",
            })}
            scrollable
        >
            <PageDialog.Category>
                <PageDialog.Page id="overview" title="Overview" icon={<Icon id="hash" />}>
                    <div class="l-form l-form--labels-left">
                        <FormItem label="Topic">
                            <TextField value={form.topic} onInput={setTopicHandler} />
                        </FormItem>

                        <Show when={buffer().createdAt}>
                            {(createdAt) => (
                                <FormItem tag="div" label="Created">
                                    <Time date={createdAt()} />
                                </FormItem>
                            )}
                        </Show>

                        <Show when={buffer().modes.size}>
                            <FormItem tag="div" label="Modes">
                                <ModeList scope="channel" modes={buffer().modes} />
                            </FormItem>
                        </Show>
                    </div>
                </PageDialog.Page>
            </PageDialog.Category>

            <PageDialog.Category title="Administration">
                <PageDialog.Page id="modes" title="Modes" icon={<Icon id="sliders" />}>
                    <ModeEditor scope="channel" modes={network().modes} value={form.modes} onInput={setModesHandler} />
                </PageDialog.Page>

                <For each={lists()}>
                    {(mode) => (
                        <PageDialog.Page
                            id={`mode_${mode.mode}`}
                            title={mode.description ?? mode.mode}
                            icon={<Icon id={MODE_LIST_ICONS[mode.mode] ?? "list-unordered"} />}
                        >
                            {""}
                        </PageDialog.Page>
                    )}
                </For>
            </PageDialog.Category>
        </PageDialog>
    );
};
