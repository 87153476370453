import { CaseMappedMap } from "@/lib/caseMappedMap";
import { CaseMapping } from "@/lib/irc/caseMapping";
import { ChannelListItem } from "@/lib/irc/list";
import { ModeListItem } from "@/lib/irc/modes";
import { newWhois, Whois } from "@/lib/irc/whois";
import { Whox } from "@/lib/irc/whox";

export class TransactionManager {
    motd?: string[];
    list?: ChannelListItem[];
    names: CaseMappedMap<CaseMappedMap<string>>;
    modeList: CaseMappedMap<ModeListItem[]>;
    whois: CaseMappedMap<Whois>;
    who?: Whox[];
    whox = new Map<string, { fields: string; items: Whox[] }>();
    whoxTokens: CaseMappedMap<string>;
    help: string[] = [];
    info: string[] = [];

    constructor(caseMapping: CaseMapping) {
        this.names = new CaseMappedMap(caseMapping);
        this.modeList = new CaseMappedMap(caseMapping);
        this.whois = new CaseMappedMap(caseMapping);
        this.whoxTokens = new CaseMappedMap(caseMapping);
    }

    setCaseMapping(caseMapping: CaseMapping) {
        const names = new CaseMappedMap<CaseMappedMap<string>>(caseMapping);
        for (const [key, map] of this.names) {
            names.set(key, new CaseMappedMap(caseMapping, map));
        }
        this.names = names;
        this.modeList = new CaseMappedMap(caseMapping, this.modeList);
        this.whois = new CaseMappedMap(caseMapping, this.whois);
        this.whoxTokens = new CaseMappedMap(caseMapping, this.whoxTokens);
    }

    clear() {
        this.list = undefined;
        this.names.clear();
        this.modeList.clear();
        this.whois.clear();
        this.who = undefined;
        this.whox.clear();
        this.whoxTokens.clear();
        this.help = [];
        this.info = [];
    }

    pushMotd(line: string) {
        if (!this.motd) {
            this.motd = [];
        }
        this.motd.push(line);
    }

    endMotd(): string[] {
        const motd = this.motd ?? [];
        this.motd = undefined;
        return motd;
    }

    pushList(item: ChannelListItem) {
        if (!this.list) {
            this.list = [];
        }
        this.list.push(item);
    }

    endList(): ChannelListItem[] {
        const list = this.list ?? [];
        this.list = undefined;
        return list;
    }

    pushNames(key: string, nickname: string, prefix: string) {
        let items = this.names.get(key);
        if (!items) {
            items = new CaseMappedMap(this.names.mapKey);
            this.names.set(key, items);
        }
        items.set(nickname, prefix);
    }

    endNames(key: string): CaseMappedMap<string> {
        const items = this.names.get(key) ?? new CaseMappedMap(this.names.mapKey);
        this.names.delete(key);
        return items;
    }

    pushModeList(key: string, item: ModeListItem) {
        let items = this.modeList.get(key);
        if (!items) {
            items = [];
            this.modeList.set(key, items);
        }
        items.push(item);
    }

    endModeList(key: string): ModeListItem[] {
        const items = this.modeList.get(key) ?? [];
        this.modeList.delete(key);
        return items;
    }

    pushWhois(key: string, whois: Partial<Whois>) {
        this.whois.set(key, { ...(this.whois.get(key) ?? newWhois()), ...whois });
    }

    endWhois(key: string): Whois {
        const whois = this.whois.get(key) ?? newWhois();
        this.whois.delete(key);
        return whois;
    }

    initWhox(mask: string, token: string, fields: string) {
        this.whox.set(token, { fields, items: [] });
        this.whoxTokens.set(mask, token);
    }

    getWhox(token: string) {
        return this.whox.get(token)?.fields ?? "";
    }

    pushWho(user: Whox, token?: string) {
        if (token) {
            const whox = this.whox.get(token);
            if (whox) {
                whox.items.push(user);
            }
            return;
        }

        if (!this.who) {
            this.who = [];
        }
        this.who.push(user);
    }

    endWho(mask: string): Whox[] {
        const token = this.whoxTokens.get(mask);
        this.whoxTokens.delete(mask);

        if (token) {
            const list = this.whox.get(token)?.items ?? [];
            this.whox.delete(token);
            return list;
        }

        const list = this.who ?? [];
        this.who = undefined;
        return list;
    }

    pushHelp(line: string) {
        this.help.push(line);
    }

    endHelp(): string[] {
        const lines = this.help;
        this.help = [];
        return lines;
    }

    pushInfo(line: string) {
        this.info.push(line);
    }

    endInfo(): string[] {
        const lines = this.info;
        this.info = [];
        return lines;
    }
}
