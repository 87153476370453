import { JSX, mergeProps, ParentComponent, Show, splitProps } from "solid-js";

export const Divider: ParentComponent<
    {
        variant?: "strong" | "medium" | "faint" | "vertical";
        label?: string;
        color?: "red";
    } & JSX.HTMLAttributes<HTMLDivElement>
> = (_props) => {
    const propsWithDefaults = mergeProps({ variant: "strong", class: "" }, _props);
    const [props, propsRest] = splitProps(propsWithDefaults, ["variant", "class", "color", "children"]);

    return (
        <div
            class={`o-divider o-divider--${props.variant ?? "strong"} ${
                props.color ? `o-divider--${props.color}` : ""
            } ${props.children ? "o-divider--labelled" : ""} ${props.class}`}
            {...propsRest}
        >
            <Show when={props.children}>{<div class="o-divider__label">{props.children}</div>}</Show>
        </div>
    );
};
