import { Component, createSignal, JSX, onMount, Show } from "solid-js";

import { FormItem } from "@/iro/layouts/Form";
import { ActionButton } from "@/iro/objects/ActionButton";
import { Dialog } from "@/iro/objects/Dialog";
import { TextField } from "@/iro/objects/TextField";
import { Network } from "@/store/network";

import { Icon } from "../objects/Icon";

export const HelpDialog: Component<{
    onClose?: (trigger?: string) => void;
    network: Network;
}> = (props) => {
    let inputEl!: HTMLInputElement;

    const [loading, setLoading] = createSignal(false);
    const [topic, setTopic] = createSignal("");
    const [content, setContent] = createSignal("");

    const handleClose = (result?: string) => props.onClose?.(result);

    const setTopicHandler: JSX.EventHandler<HTMLInputElement, InputEvent> = (e) => setTopic(e.currentTarget.value);

    const loadHandler = async (e?: SubmitEvent) => {
        e?.preventDefault();
        setLoading(true);
        try {
            const res = await props.network.help(topic());
            setContent(res.join("\n"));
        } finally {
            setLoading(false);
        }
    };

    onMount(() => {
        loadHandler();
        inputEl.focus();
    });

    return (
        <Dialog
            title="Server help"
            onClose={handleClose}
            scrollable
            footer={
                <div class="l-form l-form--labels-left">
                    <FormItem label="Topic">
                        <form class="l-media l-media--flush" onSubmit={loadHandler}>
                            <TextField
                                ref={inputEl}
                                value={topic()}
                                onInput={setTopicHandler}
                                class="l-media__block l-media__block--main"
                            />
                            <ActionButton class="l-media__block">Go</ActionButton>
                        </form>
                    </FormItem>
                </div>
            }
        >
            <Show when={!loading()} fallback={<Icon id="loading" class="u-ml-auto u-mr-auto" large block />}>
                <pre>{content()}</pre>
            </Show>
        </Dialog>
    );
};
