import { Exome } from "exome";

import { ModeChange } from "@/lib/irc/modes";
import { Whox } from "@/lib/irc/whox";

import { Network } from "./network";

export enum UserStatus {
    Offline = "offline",
    Away = "away",
    Online = "online",
}

export class User extends Exome {
    isOffline = false;

    modes = new Map<string, string | undefined>();

    constructor(
        readonly network: Network,
        public whox: Whox & Required<Pick<Whox, "nickname">>,
    ) {
        super();
    }

    get status() {
        return this.isOffline ? UserStatus.Offline : this.whox.isAway ? UserStatus.Away : UserStatus.Online;
    }

    updateWhox(whox: Whox) {
        this.whox = { ...this.whox, ...whox };
    }

    setOffline(isOffline: boolean) {
        this.isOffline = isOffline;
    }

    setModes(modes: ModeChange[]) {
        for (const mode of modes.values()) {
            if (mode.add) {
                this.modes.set(mode.mode, mode.param);
            } else {
                this.modes.delete(mode.mode);
            }
        }
    }

    modeCmd = (modes: ModeChange[]) => this.network.mode(this.whox.nickname, modes);
}
