import { useParams } from "@solidjs/router";
import { Component, createMemo, Match, Switch } from "solid-js";

import { Badge } from "@/iro/objects/Badge";
import { Button } from "@/iro/objects/Button";
import { ClientStatus } from "@/lib/client/client";
import { useStore } from "@/lib/exome/solid";
import { ROOT_BUFFER } from "@/store/buffer";
import { networkList } from "@/store/networkList";

import { Buffer } from "../components/Buffer";
import { MainSidebar } from "../components/MainSidebar";
import { Main } from "../layouts/Main";
import { LoadingView } from "./Message";

export const ChatView: Component = () => {
    const params = useParams();

    const networks = useStore(() => networkList);

    const networkId = createMemo(() => (params.network ? decodeURIComponent(params.network) : ""));
    const network = useStore(() => {
        const id = networkId();
        return id ? networks().get(id) : undefined;
    });

    const buffers = useStore(() => network()?.buffers);

    const bufferId = () => (params.buffer ? decodeURIComponent(params.buffer) : "");
    const buffer = () => {
        const id = bufferId();
        return id === ROOT_BUFFER ? network()?.serverBuffer : buffers()?.get(id);
    };

    const loading = () => {
        const net = network();
        return net && [ClientStatus.Connecting, ClientStatus.Registering, ClientStatus.Registered].includes(net.status);
    };

    const createBuffer = () => {
        const net = network();
        const id = bufferId();

        if (!net) {
            return;
        }

        if (net.isChannel(id)) {
            net.join(id);
        } else {
            net.buffers.upsert(id);
        }
    };

    return (
        <>
            <MainSidebar />

            <Switch>
                <Match when={loading()}>
                    <LoadingView />
                </Match>
                <Match when={buffer()}>{(buffer) => <Buffer buffer={buffer()} />}</Match>
                <Match when={network()?.isChannel(bufferId())}>
                    <Main centered variant="narrow" class="u-ta-center">
                        <p>
                            You're trying to view <Badge pad="200">{bufferId()}</Badge> on{" "}
                            <Badge pad="200">{network()?.name}</Badge>, but you haven't joined that channel.
                        </p>
                        <Button variant="accent" class="u-mt-400" onClick={createBuffer}>
                            Join {bufferId()}
                        </Button>
                    </Main>
                </Match>
                <Match when={network()}>
                    <Main centered variant="narrow" class="u-ta-center">
                        <p>
                            You're trying to view a conversation with <Badge pad="200">{bufferId()}</Badge> on{" "}
                            <Badge pad="200">{network()?.name}</Badge>, but that conversation is currently not open.
                        </p>
                        <Button variant="accent" class="u-mt-400" onClick={createBuffer}>
                            Talk with {bufferId()}
                        </Button>
                    </Main>
                </Match>
            </Switch>
        </>
    );
};
