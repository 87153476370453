export class ExponentialBackoff {
    attempt = 0;

    readonly offset: number;

    constructor(
        readonly base = 3,
        readonly multiplier = 100,
        min = 1000,
        readonly max = 10 * 60 * 1000,
    ) {
        this.offset = min - base ** this.attempt * multiplier;
    }

    reset() {
        this.attempt = 0;
    }

    next() {
        return Math.min(this.base ** this.attempt++ * this.multiplier + this.offset, this.max);
    }
}
