import { Exome } from "exome";
import { number as N, ord as Or, string as S } from "fp-ts";

import { CaseMappedMap } from "@/lib/caseMappedMap";
import { CaseMapping } from "@/lib/irc/caseMapping";
import { Typing } from "@/lib/irc/typing";

import { ChannelBuffer } from "./buffer/channelBuffer";
import { User } from "./user";

export const MEMBERSHIP_MAP: Record<string, string> = {
    "~": "Owner",
    "&": "Admin",
    "@": "Operator",
    "%": "Moderator",
    "+": "Voiced",
};

export const membershipOrder = "~&@%+";

export interface ChannelUser {
    prefix: string;
    user: User;
    typing: Typing;
}

export const UserOrd = Or.contramap((user: User) => user.whox.nickname)(S.Ord);

export const MembershipOrd: Or.Ord<string> = {
    ...S.Ord,
    compare(a, b) {
        let ia = a === "" ? 10000 : membershipOrder.indexOf(a);
        let ib = b === "" ? 10000 : membershipOrder.indexOf(b);
        if (ia === -1) {
            ia = 1000;
        }
        if (ib === -1) {
            ib = 1000;
        }
        return N.Ord.compare(ia, ib);
    },
};

export class MemberList extends Exome {
    members: CaseMappedMap<ChannelUser>;

    constructor(
        readonly buffer: ChannelBuffer,
        caseMapping: CaseMapping,
    ) {
        super();

        this.members = new CaseMappedMap(caseMapping);
    }

    updateCaseMapping(caseMapping: CaseMapping) {
        this.members = new CaseMappedMap(caseMapping, this.members);
    }

    private execDelete(nickname: string) {
        this.members.delete(nickname);
    }

    private execMove(oldNickname: string, newNickname: string) {
        const member = this.members.get(oldNickname);
        if (member) {
            this.members.delete(oldNickname);
            this.members.set(newNickname, member);
        }
    }

    setTyping(nickname: string, typing: Typing) {
        const member = this.members.get(nickname);
        if (member) {
            this.members.set(nickname, { ...member, typing });
        }
    }

    add(user: User, prefix: string) {
        this.members.set(user.whox.nickname, { user, prefix, typing: Typing.Done });
    }

    delete = (nickname: string) => {
        if (this.members.has(nickname)) {
            this.execDelete(nickname);
        }
    };

    move = (oldNickname: string, newNickname: string) => {
        if (this.members.has(oldNickname)) {
            this.execMove(oldNickname, newNickname);
        }
    };
}
