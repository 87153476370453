import { Component, ComponentProps, Show, splitProps } from "solid-js";

import { useOnDemand } from "@/iro/hooks/onDemand";
import { ActionMenu, ActionMenuItem, ActionMenuSeparator } from "@/iro/objects/ActionMenu";
import { StatusIndicator } from "@/iro/objects/StatusIndicator";
import { useStore } from "@/lib/exome/solid";
import { User } from "@/store/user";

import { ClientSettingsDialog } from "../modals/ClientSettings";
import { SelfDialog } from "../modals/Self";
import { Icon } from "../objects/Icon";

export const SelfMenu: Component<ComponentProps<typeof ActionMenu> & { user: User }> = (_props) => {
    const [props, propsRest] = splitProps(_props, ["user"]);
    const user = useStore(() => props.user);
    const [onDemand] = useOnDemand();

    const viewProfile = () => {
        onDemand((close) => <SelfDialog network={props.user.network} onClose={close} />);
    };
    const viewClientSettingsModal = () => {
        onDemand((close) => <ClientSettingsDialog onClose={close} />);
    };
    const setAway = () => user().network.setAway();
    const setOnline = () => user().network.setOnline();

    const close = (fn?: () => void) => {
        fn?.();
        propsRest.onClose?.();
    };

    return (
        <ActionMenu {...propsRest}>
            <ActionMenuItem pre={<Icon id="user" />} onClick={[close, viewProfile]}>
                Profile
            </ActionMenuItem>
            <Show
                when={user().status === "online"}
                fallback={
                    <ActionMenuItem pre={<StatusIndicator status="online" />} onClick={[close, setOnline]}>
                        Mark as online
                    </ActionMenuItem>
                }
            >
                <ActionMenuItem pre={<StatusIndicator status="away" />} onClick={[close, setAway]}>
                    Mark as away
                </ActionMenuItem>
            </Show>
            <ActionMenuSeparator />
            <ActionMenuItem pre={<Icon id="cog" />} onClick={[close, viewClientSettingsModal]}>
                Client settings
            </ActionMenuItem>
        </ActionMenu>
    );
};
