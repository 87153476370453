import { useIntl } from "@cookbook/solid-intl";
import { Component, createMemo, For, JSX, mergeProps, Show, splitProps } from "solid-js";

import { CHANNEL_MODE_MAP, USER_MODE_MAP } from "@/data/mode";
import { Badge } from "@/iro/objects/Badge";

export const ModeList: Component<
    {
        scope: "user" | "channel";
        modes: Map<string, string | undefined>;
    } & JSX.HTMLAttributes<HTMLDivElement>
> = (_props) => {
    const propsWithDefaults = mergeProps({ class: "" }, _props);
    const [props, propsRest] = splitProps(propsWithDefaults, ["scope", "modes", "class"]);

    const intl = useIntl();

    const modes = createMemo(() => {
        const modeMap = props.scope === "user" ? USER_MODE_MAP : CHANNEL_MODE_MAP;

        return Array.from(props.modes)
            .sort(([a], [b]) => a.localeCompare(b, intl.locale))
            .map(([mode, param]) => {
                const description =
                    mode in modeMap ? intl.formatMessage(modeMap[mode as keyof typeof modeMap]) : undefined;

                return {
                    mode,
                    param,
                    description,
                };
            });
    });

    return (
        <div class={`l-mode-list ${props.class}`} {...propsRest}>
            <For each={modes()}>
                {({ mode, param, description }) => (
                    <div>
                        <Badge fixed="100" class="u-mr-50">
                            {mode}
                        </Badge>{" "}
                        {description}
                        <Show when={param}>
                            <small> — {param}</small>
                        </Show>
                    </div>
                )}
            </For>
        </div>
    );
};
