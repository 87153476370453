export function createIterableIterator<T>(next: () => IteratorResult<T>): IterableIterator<T> {
    const it: IterableIterator<T> = { next, [Symbol.iterator]: () => it };
    return it;
}

export function mapIterableIterator<T, U>(it: IterableIterator<T>, f: (value: T) => U): IterableIterator<U> {
    return createIterableIterator((): IteratorResult<U> => {
        const { value, done } = it.next();
        if (done) {
            return { value: undefined, done: true };
        }
        return { value: f(value), done: false };
    });
}
