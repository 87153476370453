import { useNavigate } from "@solidjs/router";
import { Component, createMemo, createSignal, mergeProps, onMount, Show } from "solid-js";

import { Form, FormItem } from "@/iro/layouts/Form";
import { Dialog } from "@/iro/objects/Dialog";
import { Select } from "@/iro/objects/Select";
import { TextField } from "@/iro/objects/TextField";
import { useStore, useStores } from "@/lib/exome/solid";
import * as routes from "@/routes";
import { Network, ROOT_NETWORK } from "@/store/network";
import { networkList as networkListStore } from "@/store/networkList";

export const JoinDialog: Component<{
    network?: Network;
    onClose?: (trigger?: string) => void;
}> = (_props) => {
    const props = mergeProps({ onClose: () => void 0 }, _props);

    let targetInputEl!: HTMLSelectElement;

    const navigate = useNavigate();

    const networkList = useStore(() => networkListStore);
    const networks = useStores(() => Array.from(networkList().networks.values()));

    const [networkId, setNetworkId] = createSignal(ROOT_NETWORK);
    const [target, setTarget] = createSignal("");
    const [key, setKey] = createSignal("");

    const isChannel = createMemo(() => target() && networkList().networks.get(networkId())?.isChannel(target()));

    const setTextFieldHandler = (
        setter: (value: string) => void,
        e: InputEvent & { currentTarget: HTMLInputElement },
    ) => setter(e.currentTarget.value);

    const setNetworkHandler = (e: InputEvent & { currentTarget: HTMLSelectElement }) => {
        setNetworkId(e.currentTarget.value);
    };

    const networkOptions = createMemo(() => networks().map((network) => ({ value: network.id, label: network.name })));

    const handleClose = async (result?: string, e?: SubmitEvent) => {
        e?.preventDefault();

        const net = networkList().get(networkId());

        if (net && result === "ok") {
            if (!net.buffers.has(target())) {
                if (isChannel()) {
                    await net.join(target(), key());
                } else {
                    net.buffers.upsert(target());
                }
            }

            navigate(routes.chat(net.id, encodeURIComponent(target())));
        }

        props.onClose?.(result);
    };

    onMount(() => {
        if (props.network) {
            setNetworkId(props.network.id);
        }

        targetInputEl.focus();
    });

    return (
        <Dialog title="New chat" size="sm" onClose={handleClose}>
            <Form labelPosition="left" onSubmit={[handleClose, "ok"]}>
                <FormItem label="Network">
                    <Select
                        ref={targetInputEl}
                        value={networkId()}
                        options={networkOptions()}
                        onInput={setNetworkHandler}
                    />
                </FormItem>
                <FormItem label="Nickname / channel">
                    <TextField value={target()} onInput={[setTextFieldHandler, setTarget]} />
                </FormItem>
                <Show when={isChannel()}>
                    <FormItem label="Password (optional)">
                        <TextField type="password" value={key()} onInput={[setTextFieldHandler, setKey]} />
                    </FormItem>
                </Show>
                <button class="u-d-none">Send</button>
            </Form>
        </Dialog>
    );
};
