import { useIntl } from "@cookbook/solid-intl";
import { Component, JSX, onMount, Show } from "solid-js";
import { createStore } from "solid-js/store";

import { FormItem } from "@/iro/layouts/Form";
import { Dialog } from "@/iro/objects/Dialog";
import { TextField } from "@/iro/objects/TextField";
import { Capability } from "@/lib/client/capabilityManager";
import { useStore } from "@/lib/exome/solid";
import { Network } from "@/store/network";

import { ModeList } from "../objects/ModeList";

export const SelfDialog: Component<{
    onClose?: (trigger?: string) => void;
    network: Network;
}> = (props) => {
    const intl = useIntl();

    const network = useStore(() => props.network);
    const user = useStore(() => network().me);

    const [form, setForm] = createStore<{
        nickname: string;
        realname: string;
        modes: Map<string, string | undefined>;
    }>({
        nickname: "",
        realname: "",
        modes: new Map(),
    });

    const handleClose = async (result?: string) => {
        if (result === "ok") {
            if (form.nickname !== user().whox.nickname) {
                await network().nick(form.nickname);
            }
            if (form.realname !== user().whox.realname) {
                await network().setname(form.realname);
            }
        }

        props.onClose?.(result);
    };

    const setNicknameHandler: JSX.EventHandler<HTMLInputElement, InputEvent> = (e) =>
        setForm("nickname", e.currentTarget.value);

    const setRealnameHandler: JSX.EventHandler<HTMLInputElement, InputEvent> = (e) =>
        setForm("realname", e.currentTarget.value);

    const setModesHandler: JSX.EventHandler<never, CustomEvent<Map<string, string | undefined>>> = (e) =>
        setForm("modes", e.detail);

    onMount(() => {
        setForm({
            nickname: user().whox.nickname,
            realname: user().whox.realname,
            modes: new Map(user().modes),
        });
    });

    return (
        <Dialog
            title="Profile"
            size="sm"
            onClose={handleClose}
            okLabel={intl.formatMessage({
                id: "dialog.user.ok",
                defaultMessage: "Apply",
            })}
        >
            <div class="l-form l-form--labels-left">
                <FormItem label="Nickname">
                    <form class="l-card l-card--flush">
                        <TextField
                            class="l-card__block l-card__block--main"
                            value={form.nickname}
                            onInput={setNicknameHandler}
                        />
                    </form>
                </FormItem>
                <FormItem label="Real name">
                    <form class="l-card l-card--flush">
                        <TextField
                            class="l-card__block l-card__block--main"
                            value={form.realname}
                            onInput={setRealnameHandler}
                            disabled={!network().client.caps.has(Capability.Setname)}
                        />
                    </form>
                </FormItem>
                <Show when={user().modes.size}>
                    <FormItem alignStart label="Modes">
                        <ModeList scope="user" modes={user().modes} />
                    </FormItem>
                </Show>
            </div>
        </Dialog>
    );
};
