import { Component, createMemo, For, Show } from "solid-js";

import { Linkified as Lified, linkify } from "@/lib/linkify";
import { isMatchingN } from "@/lib/ts-pattern/util";

export const Linkified: Component<{ content: string | Lified[][] }> = (props) => {
    const content = createMemo(() =>
        typeof props.content === "string" ? props.content.split("\n").map((line) => linkify(line).text) : props.content,
    );

    const handleClick = (e: Event) => {
        e.stopPropagation();
    };

    return (
        <For each={content()}>
            {(line, i) => (
                <>
                    <Show when={i()}>
                        <br />
                    </Show>
                    <For each={line}>
                        {(el) => (
                            <Show when={isMatchingN(Lified.Link.select(), el)} fallback={<>{el.value}</>}>
                                {(el) => (
                                    <a
                                        href={el().value.href}
                                        target="_blank"
                                        rel="noreferrer noopener"
                                        onClick={handleClick}
                                    >
                                        {el().value.value}
                                    </a>
                                )}
                            </Show>
                        )}
                    </For>
                </>
            )}
        </For>
    );
};
