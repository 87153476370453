export interface Whois {
    isAuthenticated?: boolean;
    isOperator?: boolean;
    isBot?: boolean;
    username: string;
    host: string;
    realname: string;
    server: string;
    serverInfo: string;
    idle?: number;
    loggedInAt?: Date;
    channels: string[];
    special: string;
    account: string;
}

export function newWhois(): Whois {
    return {
        username: "",
        host: "",
        realname: "",
        server: "",
        serverInfo: "",
        channels: [],
        special: "",
        account: "",
    };
}
