import { LinkProps, NavLink } from "@solidjs/router";
import { Component, JSX, mergeProps, splitProps } from "solid-js";

export const Button: Component<
    {
        variant?: "accent" | "primary" | "secondary";
        block?: boolean;
        outline?: boolean;
        round?: boolean;
        class?: string;
    } & JSX.ButtonHTMLAttributes<HTMLButtonElement>
> = (_props) => {
    const propsWithDefaults = mergeProps({ variant: "secondary", class: "" }, _props);
    const [props, propsRest] = splitProps(propsWithDefaults, ["variant", "block", "outline", "round", "class"]);

    return (
        <button
            class={`o-button o-button--${props.variant} ${props.block ? "o-button--block" : ""} ${
                props.round ? "o-button--round" : ""
            } ${props.outline ? "o-button--outline" : ""} ${props.class}`}
            {...propsRest}
        />
    );
};

export const ButtonLink: Component<
    {
        variant?: "accent" | "primary" | "secondary";
        block?: boolean;
        outline?: boolean;
        round?: boolean;
        class?: string;
    } & LinkProps
> = (_props) => {
    const propsWithDefaults = mergeProps({ variant: "secondary", class: "" }, _props);
    const [props, propsRest] = splitProps(propsWithDefaults, ["variant", "block", "outline", "round", "class"]);

    return (
        <NavLink
            class={`o-button o-button--${props.variant} ${props.block ? "o-button--block" : ""} ${
                props.round ? "o-button--round" : ""
            } ${props.outline ? "o-button--outline" : ""} ${props.class}`}
            {...propsRest}
        />
    );
};
