import { mergeProps, ParentComponent } from "solid-js";

import { OnDemandProvider } from "./OnDemand";

const counter = 0;

export const Backdrop: ParentComponent<{ class?: string }> = (_props) => {
    const props = mergeProps({ class: "" }, _props);

    /*
    onMount(() => {
        document.documentElement.style.overflow = 'hidden';
        ++counter;
    });

    onCleanup(() => {
        if (!--counter) {
            document.documentElement.style.overflow = '';
        }
    });
    */

    return (
        <div class={`o-backdrop ${props.class}`}>
            <OnDemandProvider>{props.children}</OnDemandProvider>
        </div>
    );
};
