import { impl, Variant } from "@/lib/unionTypes";

import { Message } from "./message";
import { Tags } from "./rawMessage";

export type BatchItem = Variant<"Batch", Batch> | Variant<"Message", Message>;

export const BatchItem = impl<BatchItem>();

export interface Batch {
    name: string;
    type: string;
    params: string[];
    tags: Tags;
    items: BatchItem[];
}
