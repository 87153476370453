import { useNavigate } from "@solidjs/router";
import { Component, ComponentProps, splitProps, useContext } from "solid-js";

import { ActionMenu, ActionMenuItem, ActionMenuSeparator } from "@/iro/objects/ActionMenu";
import { OnDemandContext } from "@/iro/objects/OnDemand";
import { useStore } from "@/lib/exome/solid";
import * as routes from "@/routes";
import { ROOT_BUFFER } from "@/store/buffer";
import { ChannelBuffer } from "@/store/buffer/channelBuffer";

import { ChannelDialog } from "../modals/Channel";
import { Icon } from "../objects/Icon";

export const ChannelBufferMenu: Component<ComponentProps<typeof ActionMenu> & { buffer: ChannelBuffer }> = (_props) => {
    const [props, propsRest] = splitProps(_props, ["buffer"]);

    const buffer = useStore(() => props.buffer);
    const onDemand = useContext(OnDemandContext);
    const navigate = useNavigate();

    const close = (fn?: () => void) => {
        fn?.();
        propsRest.onClose?.();
    };

    const handleClose = () => {
        navigate(routes.chat(buffer().network.id, ROOT_BUFFER));
        buffer().part();
    };

    const handleSettings = () => {
        onDemand((close) => <ChannelDialog buffer={props.buffer} onClose={close} />);
    };

    return (
        <ActionMenu {...propsRest}>
            <ActionMenuItem pre={<Icon id="cog" />} onClick={[close, handleSettings]}>
                Settings
            </ActionMenuItem>
            <ActionMenuSeparator />
            <ActionMenuItem pre={<Icon id="logout" />} onClick={[close, handleClose]}>
                Leave
            </ActionMenuItem>
        </ActionMenu>
    );
};
