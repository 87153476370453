import { Exome } from "exome";

import { Buffer, ROOT_BUFFER } from "../buffer";
import { Draft } from "../draft";
import { MessageList } from "../messageList";
import { Network } from "../network";

export class ServerBuffer extends Exome implements Buffer {
    readonly id = ROOT_BUFFER;

    messages: MessageList = new MessageList(this);

    draft = new Draft();

    // eslint-disable-next-line no-useless-constructor
    constructor(readonly network: Network) {
        super();
    }

    setId = (_: string) => void 0;

    send = () => {
        if (this.draft.current !== "") {
            this.network.send(this.id, this.draft.current, this.draft.replyTo);
            this.draft.submit();
        }
    };
}
