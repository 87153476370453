import { defineMessages } from "@cookbook/solid-intl";

import { impl, Variant } from "@/lib/unionTypes";

export const USER_MODE_MAP = defineMessages({
    i: { id: "mode.user.i", defaultMessage: "Invisible" },
    o: { id: "mode.user.o", defaultMessage: "Network operator" },
    O: { id: "mode.user.O", defaultMessage: "Server operator" },
    r: { id: "mode.user.r", defaultMessage: "Registered" },
    w: { id: "mode.user.w", defaultMessage: "Wallops" },
});

export const CHANNEL_MODE_MAP = defineMessages({
    b: { id: "mode.channel.b", defaultMessage: "Ban list" },
    e: { id: "mode.channel.e", defaultMessage: "Ban exception list" },
    l: { id: "mode.channel.l", defaultMessage: "Client limit" },
    i: { id: "mode.channel.i", defaultMessage: "Invite only" },
    I: { id: "mode.channel.I", defaultMessage: "Invite exception list" },
    k: { id: "mode.channel.k", defaultMessage: "Password protected" },
    m: { id: "mode.channel.m", defaultMessage: "Moderated" },
    q: { id: "mode.channel.q", defaultMessage: "Quiet list" },
    s: { id: "mode.channel.s", defaultMessage: "Unlisted" },
    t: { id: "mode.channel.t", defaultMessage: "Protected topic" },
    n: { id: "mode.channel.n", defaultMessage: "No external messages" },
});

export const MEMBERSHIP_MAP = defineMessages({
    "~": { id: "membership.~", defaultMessage: "Owner" },
    "&": { id: "membership.&", defaultMessage: "Admin" },
    "@": { id: "membership.@", defaultMessage: "Operator" },
    "%": { id: "membership.%", defaultMessage: "Moderator" },
    "+": { id: "membership.+", defaultMessage: "Voiced" },
});

export const MODE_LIST_ICONS: Record<string, string> = {
    b: "ban",
    e: "checklist",
    I: "checklist",
    q: "volume-mute",
};

export type ModeSpec =
    | Variant<"AlwaysArg", { mode: string; active: boolean; param: string }>
    | Variant<"SetArg", { mode: string; active: boolean; param: string }>
    | Variant<"NeverArg", { mode: string; active: boolean }>;

export const ModeSpec = impl<ModeSpec>();
