import { Message } from "@/lib/irc/message";
import { Narrow } from "@/lib/unionTypes";

export class IrcFailError extends Error {
    code: string;

    command: string | number;

    context?: string[];

    constructor(msg: Narrow<Message, "Fail">["value"]) {
        super(msg.description);

        this.code = msg.code;
        this.command = msg.command;
        this.context = msg.context;
    }
}

export class IrcReplyError extends Error {
    code: number;

    server: string;

    constructor(msg: Narrow<Message, "Reply">["value"]) {
        const params = msg.params;

        super(`${msg.code}: ${params.length ? params[params.length - 1] : "-"}`);

        this.code = msg.code;
        this.server = msg.source.nickname;
    }
}

export class IrcFatalError extends Error {
    constructor(msg: Narrow<Message, "Error">["value"]) {
        super(msg.error);
    }
}
