import { createSignal, onCleanup } from "solid-js";

import { getScrollContainer } from "@/lib/scroll";

export const useScrollTrigger = (anchor: "top" | "bottom") => {
    const [threshold, setThreshold] = createSignal(false);

    let io: IntersectionObserver | undefined;

    const cb: IntersectionObserverCallback = (entries) => setThreshold(!entries[0]?.isIntersecting);

    const mount = (el: HTMLElement) => {
        io?.disconnect();

        io = new IntersectionObserver(cb, {
            root: getScrollContainer(el),
            rootMargin: anchor === "bottom" ? "-100% 0px 100% 0px" : "100% 0px -100% 0px",
            threshold: 0.001,
        });

        io.observe(el);
    };

    onCleanup(() => {
        io?.disconnect();
    });

    return [mount, threshold] as const;
};
