import { useIntl } from "@cookbook/solid-intl";
import { Component, mergeProps, Show } from "solid-js";

import { useStore } from "@/lib/exome/solid";
import { User } from "@/store/user";

import { Icon } from "./Icon";

export const UserName: Component<{ user: User; class?: string }> = (_props) => {
    const props = mergeProps({ tag: "span", class: "" }, _props);

    const intl = useIntl();
    const user = useStore(() => props.user);

    return (
        <>
            {user().whox.nickname}{" "}
            <Show when={user().whox.account}>
                <span
                    style={{ color: "var(--colors--accent--primary--solid--obj)" }}
                    title={intl.formatMessage(
                        {
                            id: "user.authenticated",
                            defaultMessage: "Authenticated as {account}",
                        },
                        {
                            account: user().whox.account,
                        },
                    )}
                >
                    <Icon id="check-circle" />
                </span>
            </Show>
        </>
    );
};
